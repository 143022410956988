import axios from "axios";
import React, { useEffect, useState } from "react";

import { firebase, auth } from "../../FireBase";
import flage from "../../assets/logo/flag.webp";

// import login from "../../assets/img/leatzmeat/small.jpg";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineGoogle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// import { useSetLoginMutation } from "../products/productSlice";
import { Spinner } from "react-bootstrap";
// import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
// import GoogleLogin from "react-google-login";
// import { LoginSocialFacebook } from "reactjs-social-login";
import { FcGoogle } from "react-icons/fc";
// import OtpLogin from "../otpLogin/OtpLogin";

import { useTranslation } from "react-i18next";
import { base_url } from "../../server";
import { ToastContainer, toast } from "react-toastify";

function Login(props) {
  // const getMobile = window.localStorage.getItem('phoneNumber')
  // console.log(getMobile);
  // console.log(props.fontSize);
  const [fontSize, setFontSize] = useState(18);
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const handleChangeLogin = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const cloneData = { ...loginForm };
    cloneData[name] = value;
    setLoginForm(cloneData);
  };

  // const [loginFormStatus, { data, isError, isSuccess, isLoading }] = useSetLoginMutation()

  // useEffect(() => {
  //   if (data?.firstname) {
  //     setTimeout(() => {
  //       window.localStorage.setItem("token", data.token);
  //       window.localStorage.setItem("user_id", data._id);
  //       window.localStorage.setItem("isLogin", true);
  //       window.localStorage.setItem("email", data.email);
  //       window.localStorage.setItem("profilePic", data.image.url);
  //       window.localStorage.setItem("userName", `${data.firstname} ${data.lastname}`);
  //       navigate("/");
  //     }, 1000);
  //   }
  // }, [isLoading])

  // const handleLoginSubmit = async (e) => {
  //   e.preventDefault();
  //   loginFormStatus(loginForm)
  // };

  const [gmailData, setGailData] = useState();

  const [isGoogleLogin, seIGoogle] = useState(false);

  const sendValueGoogle = async (val) => {
    seIGoogle(true);
    try {
      const res = await axios.post(
        "https://evehicleadmin.mshfdc.co.in/api/socialMedia/google",
        { access_token: val?.data?.access_token }
      );
      window.localStorage.setItem("token", res.data.token);
      window.localStorage.setItem("user_id", res.data._id);
      window.localStorage.setItem("isLogin", true);
      window.localStorage.setItem("email", res.data.email);
      // window.localStorage.setItem("profilePic", data?.image?.url);
      window.localStorage.setItem(
        "userName",
        `${res?.data.firstname} ${res?.data.lastname}`
      );
      navigate("/");
    } catch (error) { }
    seIGoogle(false);
  };

  useEffect(() => {
    if (gmailData) {
      sendValueGoogle(gmailData);
    }
  }, [gmailData]);

  // console.log(gmailData);

  const responseFacebook = (response) => {
    console.log(response);
  };

  const [modalShow, setModalShow] = useState(false);

  const [showsec, setShowSec] = useState(true);
  const anotheShow = () => {
    setShowSec(false);
  };

  const [phone, setPhone] = useState();

  const [isValid, setIsValid] = useState(false);

  const mobileHandle = (e) => {
    //  const clone = ...phone
    const value = e.target.value;
    if (value?.length == 11) {
      setIsValid(true);
      setTimeout(() => {
        setIsValid(false);
      }, [5000]);
      return;
    }
    setPhone(value);
  };
  // console.log(phone);

  const [otp, setOtp] = useState("");
  const [final, setfinal] = useState("");
  const [otpToken, setOtpToken] = useState(null);

  // const navigate = useNavigate()

  // console.log('final', final);

  const [token, setToken] = useState();
  // console.log('token', token);

  const [validToken, setValidToken] = useState({
    id: token,
  });

  const [verify, setVerify] = useState();

  const [mobileNumAlert, setMobileNumAlert] = useState(null);

  const verifiedMobileNumber = async () => {
    try {
      const res = await axios.get(
        `${base_url}specialSeller/phoneCheck/${phone}`
      );
      if (res.data.alreadyExist === true) {
        setMobileNumAlert(true);
        setTimeout(() => {
          setMobileNumAlert(false);
        }, 3000);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      alert(error.message);
      return true;
    }
  };

  // useEffect(() => {

  // }, [])

  const [recatcha, setRecaptcha] = useState(null);
  const [recatcha2, setRecaptcha2] = useState(true);
  const sendOtp = async () => {
    const dataMobile = await verifiedMobileNumber();

    // if (dataMobile == false) {
    //   alert('Beneficiary Registration Portal Will Start from 3rd December 2023')
    //   return
    // }

    console.log("dataMobile", dataMobile);
    if (!dataMobile) {
      window.localStorage.setItem("phoneNumber", phone);
      if (phone == "" || phone.length != 10) {
        alert("Invalid Mobile Number please enter correct number");
        return;
      }


      // let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");



      try {
        let api = await axios.post(
          "https://evehicleadmin.mshfdc.co.in/api/auth/request_otp",
          { entity: phone }
        );
        setOtpToken(api.data.token);
        toastSuccessMessage();
        anotheShow();
      } catch (error) {
        alert(error.message);
      }

      // auth
      //   .signInWithPhoneNumber("+91" + phone, verify)
      //   .then((result) => {
      //     console.log(verify, "verify");
      //     console.log("result", result);
      //     setfinal(result);

      //     // alert("Your OTP has been successfull")
      //     toastSuccessMessage();
      //     anotheShow();
      //     // if (verify.v === 'recaptcha-container') {
      //     //   setRecaptcha(false)
      //     //   setRecaptcha2(false)
      //     // }
      //   })
      //   .catch((err) => {
      //     alert(err);
      //     // window.location.reload()
      //   });
    }
  };

  const ValidateOtp = async () => {
    if (otp === null || final === null) return;
    try {
      let verify = await axios.post(
        "https://evehicleadmin.mshfdc.co.in/api/auth/phone_verify_otp",
        { otp: otp, token: otpToken }
      );
      setToken(verify.data.token);
      // postData(verify.data.token);
      navigate("/special-seller-sign-up");
      //
    } catch (error) {
      console.log(error);
      alert(error.response.data.message);
    }

    // const res = await final
    //   .confirm(otp)
    //   .then((result) => {
    //     // console.log('result----', otp, result.user);
    //     setToken(result.user._lat);
    //     postData(result.user._lat);
    //     // navigate('/')
    //   })
    //   .catch((err) => {
    //     alert("Wrong OTP");
    //   });

    // setValidToken()
  };

  const postData = async (toke) => {
    const obj = { id: toke };
    try {
      const res = await axios.post(`${base_url}auth/phone/verify`, obj);
      // console.log('login', res);
      window.localStorage.setItem("token", res.data.token);
      window.localStorage.setItem("isLogin", true);
      // window.localStorage.setItem("user_id", res.data._id);
      navigate("/special-seller-sign-up");
      // props.onHide()
    } catch (error) {
      alert(error.message)
    }
  };

  // const resendOtp = async () => {

  //   let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");

  //   auth
  //     .signInWithPhoneNumber("+91" + phone, verify)
  //     .then((result) => {
  //       setfinal(result);
  //       console.log("result", result);

  //       toastSuccessMessage();
  //       anotheShow();
  //     })
  //     .catch((err) => {
  //       alert(err);
  //     });
  // };

  const toastSuccessMessage = () => {
    toast.success("OTP has been sent to your mobile number.", {
      position: "top-center",
    });
  };

  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      {/* <CustomToaster color={showTaoster.color} show={showTaoster.show} setShow={handleToaster} message={showTaoster.message} position="bottom-end" delay={10000} /> */}
      <div className="registrationDetail">
        <div className="container">
          <div className="registrationInfo">
            <div className="registerContent">
              {isGoogleLogin && (
                <div className="preloaderCount">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
              <div className="contentHeader">
                <h3 style={{ fontSize: `${props.fontSize}px` }}>
                  Welcome to Self-Employment To Divyangjan Through Various
                  Businesses on Environmentally Friendly E-Vehicles / E-Cart
                </h3>
                <p style={{ fontSize: `${props.fontSize}px` }}>
                  {t("Sign in with your mobile number to get started")}
                </p>
              </div>
              <div className="contentFooter">
                {/* <img src={login} alt="Login" className="img-fluid" /> */}
              </div>
            </div>
            <div className="registerForm">
              <h4 className="mb-4" style={{ fontSize: `${props.fontSize}px` }}>
                {t("Login to your account.")}
              </h4>
              {/* <form className="registerFormField" onSubmit={handleLoginSubmit}>
                <div className="mb-3">
                  <input
                    type="email"
                    placeholder="email"
                    className="form-control"
                    autoComplete="off"
                    name="email"
                    onChange={handleChangeLogin}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    placeholder="Password"
                    className="form-control"
                    autoComplete="off"
                    name="password"
                    onChange={handleChangeLogin}
                  />
                </div>
                <div className="form-check mb-3 forgotInfo">
                  <div className="rememberText">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label agreeCheck"
                      htmlFor="flexCheckDefault"
                    >
                      Remember Me
                    </label>
                  </div>
                  <div className="forgotText">
                    <Link to="/reset">Forgot password?</Link>
                  </div>
                </div>
                {isError && <h4 style={{ color: "red" }}>login Fail ! </h4>}
                {isSuccess && <h4>login Successfully !</h4>}
                <button className="btn btn-primary createAccount mb-3" type="submit" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  login
                  {isLoading && <Spinner style={{ marginLeft: "7px" }} animation="border" />}
                </button>
              </form> */}

              <div className="login-section">
                {showsec ? (
                  <div className="auth-user text-center">
                    {/* <input type="text" placeholder="UDID Authentication Number" />
                            <button type="button" className="" onClick={anotheShow}>Submit UDID</button> */}
                    <p style={{ fontSize: `${props.fontSize}px` }}>
                      <img src={flage} alt="" style={{ width: "30px" }} /> Note:
                      Please Enter Your Indian Mobile Number
                    </p>
                    <div className="number-sec">
                      <div className="nine-one-div">+91</div>
                      {/* <input type="number" placeholder="Please Enter Your Indian Mobile Number" name="phone" value={phone} onChange={(e) => { setPhone(e.target.value) }} /> */}
                      <input
                        type="number"
                        placeholder="Please Enter Your Indian Mobile Number"
                        name="phone"
                        value={phone}
                        onChange={mobileHandle}
                      />
                    </div>
                    {isValid && (
                      <p style={{ color: "red" }}>
                        Please enter a valid 10-digit mobile number.
                      </p>
                    )}

                    {mobileNumAlert && (
                      <div style={{ color: "red" }}>Already Exist!</div>
                    )}

                    <div id="recaptcha-container"></div>
                    <button type="button" className="" onClick={sendOtp}>
                      Send OTP
                    </button>
                  </div>
                ) : (
                  <div className="otp-section auth-user text-center">
                    <p>Verify Mobile Number {phone}</p>
                    <input
                      type="text"
                      className="mt-3"
                      placeholder="Enter OTP"
                      name="otp"
                      value={otp}
                      onChange={(e) => {
                        setOtp(e.target.value);
                      }}
                    />

                    {/* <div id="recaptcha-container"></div> */}

                    {/* <div className="resent" onClick={resendOtp}>
                      <a href="#">OTP Resend</a>
                    </div> */}

                    <button type="button" className="" onClick={ValidateOtp}>
                      Submit OTP
                    </button>
                  </div>
                )}
              </div>

              {/* <button className="btn btn-primary createAccount" onClick={() => setModalShow(true)} type="button" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {t('OTP LOGIN')}
                

              </button> */}
              {/* <div className="joinWith">
                <span>{t('OR LOGIN WITH')}</span>
              </div> */}
              {/* <div className="connectWith">
                <ul>
                  <li>
                    <a href="#"
                      appId="1007501343742455"
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      className="facebook">
                      <FaFacebookF />
                    </a>


                    <LoginSocialFacebook
                      client_id='1007501343742455'
                      discoveryDocs='claims_supported'
                      access_type='offline'
                      onResolve={(provider, data) => {
                        setGailData(provider)
                        console.log(provider);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}

                    >
                    <a href="#" className="facebook">
                      <FaFacebookF />
                    </a>

                    </LoginSocialFacebook>


                    <FacebookLogin
                      appId="1007501343742455"
                      autoLoad={false}
                      // fields="name,email,picture"
                      callback={responseFacebook}
                      className="facebook"
                    />

                    <LoginSocialFacebook appId="1007501343742455"
                      onResolve={(res) => {
                        console.log(res);
                      }}
                      onReject={(rej) => {
                        console.log(rej);
                      }}
                    >
                    </LoginSocialFacebook>


                  </li>

                  <li>
                    <a href="#" className="twitter">
                      <BsTwitter />
                    </a>
                  </li>

                  <li>

                    <LoginSocialGoogle
                      client_id='29276214138-s764o2gvrj925kgiii029stgapt5raae.apps.googleusercontent.com'
                      scope='email'
                      discoveryDocs='claims_supported'
                      access_type='offline'
                      onResolve={(provider, data) => {
                        setGailData(provider)
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}

                    >
                      <a href="#" className="google">
                        <FcGoogle />
                      </a>

                    </LoginSocialGoogle>
                  </li>
                </ul>
              </div> */}
              {/* <div className="alreadyAccount">
                <p>{t('Don`t have an account ?')}</p>
                <Link to="/registration">{t('Register Now')}</Link>
              </div> */}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      {/* <OtpLogin
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
    </>
  );
}

export default Login;
