import React, { useEffect } from "react";
import Login from "../../Components/login/Login";


function LoginPage({ fontSize }) {
  console.log('fontSize', fontSize);

  return (
    <>
      <Login fontSize={fontSize} />
    </>
  );
}

export default LoginPage;
