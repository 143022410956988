import { Button, Modal } from "react-bootstrap"
import { AiFillPrinter } from "react-icons/ai"
import { Link } from "react-router-dom"

function SpecialSellerPopup(props) {
    console.log('props---', props);
    return (
        <>
            {props.spinn && <div className="preloaderCount">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-center">
                        Your Registration has been done sucessfully.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <h3 className="text-center">Your Registration No: <span style={{ color: 'red' }}>{props?.successData && props?.successData[0]?.seller?.applicationNo}</span></h3>
                        <h3 className="text-center">Your Registration Date :<span style={{ color: 'red' }}>{props?.successData && props?.successData[0]?.seller?.applicationDate}</span></h3>
                    </div>
                    <div className="pdf text-center">
                        {/* Download Your Form Details in PDF */}
                        <button type="button" style={{ border: 'none', padding: '5px', borderRadius: "5px", background: 'aqua' }} onClick={props.downloadPdf}><AiFillPrinter /> PDF</button>
                        <Link className="ml-2" to={`/special-seller/details/${props?.successData && props?.successData[0]?.seller?.applicationNo}`} target="blank" style={{ border: 'none', padding: '5px', borderRadius: "5px", background: 'aqua' }}>Aplication Details</Link>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button onClick={props}>Close</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default SpecialSellerPopup