import { useState } from "react";
import html2pdf from "html2pdf.js";

// function PdfGeneratePdf({ fontSize, colorMode, listProfe }) {
// const [imageAddress, setAddress] = useState();



let imgSign;
let imgPhoto,
  castCertificate,
  frontOfBankPassBook,
  affidavith,
  disabilityCert,
  udidCerti,
  addressProof,
  identityProof,
  domacileCertificate;

async function toDataURL(url, na) {
  var xhr = new XMLHttpRequest();
  xhr.onload = async function () {
    var reader = new FileReader();

    reader.onloadend = async function () {
      //return reader.result.toString();
      // console.log('na', na);
      let base64
      if (reader.result.toString().slice(0, 13) == 'data:text/xml') {
        base64 = 'data:image/png' + reader.result.toString().slice(13)
      } else {
        base64 = reader.result.toString()
      }
      if (na == "signPhoto") {
        ///setImgSign(reader.result.toString());
        imgSign = base64
      } else if (na == "cateCertificate") {
        //          setCastCertificate(reader.result.toString());
        castCertificate = base64;
        console.log('castCertificate', castCertificate);
      } else if (na == "frontOfBankPassBook") {
        //        setFrontOfBankPassBook(reader.result.toString());
        frontOfBankPassBook = base64;
      } else if (na == "affidavith") {
        // setAffidavith(reader.result.toString());
        affidavith = base64;
        // console.log(affidavith);
      } else if (na == "disabilityCert") {
        // setDisabilityCert(reader.result.toString());
        disabilityCert = base64;
      } else if (na == "udidcerti") {
        // setUdidCerti(reader.result.toString());
        udidCerti = base64;
      } else if (na == "addressproof1") {
        // setAddressProof(reader.result.toString());
        addressProof = base64;
      } else if (na == "identityproof") {
        // setIdentityProof(reader.result.toString());
        identityProof = base64;
      } else if (na == "domacileCertifi") {
        // setDomacileCertificate(reader.result.toString());
        domacileCertificate = base64;
      } else {
        // setImgPhoto(reader.result.toString());
        // console.log(reader.result.toString());
        imgPhoto = base64;
        // console.log('imgPhoto', imgPhoto);
      }
    };
    reader.readAsDataURL(xhr.response);
  };



  if (url != undefined && url != null && url?.slice(-3) == "pdf") {
    url = "https://evehicleadmin.mshfdc.co.in/api/cloudinaryimage/pdf2image/" + url?.slice(49)


  }

  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
  // setImgPhoto()
}


const imgShoing = (url) => {
  // setModalShow1(true);
  // setAddress(url);
};

export const downloadPdff = async (fontSize, colorMode, listProfe) => {
  let sign = await toDataURL(
    listProfe[0].signPhoto.url
      ? listProfe[0].signPhoto.url
      : listProfe[0].signPhoto.s3url,
    "signPhoto"
  );

  await toDataURL(
    listProfe[0].userPhoto.url
      ? listProfe[0].userPhoto.url
      : listProfe[0].userPhoto.s3url,
    "userPhoto"
  );

  await toDataURL(
    listProfe[0].castCertificate.url
      ? listProfe[0].castCertificate.url
      : listProfe[0].castCertificate.s3url,
    "cateCertificate"
  );


  await toDataURL(
    listProfe[0].frontOfBankPassBook.url
      ? listProfe[0].frontOfBankPassBook.url
      : listProfe[0].frontOfBankPassBook.s3url,
    "frontOfBankPassBook"
  );
  await toDataURL(
    listProfe[0].affidavith.url
      ? listProfe[0].affidavith.url
      : listProfe[0].affidavith.s3url,
    "affidavith"
  );
  await toDataURL(
    listProfe[0].disabilityCertImage.url
      ? listProfe[0].disabilityCertImage.url
      : listProfe[0].disabilityCertImage.s3url,
    "disabilityCert"
  );
  await toDataURL(
    listProfe[0].udId_File.url
      ? listProfe[0].udId_File.url
      : listProfe[0].udId_File.s3url,
    "udidcerti"
  );
  await toDataURL(
    listProfe[0].addressProof[0].url
      ? listProfe[0].addressProof[0].url
      : listProfe[0].addressProof[0].s3url,
    "addressproof1"
  );
  await toDataURL(
    listProfe[0].idProofFile.url
      ? listProfe[0].idProofFile.url
      : listProfe[0].idProofFile.s3url,
    "identityproof"
  );
  await toDataURL(
    listProfe[0].domacileCert.url
      ? listProfe[0].domacileCert.url
      : listProfe[0].domacileCert.s3url,
    "domacileCertifi"
  );

  // setTimeout(function () {

  const options = {
    margin: 10,
    filename: "seller-pdf.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };

  // if (cyndd) {
  setTimeout(function () {
    // console.log(`data:application/pdf;base64${affidavith.slice(20)}`);
    const html = `

      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <!-- Include Bootstrap styles -->
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
        <title>HTML to PDF</title>
      </head>
      <body>
       <div style=border:"1px solid grey" class="seller-special mb-3" ref={pdfRef} id="code-are-send">
            <div class="container" ref={pdfContainerRef}>
              <div class="row">
                <div
                  class="col-lg-12 conatct-seller ${colorMode === "white" ? "form-boder" : ""
      }"
                  style={{ fontSize: ${fontSize}px }}
                >
                  <div
                    class="border border-primary ${colorMode === "white" ? "border-primaryy-2" : ""
      }"
                  >
                    <div class="form-logo">
                      <img src={log} alt="" />
                    </div>
                    <div style='text-align:"center"' class="col-md-12 text-center mt-2">
                      <p class="fs-6 fw-bold">
                        महाराष्ट्र राज्य दिव्यांग वित्त व विकास महामंडळ मर्या.
                        मुंबई
                      </p>
                      <p class="fs-6 fw-bold">
                        (ISO 9001 : 2008 Certified Organisation)
                      </p>
                      <span class="fs-6 fw-bold">
                        Person with Disabilities Welfare Department, Government of
                        Maharashtra
                      </span>
                      <br />
                      <span>दिव्यांग कल्याण विभाग, महाराष्ट्र शासन </span>
                    </div>
                    <div class="form-logo-2">
                      <img src={log2} alt="" />
                    </div>
                  </div>

                  <div class="border border-primary">
                    <div class="col-md-12 text-center mt-2">
                      <span class="fs-6 ">
                        Application form for Environmentally Friendly
                        e-Vehicles/E-cart for Divyangjan to become self-reliant
                      </span>
                      <br />
                      <span>
                        दिव्यांग व्यक्तींना स्वावलंबी होण्याच्या दृष्टीने हरित
                        उर्जेवर चालणाऱ्या पर्यावरण स्नेही फिरत्या वाहनावरील दुकान
                        योजने अंतर्गत अर्ज करण्यासाठीचा अर्ज
                      </span>
                    </div>
                  </div>
                  <div
                    class="border border-primary"
                  >
                    <div class="col-md-12 mt-4 padding-class">
                      <h6>Personal Details/वैयक्तिक माहिती</h6>
                      <hr class="border border-1 border-primary m-0 p-0" />
                    </div>

                        <h3 class="text-center">
                          Your Registration No:
                          <span style={{ color: "red" }}>
                            ${listProfe[0]?.applicationNo}
                          </span>
                        </h3>

                        <h3 class="text-center">
                          Your Registration Date :
                          <span style={{ color: "red" }}>
                            ${new Date(listProfe[0]?.applicationDate).getDate() + "/" + (new Date(listProfe[0]?.applicationDate).getMonth() + 1) + "/" + new Date(listProfe[0]?.applicationDate).getFullYear()}
                          </span>
                        </h3>

                    <form action="" onSubmit={handleValidation}>
                      <div class="row m-0 p-0">
                        <div class="col-md-8 mt-3 m-0 p-0 disc-3">
                          <ul
                            type="disc"
                            class="disc-2 "
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="">

                              <label htmlFor="" class="fw-bold mr-2 mb-3">
                                Name of Applicant/अर्जदाराचे नाव <sup>*</sup>
                              </label>
                              <br />
                              <input
                                type="text"
                                placeholder="First Name"
                                class="pl-2 mr-2"
                                name="bene_firstname"
                                id="#txtNumeric"
                                required
                                value=${listProfe[0]?.bene_firstname}
                                onChange={handleInputChange}
                              />

                              <input
                                type="text"
                                class="pl-2 mr-2"
                                placeholder="Middle name"
                                name="bene_middlename"
                                value=${listProfe[0]?.bene_middlename}
                                onChange={handleInputChange}
                              />

                              <input
                                type="text"
                                placeholder="Last Name"
                                class="pl-2 mt-2"
                                name="bene_lastname"
                                value=${listProfe[0]?.bene_lastname}
                                onChange={handleInputChange}
                              />

                            </li>

                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2 mb-3">
                                Mother’s Name/आईचे नाव <sup>*</sup>
                              </label>
                              <div>
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  class="pl-2"
                                  name="mother_firstname"
                                  value=${listProfe[0].mother_firstname}
                                  onChange={handleInputChange}
                                />

                                <input
                                  type="text"
                                  class="pl-2 me-2"
                                  placeholder="Middle name"
                                  name="mother_middlename"
                                  value=${listProfe[0].mother_middlename}
                                  onChange={handleInputChange}
                                />

                                <input
                                  type="text"
                                  placeholder="Last Name"
                                  class="pl-2 mt-2"
                                  name="mother_lastname"
                                  value=${listProfe[0].mother_lastname}
                                  onChange={handleInputChange}
                                />

                              </div>
                            </li>

                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2 mb-2">
                                Date of Birth/जन्मतारीख <sup>*</sup>
                              </label>
                              <div>
                                <input
                                value=${new Date(listProfe[0]?.dob).getDate() + "/" + (new Date(listProfe[0]?.dob).getMonth() + 1) + "/" + new Date(listProfe[0]?.dob).getFullYear()}
                                  type="text"
                                  class="pl-2"
                                  id="bday"
                                  name="bday"
                                />

                                <p id="resultBday"></p>

                              </div>

                            </li>

                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2 mb-2">
                                Mobile No./भ्रमणध्वनी क्र. +९१<sup>*</sup>
                              </label>
                              <div>
                                <input

                                  type="number"
                                  placeholder="Only 10 Digit Number"
                                  class="pl-2"
                                  name="mobileset"
                                  value=${listProfe[0]?.mobile}
                                  onChange={changeHandle}
                                />

                                <input
                                  type="number"
                                  placeholder="+91"
                                  class="pl-2 d-none"
                                  name="phoneVerified"
                                  value={phoneVerified}
                                  onChange={changeHandle}
                                />

                              </div>
                            </li>
                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2 mb-2">
                                Email./ईमेल<sup>*</sup>
                              </label>
                              <div class="mb-3">
                                <input
                                  type="email"
                                  placeholder="example@gmail.com"
                                  class="pl-2"
                                  name="email"
                                  value=${listProfe[0].email}
                                  onChange={changeHandle}
                                />

                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-4 mt-4 text-center imag-sec">
                          <img
                            src=${imgPhoto}
                            style= "width: 200px ;height: 160px"
                            alt="Photograph"
                            class="border border-primary"
                            
                          />

                         

                          <input
                            type="file"
                            class="mt-1 mt-1-1"
                            name="userPhoto"
                            id="fileInput"
                            onChange={handleImage}
                          />
                          <label for="img">
                            Photograph <sup>*</sup>
                          </label>
                          <div class="space-between">
                           <!-- <p class="info-message">
                              (Only jpeg, jpg ,png and gif image with size 15 KB
                              to 100 KB allowed and Photo image Width and Height
                              less than 800px allowed)-->
                            </p>

                          </div>

                          <div
                            class="box box-box"
                            style="border 1px solid gray; width:200px;max-height:100px"
                          >
                            <img
                              src=${imgSign ? imgSign : ""}
                              style=" max-width:90%; max-height:100%;margin:auto"
                              class="border border-primary"
                            />
                            
                            <iframe
                            src=${imgSign ? imgSign : ""}
                           title="sign"
                          >
                          </iframe>
                            
                          </div>

                          <p class="error-message" style={{ color: "red" }}>

                          </p>
                          <input
                            type="file"
                            class="mt-1 mt-1-1  "
                            name="signPhoto"
                            id="fileInput1"
                            onChange={handleImage}
                          />
                          <label for="img">
                            Uploaded Signature<sup>*</sup>
                          </label>
                          <div class="space-between">
                            <!--<p class="info-message">
                              (Only jpeg, jpg ,png and gif image with size 3 KB to
                              30 KB allowed)
                            </p>-->
                            <p class="info-message">
                              (Only width:190px,height:50px)
                            </p>

                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-8">
                          <ul
                            type="disc "
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="mt-2">

                              <label htmlFor="" class="fw-bold mr-2 mt-2">
                                Gender/लिंग<sup>*</sup>
                              </label>
                              <div class="Gender">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="flexRadioDefault1"

                                    ${listProfe[0].gender == "Male"
        ? "checked"
        : ""
      }
                                   />

                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault1"
                                  >
                                    Male/पु
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="flexRadioDefault2"
                                    ${listProfe[0].gender == "Female"
        ? "checked"
        : ""
      }
                                   />

                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault2"
                                  >
                                    Female/स्त्री
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="flexRadioDefault3"
                                    ${listProfe[0].gender == "Trans"
        ? "checked"
        : ""
      }
                                   />
                                    
                                   
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault3"
                                  >
                                    Transgender/ट्रान्सजेडर
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="flexRadioDefault4"
                                    ${listProfe[0].gender == "Other"
        ? "checked"
        : ""
      }
                                   />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault4"
                                  >
                                    Other/इतर
                                  </label>
                                </div>
                              </div>

                            </li>
                            <li class="mt-4 mb-2">

                              <label htmlFor="" class="fw-bold mr-2">
                                Blood Group/रक्त गट<sup>*</sup>
                              </label>
                              <div class="Blood-Group">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="bloodGroup"
                                    id="flexRadioDefault5"
                                    ${listProfe[0].bloodGroup == "O+"
        ? "checked"
        : ""
      }
                                   

                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault5"
                                  >
                                    O+
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="bloodGroup"
                                    
                                    id="flexRadioDefault6"
                                    ${listProfe[0].bloodGroup == "O-"
        ? "checked"
        : ""
      }
                                   />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault6"
                                  >
                                    O-
                                  </label>
                                </div>

                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="bloodGroup"
                                    
                                    id="flexRadioDefault8"
                                    ${listProfe[0].bloodGroup == "A+"
        ? "checked"
        : ""
      }
                                   />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault8"
                                  >
                                    A+
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="bloodGroup"
                                    
                                    id="flexRadioDefault9"
                                    ${listProfe[0].bloodGroup == "A-"
        ? "checked"
        : ""
      }
                                   />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault9"
                                  >
                                    A-
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="bloodGroup"
                                    
                                    id="flexRadioDefault10"
                                    ${listProfe[0].bloodGroup == "B+"
        ? "checked"
        : ""
      }
                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault10"
                                  >
                                    B+
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="bloodGroup"
                                    ${listProfe[0].bloodGroup == "B-"
        ? "checked"
        : ""
      }
                                    id="flexRadioDefault11"

                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault11"
                                  >
                                    B-
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="bloodGroup"
                                   
                                    id="flexRadioDefault12"
                                    ${listProfe[0].bloodGroup == "AB+"
        ? "checked"
        : ""
      }

                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault12"
                                  >
                                    AB+
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="bloodGroup"
                                    
                                    id="flexRadioDefault13"
                                    ${listProfe[0].bloodGroup == "AB-"
        ? "checked"
        : ""
      }

                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault13"
                                  >
                                    AB-
                                  </label>
                                </div>

                                  </p>

                              </div>
                            </li>

                            <li class="pt-2">

                              <label htmlFor="" class="fw-bold mr-2">
                                Category/जात/प्रवर्ग <sup>*</sup>
                              </label>
                              <div class="Category-1">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="castCategory"

                                    ${listProfe[0].castCategory == "General"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault14"
                                  >
                                    General
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="castCategory"
                                   
                                    id="flexRadioDefault16"
                                    
                                    ${listProfe[0].castCategory == "OBC"
        ? "checked"
        : ""
      }
                                    
                                   />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault16"
                                  >
                                    OBC
                                  </label>
                                </div>

                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="castCategory"
                                    
                                    id="flexRadioDefault17"
                                   
                                    ${listProfe[0].castCategory == "SC"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault17"
                                  >
                                    SC
                                  </label>
                                </div>

                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="castCategory"
                                   
                                    id="flexRadioDefault18"
                                   
                                    ${listProfe[0].castCategory == "ST"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault18"
                                  >
                                    ST
                                  </label>
                                </div>

                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="castCategory"
                                   
                                    id="flexRadioDefault19"
                                    
                                    ${listProfe[0].castCategory == "VJNT"
        ? "checked"
        : ""
      }
                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault19"
                                  >
                                    VJNT
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="castCategory"
                                   
                                    
                                    ${listProfe[0].castCategory == "NT-B"
        ? "checked"
        : ""
      }

                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault20"
                                  >
                                    NT-B
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="castCategory"
                                   
                                    id="flexRadioDefault21"
                                    
                                    ${listProfe[0].castCategory == "NT-C"
        ? "checked"
        : ""
      }

                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault21"
                                  >
                                    NT-C
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="castCategory"
                                   
                                    id="flexRadioDefault22"
                                    
                                    ${listProfe[0].castCategory == "NT-D"
        ? "checked"
        : ""
      }

                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault22"
                                  >
                                    NT-D
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="castCategory"
                                   
                                    id="flexRadioDefault22"
                                    
                                    ${listProfe[0].castCategory == "SBC"
        ? "checked"
        : ""
      }

                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault22"
                                  >
                                    SBC
                                  </label>
                                </div>

                              </div>
                              <span class="mt-4">
                                <!--(Attached Caste Certificate and Non-Creamy Layer-->
                                <!--Certificate for OBC/SC/ST/VJNT/NT-B/NT-C/NT-D/SBC)-->
                              </span>
                            </li>

                            <li class="mt-5">

                              <label htmlFor="" class="fw-bold mr-2">
                                Caste Certificate / जातीचा दाखला<sup>*</sup>
                              </label>
                              <div class="d-flex">
                                <input
                                  type="file"
                                  class="pl-2"
                                  disabled=${listProfe[0].castCategory == "General"
      }
                                  name="castCertificate"
                                  id="castCertificate"
                                  onChange={handleImage}
                                />

                              </div>
                              <div class="card" style="border 1px solid gray; width:300px;height:300px;display:flex;justify-content:center">
                               <img src=${castCertificate}
                               alt="caste" style="max-width:100%; max-height:100% ; margin:auto" />
                              
                              
                              </div>

                             
                              <p
                                class="error-message"
                                style={{ color: "red" }}
                              >

                              </p>
                            </li>

                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                Business Category/व्यवसाय श्रेणी <sup>*</sup>
                              </label>
                              <div class="d-flex">
                                <p class="" style="border:1px solid grey; padding:.3em .7em; margin:.1em">
                                ${listProfe[0]?.businessCategory?.name}
                                </p>
                                 
                          

                              </div>
                            </li>

                              <div class="showInput">
                                <input
                                  type="text"
                                  name="otherBusiness"
                                  id=""
                                  placeholder="Please Enter Business Category"
                                  value=${listProfe[0].otherBusiness}

                                />
                              </div>

                              <div
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  marginTop: "10px",

                              >
                                <img
                                  src={filterImage?.icon?.url}
                                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                                  alt=""
                                />
                              </div>

                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                Marital Status/वैवाहिक स्थिती<sup>*</sup>
                              </label>
                              <div class="Marital-Status">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="maritalStatus"
                                    ${listProfe[0].maritalStatus == "Married"
        ? "checked"
        : ""
      }/>

                                    <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault24"
                                  >
                                    Married
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="maritalStatus"
                                    ${listProfe[0].maritalStatus == "Unmarried"
        ? "checked"
        : ""
      }/>
                                    <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault23"
                                  >
                                    Unmarried
                                  </label>
                                </div>

                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="maritalStatus"
                                    ${listProfe[0].maritalStatus == "Widow"
        ? "checked"
        : ""
      }/>
                                    <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault25"
                                  >
                                    Widow
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="maritalStatus"
                                    ${listProfe[0].maritalStatus == "Divorced"
        ? "checked"
        : ""
      }/>
                                    <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault26"
                                  >
                                    Divorced
                                  </label>
                                </div>
                              </div>

                            </li>

                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                If you are married, then please provide name of
                                the Spouse/विवाहित असल्यास पतिचे/पत्नीचे संपूर्ण
                                नाव
                                <sup>*</sup>
                              </label>
                              <div class="d-flex">
                                <input
                                  type="text"
                                  class="pl-2"
                                  disabled={
                                    listProfe[0].maritalStatus == "Unmarried" ||
                                    listProfe[0].maritalStatus == "Widow" ||
                                    listProfe[0].maritalStatus == "Divorced"
                                  }
                                  name="spouse_name"
                                  value=${listProfe[0].spouse_name}
                                
                                />

                              </div>
                            </li>

                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <ul
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                Name of Guardian of the Applicant/Assistant/ Care
                                Taker अर्जदाराच्या पालकाचे/सहाय्यकाचे नाव
                                <sup>*</sup>
                              </label>
                              <div class="d-flex">
                                <input
                                  type="text"
                                  class="pl-2"
                                  name="guardian_name"
                                  value=${listProfe[0].guardian_name}
                                  onChange={changeHandle}
                                />
                              </div>

                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <ul style={{ listStyle: "unset" }}>
                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                His/her Contact No<sup>*</sup>
                              </label>
                              <div class="d-flex">
                                <input
                                  type="number"
                                  class="pl-2"
                                  name="guardian_contact"
                                  value=${listProfe[0].guardian_contact}
                                  onChange={changeHandle}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-12">
                          <ul style={{ listStyle: "unset" }}>

                            <li class="mt-3" style={{ listStyle: "none" }}>

                              <label htmlFor="" class="fw-bold mr-2">
                                Relationship of guardian with the
                                applicant/अर्जदाराचे पालकांसोबतचे नाते<sup>*</sup>
                              </label>
                              <div class="Relationship">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault27"
                                    ${listProfe[0].relatedToGuardian == "Father"
        ? "checked"
        : ""
      }
                                    name="relatedToGuardian"
                                   
                                   />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault27"
                                  >
                                    Father
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault28"
                                    name="relatedToGuardian"
                                    ${listProfe[0].relatedToGuardian == "Mother"
        ? "checked"
        : ""
      }
                                    
                                 />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault28"
                                  >
                                    Mother
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault29"
                                    name="relatedToGuardian"
                                    value="Wife"
                                    ${listProfe[0].relatedToGuardian == "Wife"
        ? "checked"
        : ""
      }
                                   />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault29"
                                  >
                                    Wife
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault30"
                                    name="relatedToGuardian"
                                    ${listProfe[0].relatedToGuardian ==
        "Husband"
        ? "checked"
        : ""
      }
                                   />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault30"
                                  >
                                    Husband
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault31"
                                    name="relatedToGuardian"
                                    value="Other"
                                    ${listProfe[0].relatedToGuardian == "Other"
        ? "checked"
        : ""
      } 
                                    }
                                   />
                                    
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault31"
                                  >
                                    Other
                                  </label>
                                </div>
                              </div>

                            </li>

                            <li class="mt-5" style={{ listStyle: "none" }}>

                              <label htmlFor="" class="fw-bold mr-2">
                                Educational Details/शैक्षणिक अर्हता<sup>*</sup>
                              </label>
                              <div class="Educational">
                              ${listProfe[0].educationDetails.primary
        ? '<input type="checkbox" class="seller-checkbox" id="Primary" name="primary" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      } 
                                <label htmlFor="Primary" class="ml-1 mr-2  ">
                                  Primary
                                </label>
                                ${listProfe[0].educationDetails.secondary
        ? '<input type="checkbox" class="seller-checkbox" id="Secondary" name="secondary" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      } 
                                <label
                                  htmlFor="Secondary"
                                  class="mr-2 ml-1 seller-label"
                                >
                                  Secondary
                                </label>
                                ${listProfe[0].educationDetails.higherSecondary
        ? '<input type="checkbox" class="seller-checkbox" id="HigherSecondary" name="higherSecondary" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      } 
                                <label
                                  htmlFor="Higher Secondary"
                                  class="mr-2 ml-1 seller-label"
                                >
                                  Higher Secondary
                                </label>
                                ${listProfe[0].educationDetails.diploma
        ? '<input type="checkbox" class="seller-checkbox" id="Diploma" name="diploma" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      } 
                                <label
                                  htmlFor="Diploma"
                                  class="mr-2 ml-1 seller-label"
                                >
                                  Diploma
                                </label>
                                ${listProfe[0].educationDetails.graduate
        ? '<input type="checkbox" class="seller-checkbox" id="Graduate" name="graduate" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      } 
                                <label
                                  htmlFor="Graduate"
                                  class="mr-2 ml-1 seller-label"
                                >
                                  Graduate
                                </label>
                              </div>
                              <div class="d-flex mt-5">
                              ${listProfe[0].educationDetails.postGraduate
        ? '<input type="checkbox" class="seller-checkbox" id="PostGraduate" name="postGraduate" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      } 
                                <label
                                  htmlFor="Postgraduate"
                                  class="mr-2 ml-1 seller-label"
                                >
                                  Postgraduate
                                </label>
                                ${listProfe[0].educationDetails.uneducated
        ? '<input type="checkbox" class="seller-checkbox" id="Uneducated" name="uneducated" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      } 
                                <label
                                  htmlFor="Uneducated"
                                  class="mr-2 ml-1 seller-label"
                                >
                                  Uneducated
                                </label>
                              </div>

                                <p style="color: red;  visibility: hidden">
                                  Educational Details is Required!
                                </p>

                            </li>

                          </ul>
                        </div>
                      </div>
                      <div class="col-md-12 mt-4 padding-class">
                        <h6 style={{ fontWeight: "800" }}>
                          Address Details/ निवासाचे तपशिल
                        </h6>
                        <hr class="border border-1 border-primary m-0 p-0" />
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <ul
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="mt-3, mb-2">

                              <label htmlFor="" class="fw-bold mr-2">
                                Are you being resident of Maharashtra State?/आपण
                                महाराष्ट्र राज्याचे रहिवासी आहात का? <sup>*</sup>
                              </label>
                              <div class="d-flex">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault32"
                                    name="residentOf"
                                    ${listProfe[0].residentOf == "Yes"
        ? "checked"
        : ""
      }/>
                                    <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault32"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault33"
                                    name="residentOf"
                                    ${listProfe[0].residentOf == "No"
        ? "checked"
        : ""
      }
                                    />
                                    <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault33"
                                  >
                                    No
                                  </label>
                                </div>
                                <span class="ml-3">
                                  (If Yes Please Upload Domicile Certificate)
                                </span>
                              </div>

                            </li>

                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <ul
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                Domacile Certificate/अधिवास प्रमाणपत्र<sup>*</sup>
                              </label>
                              <div class="d-flex">
                                <input
                                  type="file"
                                  id="domacileCert"
                                  class="pl-2"
                                  name="domacileCert"
                                  onChange={handleImage}
                                />
                              

                               

                                <p
                                  class="error-message"
                                  style={{ color: "red" }}
                                >

                                </p>

                              </div>
                              <div class="card" style="border 1px solid gray; width:300px;height:300px;display:flex;justify-content:center">
                              <img src=${domacileCertificate}
                              alt="passbook" style="max-width:100%; max-height:100% ; margin:auto" />
                              
                              </div>
                             
                              
                            </li>
                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold">
                                Correspondence Address / सध्याचा पत्ता<sup>*</sup>
                              </label>
                              <div class="Correspondence">
                                <p  style="width:90%; padding:.3em .7em; margin:.1em ; border:.1em solid black ">
                                ${listProfe[0].permanentAddress}
                                </p>
                                  

                              </div>
                            </li>
                          </ul>
                          <div class="Village-sec ml- Village-sec-2">
                            <div class="row">
                              <div class="col-md-4">
                                <li
                                  class="mt-3"
                                  style={{ listStyleType: "none" }}
                                >

                                  <label htmlFor="" class="fw-bold mr-1">
                                    Post / पोस्ट<sup>*</sup>
                                  </label>
                                  <div class="d-flex">
                                    <input
                                      type="text"
                                      class="pl-1"
                                      name="post"
                                      value=${listProfe[0].post}
                                      onChange={changeHandle}
                                    />
                                  </div>

                                </li>
                              </div>
                              <div class="col-md-4">
                                <li
                                  class="mt-3"
                                  style={{ listStyleType: "none" }}
                                >

                                  <label htmlFor="" class="fw-bold mr-1">
                                    State / राज्य<sup>*</sup>
                                  </label>
                                  <div class="d-flex">
                                    <input
                                      type="text"
                                      class="pl-1"
                                      name="state"
                                      value=${listProfe[0].state}
                                      onChange={changeHandle}
                                    />
                                  </div>

                                </li>
                              </div>
                              <div class="col-md-4">
                                <li
                                  class=""
                                  style={{ listStyleType: "none" }}
                                >

                                  <label htmlFor="" class="fw-bold mr-1">
                                    Division/विभाग<sup>*</sup>
                                  </label>
                                  <input
                                    class="pl-1"
                                    type="text"
                                    value=${listProfe[0].division[0]?.title}
                                    onChange={divisonChange}
                                  />
                                    

                                </li>
                              </div>
                              <div class="col-lg-4">
                                <li
                                  class="mt-3"
                                  style={{ listStyleType: "none" }}
                                >

                                  <label htmlFor="" class="fw-bold mr-1">
                                    District/जिल्हा<sup>*</sup>
                                  </label>
                                  <input
                                  class="pl-1"
                                  type="text"
                                    value=${listProfe[0].district[0]?.title}
                                    name="district"
                                    onChange={changeHandle}
                                  />
                                    <option selected>
                                      Open this select menu
                                    </option>
                                   
                                  </input>

                                </li>
                              </div>
                              <div class="col-md-4">
                                <li
                                  class="mt-3"
                                  style={{ listStyleType: "none" }}
                                >

                                  <label htmlFor="" class="fw-bold mr-1">
                                    Block/तालुका<sup>*</sup>
                                  </label>
                                  <div class="d-flex">
                                    <input
                                      type="text"
                                      class="pl-2"
                                      name="block"
                                      value=${listProfe[0].block}
                                      onChange={changeHandle}
                                    />
                                  </div>

                                </li>
                              </div>
                              <div class="col-md-4">
                                <li
                                  class="mt-3"
                                  style={{ listStyleType: "none" }}
                                >

                                  <label htmlFor="" class="fw-bold mr-1">
                                    Village/गांव<sup>*</sup>
                                  </label>
                                  <div class="Village">
                                    <input
                                      type="text"
                                      class="pl-2"
                                      name="village"
                                      value=${listProfe[0].village}
                                      onChange={changeHandle}
                                    />
                                  </div>

                                </li>
                              </div>

                              <div class="col-md-4">
                                <li
                                  class="mt-3"
                                  style={{ listStyleType: "none" }}
                                >

                                  <label htmlFor="" class="fw-bold mr-1">
                                    Pincode / पिनकोड<sup>*</sup>
                                  </label>
                                  <div class="d-flex">
                                    <input
                                      type="number"
                                      class="pl-2"
                                      name="pincode"
                                      value=${listProfe[0].pincode}
                                    
                                    />
                                  </div>

                                </li>
                              </div>

                              <ul style={{ listStyle: "unset" }}>
                                <li
                                  class="mt-5"
                                  style={{ listStyleType: "none" }}
                                >

                                  <label htmlFor="" class="fw-bold mr-2">
                                    Permanent Address / कायमचा पत्ता<sup>*</sup>
                                  </label>
                                  <div class="Permanent">
                                    <p style="width:90%; padding:.3em .7em; margin:.1em ; border:.1em solid black ">
                                    ${listProfe[0].permanentAddress}</p>
                                    
                                  </div>

                                </li>
                              </ul>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <ul
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                Documents of Address Proof/ निवासी असल्याचा पुरावा
                                <sup>*</sup>
                              </label>
                              <div class="Muscular-1 Muscular-2 d-flex">
                              ${listProfe[0].docOfAddProof.driveLicen
        ? '<input type="checkbox" class="seller-checkbox" id="DrivingLicense" name="drivingLicense" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      } 
                                <label
                                  htmlFor="Driving License"
                                  class="ml-1 mr-2 seller-label"
                                >
                                  Driving License
                                </label>
                                ${listProfe[0].docOfAddProof.rationCard
        ? '<input type="checkbox" class="seller-checkbox" id="rationCard" name="rationCard" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      } 
                                <label
                                  htmlFor="Ration Card"
                                  class="ml-1 mr-2 seller-label"
                                >
                                  Ration Card
                                </label>
                                ${listProfe[0].docOfAddProof.voterId
        ? '<input type="checkbox" class="seller-checkbox" id="VoterId" name="voterId" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      } 
                                <label htmlFor="Voter Id" class="ml-1 mr-2">
                                  Voter Id
                                </label>
                                ${listProfe[0].docOfAddProof.passport
        ? '<input type="checkbox" class="seller-checkbox" id="Passport" name="passport" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                <label
                                  htmlFor="Passport"
                                  class="ml-1 mr-2 seller-label"
                                >
                                  Passport
                                </label>
                              </div>
                              <div class="d-flex mt-3 Muscular-2">
                              ${listProfe[0].docOfAddProof.electricBill
        ? '<input type="checkbox" class="seller-checkbox" id="VoterId" name="voterId" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                <label
                                  htmlFor="Electricity Bill"
                                  class="ml-1 mr-2  seller-label"
                                >
                                  Electricity Bill
                                </label>
                                ${listProfe[0].docOfAddProof.properTaxRec
        ? '<input type="checkbox" class="seller-checkbox" id="PropertyTaxReceipt" name="propertyTaxReceipt" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                <label
                                  htmlFor="Property Tax Receipt"
                                  class="ml-1 mr-2 seller-label"
                                >
                                  Property Tax Receipt
                                </label>
                              </div>

                                <p style="color: red;  visibility: hidden">
                                  Documents of Address Proof is Required!
                                </p>

                            </li>

                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                Address proof / पत्त्याचा पुरावा<sup>*</sup>

                                ${listProfe[0]?.addressProof?.url ? (
        <button
          class="mt-2"
          onClick={() =>
            imgShoing(
              listProfe[0]?.addressProof?.url
            )
          }
        >
          Show
        </button>
      ) : (
        ""
      )
      }

                                <p
                                  class="error-message"
                                  style={{ color: "red" }}
                                >

                                </p>
                              </label>
                              <div class="d-flex">
                                <input
                                  type="file"
                                  id="addressProo"
                                  class="pl-2"
                                  name="addressProof"
                                  onChange={handleImage}
                                />
                               
                              </div>

                              <div class="card" style="border 1px solid gray; width:300px;height:300px;display:flex;justify-content:center">
                              <img src=${addressProof}
                              alt="passbook" style="max-width:100%; max-height:100% ; margin:auto" />
                              
                              </div>
                              
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-12 mt-4 padding-class">
                        <h6>Disability Details / दिव्यांगाची माहिती</h6>
                        <hr class="border border-1 border-primary m-0 p-0" />
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <ul
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                Do You have Disability Certificate /आपल्याकडे
                                दिव्यांगत्व प्रमाणपत्र आहे का<sup>*</sup>
                              </label>

                              <div class="d-flex">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flex"
                                    name="disabilityCert"
                                   
                                   
                                    ${listProfe[0].disabilityCert === true
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flex1"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flex2"
                                    name="disabilityCert"
                                    ${listProfe[0].disabilityCert === false
        ? "checked"
        : ""
      }
                                    
                                   />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flex2"
                                  >
                                    No
                                  </label>
                                </div>
                                <span class="ml-3">

                                  (If Yes, Please fill in the following Details &
                                  attach disability certificate)
                                </span>

                              </div>
                            </li>
                            <li class="mt-3" >

                              <label htmlFor="" class="fw-bold mr-2">
                                Disability Certificate/दिव्यांगत्वाचे प्रमाणपत्र
                                <sup>*</sup>


                                <p
                                  class="error-message"
                                  style={{ color: "red" }}
                                >

                                </p>
                              </label>
                              <div class="d-flex">
                                <input
                                  type="file"
                                  id="fileInput2"
                                  class="pl-2"
                                  name="disabilityCertImage"

                                />
                               
                              </div>
                                      
                              <div class="card" style="border 1px solid gray; width:300px;height:200px;display:flex;justify-content:center">
                              <img src=${disabilityCert}
                              alt="passbook" style="max-width:100%; max-height:100% ; margin:auto" />
                              
                              </div>

                               
                            </li>
                          </ul>
                        </div>

                        <div class="col-md-12">
                          <ul
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                Do You have UDID Number<sup>*</sup>
                              </label>

                              <div class="d-flex">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flex8 udidType"
                                    name="udidType"
                                   

                                    ${listProfe[0].udidType == "Permanent UDID"
        ? "checked"
        : ""
      }
                                   />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flex8"
                                  >
                                    Permanent UDID /कायमस्वरूपी UDID
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flex6"
                                    name="udidType"
                                    
                                    ${listProfe[0].udidType == "Temporary UDID"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flex6"
                                  >
                                    Temporary UDID /तात्पुरता UDID
                                  </label>
                                </div>

                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="row mt-5 pt-2">
                        <div class="col-md-6">
                          <ul
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="">

                              <label htmlFor="" class="fw-bold mr-2">
                                UDID Certificate No/UDID प्रमाणपत्र क्र
                                <sup>*</sup>
                              </label>
                              <div class="d-flex">
                                <input
                                  type="text"
                                  class="pl-2"
                                  name="udId_No"
                                  value=${listProfe[0].udId_No}
                                  onChange={changeHandle}
                                />
                              </div>

                            </li>

                            <li class="">

                              <label htmlFor="" class="fw-bold mr-2">
                                UDID Certificate/UDID प्रमाणपत्र <sup>*</sup>

                                

                                <p
                                  class="error-message"
                                  style={{ color: "red" }}
                                >
                                  
                                </p>
                              </label>
                              <div class="d-flex">
                                
                                
                              </div>

                              <div class="card" style="border 1px solid gray; width:300px;height:300px;display:flex;justify-content:center">
                              <img src=${udidCerti}
                              alt="passbook" style="max-width:100%; max-height:100% ; margin:auto" />
                              
                              </div>

                            </li>
                          </ul>
                        </div>

                        <div class="col-md-6">

                        </div>
                        <div class="col-md-6">

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <ul
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="">

                              <label htmlFor="" class="fw-bold mr-2">
                                Percentage of disability (40% or more than
                                40%)/दिव्यांगत्व प्रमाण (किमान ४०% अथवा त्यापेक्षा
                                अधिक) <sup>*</sup>
                              </label>
                              <div class="d-flex">
                                <input
                                  type="number"
                                  class="pl-2"
                                  name="PerOfDis"
                                  min="40"
                                  max="100"
                                  value=${listProfe[0].PerOfDis}
                                  onChange={changeHandle}
                                />
                              </div>

                            </li>

                                  </li>
                            <li class="mt-3">
                              <label htmlFor="" class="fw-bold mr-2">
                                Types Of Disability/दिव्यांगत्वाचा प्रकार
                                <sup>*</sup>
                              </label>

                                <p style="color: red;  visibility: hidden">
                                  Types Of Disability is Required!
                                </p>

                              <div class="Disability">
                              <li>
                              <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.locoMotor
        ? '<input type="checkbox" class="seller-checkbox" id="locomotorsDisability" name="locomotorsDisability" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Locomotors Disability"
                                  >
                                    Locomotors Disability/अस्थिव्यंग
                                  </label>
                                </div>
                              </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.leprosy
        ? '<input type="checkbox" class="seller-checkbox" id="Leprosy" name="leprosy" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Leprosy Cured persons"
                                  >
                                    Leprosy Cured persons/कुष्ठरोग निवारीत मुक्त
                                  </label>
                                </div>
                                
                                </li>
                               
                                
                               <li>
                               <div class="form-check mr-2">
                               ${listProfe[0].typesOfDisability.cerebralPal
        ? '<input type="checkbox" class="seller-checkbox" id="Cerebral" name="cerebral" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                 <label
                                   class="form-check-label"
                                   htmlFor="Cerebral Palsy"
                                 >
                                   Cerebral Palsy/मेंदूचा पक्षाघात
                                 </label>
                               </div>
                               </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.dwarfism
        ? '<input type="checkbox" class="seller-checkbox" id="Dwarfism" name="dwarfism" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Dwarfism"
                                  >
                                    Dwarfism /शारीरिक वाढ खुंटणे
                                  </label>
                                </div>
                                </li>

                                <li>
                                <!--<div class="Muscular-1 d-flex mt-3">-->
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.muscular_dy
        ? '<input type="checkbox" class="seller-checkbox" id="Muscular" name="muscular" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Muscular Dystrophy"
                                  >
                                    Muscular Dystrophy/स्नायुंची विकृती
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.acidAttactVic
        ? '<input type="checkbox" class="seller-checkbox" id="Acid" name="acid" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Acid Attack victim"
                                  >
                                    Acid Attack victim/आम्ल हल्ला पीडित
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.blindness
        ? '<input type="checkbox" class="seller-checkbox" id="Blindness" name="blindness" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Blindness"
                                  >
                                    Blindness/पुर्णतः अंध
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.lowVision
        ? '<input type="checkbox" class="seller-checkbox" id="LowVision" name="lowVision" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Low-vision"
                                  >
                                    Low-vision/अंशतः अंध (दृष्टिदोष)
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.hearingImp
        ? '<input type="checkbox" class="seller-checkbox" id="Hearing" name="hearing" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Hearing Impairment"
                                  >
                                    Hearing Impairment (Deaf and hard of
                                    Hearing)/कर्णबधीर / ऐकू कमी येणे
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.speechNlang
        ? '<input type="checkbox" class="seller-checkbox" id="Speech" name="speech" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Speech"
                                  >
                                    Speech and Language disability/वाचा / भाषा दोष
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.intellect
        ? '<input type="checkbox" class="seller-checkbox" id="intellectual" name="intellectual" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Intellectual Disability"
                                  >
                                    Intellectual Disability/बौद्धिक अक्षम
                                  </label>
                                </div>
                                </li>
                                <li>
                              <div class="form-check mr-2">
                              ${listProfe[0].typesOfDisability.specific_learn
        ? '<input type="checkbox" class="seller-checkbox" id="Specific" name="specific" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                <label
                                  class="form-check-label"
                                  htmlFor="Specific"
                                >
                                  Specific Learning Disabilities/विशिष्ट अध्ययन
                                  अक्षम
                                </label>
                              </div>
                              </li>
                              <li>
                              <div class="form-check mr-2">
                              ${listProfe[0].typesOfDisability.autism
        ? '<input type="checkbox" class="seller-checkbox" id="Autism" name="Autism" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                <label
                                  class="form-check-label"
                                  htmlFor="Autism"
                                >
                                  Autism Spectrum Disorder/स्वमग्न
                                </label>
                              </div>
                              </li>
                              <li>
                              <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.mentalBehave
        ? '<input type="checkbox" class="seller-checkbox" id="Mental" name="Mental" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Mental"
                                  >
                                    Mental Behavior/Mental illness/मानसिक वर्तन /
                                    मानसिक आधार
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.multiScler
        ? '<input type="checkbox" class="seller-checkbox" id="Multiple" name="Multiple" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Multiple"
                                  >
                                    Multiple Sclerosis/हातापायातील स्नायू कमजोर /
                                    शिथिल होणे
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.parkinson
        ? '<input type="checkbox" class="seller-checkbox" id="Parkinson" name="Parkinson" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Parkinson"
                                  >
                                    Parkinson's disease/कंपावात
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.hemoPhilia
        ? '<input type="checkbox" class="seller-checkbox" id="hemophilia" name="hemophilia" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Hemophilia"
                                  >
                                    Hemophilia/अधिक रक्तस्त्राव
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2 mt-4">
                                ${listProfe[0].typesOfDisability.thalassemia
        ? '<input type="checkbox" class="seller-checkbox" id="thalassemia" name="thalassemia" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Thalassemia"
                                  >
                                    Thalassemia/रक्ताची कमतरता
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.sickieCell
        ? '<input type="checkbox" class="seller-checkbox" id="Sickle" name="Sickle" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Sickle"
                                  >
                                    Sickle Cell disease/रक्ताचे हिमोग्लोबीनचे
                                    प्रमाण कमी होणे
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.chronicNeu
        ? '<input type="checkbox" class="seller-checkbox" id="chronic" name="chronic" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="Chronic"
                                  >
                                    Chronic Neurological conditions/मज्जासंस्थेचे
                                    तीव्र आजार
                                  </label>
                                </div>
                                </li>
                                <li>
                                <div class="form-check mr-2">
                                ${listProfe[0].typesOfDisability.multipleDisab
        ? '<input type="checkbox" class="seller-checkbox" id="multipleDisabilities" name="multipleDisabilities" checked  />'
        : '<input type="checkbox" class="seller-checkbox">'
      }
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault56"
                                  >
                                    Multiple Disabilities/बहुविकलांग
                                  </label>
                                </div>
                              </div>
                              </li>
                             <!-- </div>-->
                            
                               
                                
                               
                              </div>
                              <div class="">
                              
                             
                              
                               
                               
                                
                                
                                
                               
                                
                                
                               
                              </div>
                              <div class="">
                                
                               
                               
                              </div>
                              <div class="">
                                
                               
                                
                              </div>
                              <div class="">
                               
                               
                               
                              </div>
                              <div class="">
                               
                               
                         

                            <li class="mt-5 ml-4">

                              <label htmlFor="" class="fw-bold mr-2">
                                Do you have Disability by Birth/तुम्हाला जन्मतःच
                                दिव्यांगत्व आहे का<sup>*</sup>
                              </label>

                              <div class="have-Disability ml-2">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault57"
                                   
                                    ${listProfe[0].disabltyByBirth === true
        ? "checked"
        : ""
      }
                                    
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault57"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault58"
                                   
                                    name="disabilitiesBirth"
                                  
                                    ${listProfe[0].disabltyByBirth === false
        ? "checked"
        : ""
      }
                                   
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault58"
                                  >
                                    No
                                  </label>
                                </div>
                                <label htmlFor="" class="ml-5 seller-label">
                                  If No then Disability Since
                                </label>
                                <input
                                  type="text"
                                  class="ml-3"
                                  disabled={disabilitiesBirth === true}
                                  name="disabtlySince"
                                  value=${listProfe[0].disabtlySince}
                                  onChange={changeHandle}
                                />
                              </div>
                            </li>



                            <li class="mt-5 ml-4">

                              <label htmlFor="" class="fw-bold mr-2">
                                Area of Disability/दिव्यांगत्व क्षेत्र<sup>*</sup>
                              </label>

                              <div class="Area-of-Disability">
                                <p class="ml-2"  style="width:90%; height:80px  ">
                               
                                  
                                  ${listProfe[0].areaOfDisable}
                               
                                  </p>
                                </div>
                            </li>
                            
                          </ul>
                        </div>
                      <div class="col-md-12 mt-4 padding-class">
                        <h6 style={{ fontWeight: "700" }}>
                          Employment Details /रोजगाराची सद्यस्थिती
                        </h6>
                        <hr class="border border-1 border-primary m-0 p-0" />
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <ul
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                Employment Status/रोजगाराची स्थिती<sup>*</sup>
                              </label>

                                <p style="color: red;  visibility: hidden">
                                  Employment Status is Required!
                                </p>

                              <div class="d-flex">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="Employed"
                                    name="status"
                                    ${listProfe[0].employmentDetail.status ==
        "Employed"
        ? "checked"
        : ""
      }
                                  
                                     />
                                  <label
                                    class="form-check-label"
                                    htmlFor="Employed"
                                  >
                                    Employed
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="Unemployed"
                                    name="status"
                                    
                                    ${listProfe[0].employmentDetail.status ==
        "Unemployed"
        ? "checked"
        : ""
      }
                                  />
                                  <label
                                    class="form-check-label"
                                    htmlFor="Unemployed"
                                  >
                                    Unemployed
                                  </label>
                                </div>
                              </div>
                            </li>

                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                Are you employed by the Government, Semi
                                Government, or any Government body/Corporation?
                                /आपण शासकीय/निमशासकीय/मंडळे/महामंडळे यांचे
                                कर्मचारी आहात का?<sup>*</sup>
                              </label>

                                <p style="color: red;  visibility: hidden">
                                  Employment Status is Required!
                                </p>

                              <div class="d-flex">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="employeeByGov"
                                    name="employeeByGov"
                                   
                                    ${listProfe[0].employmentDetail?.employeeByGov == "Yes"
        ? "checked"
        : ""
      }
                                   />
                                  <label
                                    class="form-check-label"
                                    htmlFor="employeeByGov"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="employeeByGov"
                                    name="employeeByGov"
                                    
                                    ${listProfe[0].employmentDetail?.employeeByGov == "No"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="employeeByGov"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                              <div class="Semi-Government-2 mt-2">
                                <textarea
                                  name="employeeGovInDepart"
                                  id=""
                                  cols="70"
                                  rows="2"
                                  disabled={empDetails.employeeByGov == "No"}
                                  value=${listProfe[0].employeeGovInDepart}
                                  onChange={changeHandle}
                                ></textarea>
                              </div>
                            </li>

                            <li class="mt-5">

                              <label htmlFor="" class="fw-bold mr-2">
                                BPL/APL/दारिद्र्यरेषेच्या वर / दारिद्र्यरेषेच्या
                                खालील<sup>*</sup>
                              </label>

                                <p style="color: red;  visibility: hidden">
                                  BPL/APL is Required!
                                </p>

                              <div class="d-flex">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault62"
                                    name="bpl"
                                    
                                    ${listProfe[0].employmentDetail.bpl == "N/A"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault62"
                                  >
                                    N/A
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault63"
                                    name="bpl"
                                    
                                    ${listProfe[0].employmentDetail.bpl == "APL"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault63"
                                  >
                                    APL
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault64"
                                    name="bpl"
                                    ${listProfe[0].employmentDetail.bpl == "BPL"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault64"
                                  >
                                    BPL
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault65"
                                    name="bpl"
                                   
                                    ${listProfe[0].employmentDetail.bpl ==
        "Antoday"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault65"
                                  >
                                    Antyoday
                                  </label>
                                </div>
                              </div>
                            </li>

                            <li class="mt-5">

                              <label htmlFor="" class="fw-bold mr-2">
                                Annual Income of the Applicant/लाभार्थ्याचे
                                वार्षिक उत्पन्न<sup>*</sup>
                              </label>

                                <p style="color: red;  visibility: hidden">
                                  Annual Income of the Applicant is Required!
                                </p>

                              <div class="d-flex">
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault66"
                                    name="annualIncom"
                                    
                                    ${listProfe[0].employmentDetail
        .annualIncom == "Below 10k"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault66"
                                  >
                                    Below 10k
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault67"
                                    name="annualIncom"
                                    ${listProfe[0].employmentDetail
        .annualIncom == "10k to 1 lakhs"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault67"
                                  >
                                    10k to 1 lakhs
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault68"
                                    name="annualIncom"
                                    ${listProfe[0].employmentDetail
        .annualIncom == "1 lakhs to 2.5 lakhs"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault68"
                                  >
                                    01 lakhs to 2.5 lakhs
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault69"
                                    name="annualIncom"
                                    ${listProfe[0].employmentDetail
        .annualIncom == "2.5 lakhs and more"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault69"
                                  >
                                    Above 2.5 lakhs
                                  </label>
                                </div>
                              </div>
                            </li>

                          </ul>
                        </div>
                      </div>
                      <div class="col-md-12 mt-5  padding-class">
                        <h6 style={{ fontWeight: "700" }}>
                          Identity Details (Min-1)/ओळखीचा पुरावा (किमान - १)
                        </h6>
                        <hr class="border border-1 border-primary m-0 p-0" />
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <ul
                            style={{ listStyle: "unset", listStyleType: "none" }}
                          >
                            <li class="mt-4">

                              <label htmlFor="" class="fw-bold mr-2">
                                Identity Proof/ओळखपत्र क<sup>*</sup>
                              </label>

                              <div class="Identity-proof Identity-proof-2">
                                <input
                                  type="radio"
                                  class="seller-checkbox "
                                  id="Driving License1"
                                  name="idProof"
                                 
                                  ${listProfe[0].idProof == "Driving License"
        ? "checked"
        : ""
      }
                                 />
                                <label
                                  htmlFor="Driving License1"
                                  class="ml-1 seller-label"
                                >
                                  Driving License
                                </label>
                                <input
                                  type="radio"
                                  class="ml-3 seller-checkbox"
                                  id="PAN Card1"
                                  name="idProof"
                                  
                                  ${listProfe[0].idProof == "PAN Card"
        ? "checked"
        : ""
      }
                                 />
                                <label
                                  htmlFor="PAN Card1"
                                  class="ml-1 seller-label"
                                >
                                  PAN Card
                                </label>
                                <input
                                  type="radio"
                                  class="ml-3 seller-checkbox  "
                                  id="Ration Card1"
                                  name="idProof"
                                ${listProfe[0].idProof == "Ration Card"
        ? "checked"
        : ""
      }
                                  />
                                <label
                                  htmlFor="Ration Card1"
                                  class="ml-1 seller-label"
                                >
                                  Ration Card
                                </label>
                                <input
                                  type="radio"
                                  class="ml-3 seller-checkbox  "
                                  id="Voter ID1"
                                  name="idProof"
                                  ${listProfe[0].idProof == "Voter ID"
        ? "checked"
        : ""
      }
                                  />
                                <label
                                  htmlFor="Voter ID1"
                                  class="ml-1 seller-label"
                                >
                                  Voter ID
                                </label>
                                <input
                                  type="radio"
                                  class="ml-3 seller-checkbox  "
                                  id="Aadhar Card1"
                                  name="idProof"
                                 
                                  ${listProfe[0].idProof == "Aadhar Card"
        ? "checked"
        : ""
      }
                                 />
                                <label
                                  htmlFor="Aadhar Card1"
                                  class="ml-1 seller-label"
                                >
                                  Aadhar Card
                                </label>
                              </div>
                              <div class="d-flex mt-1">
                                <input
                                  type="radio"
                                  class="seller-checkbox "
                                  id="Passport1"
                                  name="idProof"
                                  ${listProfe[0].idProof == "Passport"
        ? "checked"
        : ""
      }
                                  />
                                <label
                                  htmlFor="Passport1"
                                  class="ml-1 seller-label"
                                >
                                  Passport
                                </label>
                                <input
                                  type="radio"
                                  class="ml-3 seller-checkbox  "
                                  id="MGNERGA Job Card1"
                                  name="idProof"
                                  
                                  ${listProfe[0].idProof == "MGNERGA Job Card"
        ? "checked"
        : ""
      }
                                  />
                                <label
                                  htmlFor="MGNERGA Job Card1"
                                  class="ml-1 seller-label"
                                >
                                  MGNERGA Job Card
                                </label>
                              </div>
                              <div class="d-flex mt-3">
                        
                              </div>

                              <div class="card" style="border 1px solid gray; width:300px;height:280px;display:flex;justify-content:center">
                              <img src=${identityProof}
                              alt="passbook" style="max-width:100%; max-height:100% ; margin:auto" />
                              
                              </div>

                            </li>

                            <li class="mt-3">

                              <label htmlFor="" class="fw-bold mr-2">
                                Identity Proof No./ओळखपत्र क्र<sup>*</sup>
                              </label>
                              <div class="d-flex">
                                <input
                                  type="text"
                                  class="pl-2"
                                  value=${listProfe[0].idProofNo}
                                  name="idProofNo"
                                  onChange={changeHandle}
                                />
                              </div>
                            </li>

                            <li class="mt-3">
                              <div class="d-flex">
                                <div class="col-md-6">
                                  <label htmlFor="" class="fw-bold mr-2">
                                    Aadhar Card No./आधार क्रमांक <sup>*</sup>
                                  </label>
                                  <div class="d-flex">
                                    <input
                                      type="number"
                                      class="pl-2"
                                      name="adhaarCard"
                                      value=${listProfe[0].adhaarCard}
                                      onChange={handleAadharChange}
                                    />
                                  </div>

                                    <div style={{ color: "red" }}>

                                    </div>

                                </div>

                              </div>

                            </li>
                            <li class="mt-3">
                              <label htmlFor="" class="fw-bold mr-2">
                                Bank Details of the Applicant/अर्जदाराचे बँक
                                तपशील*<sup>*</sup>
                              </label>
                              <div class=" col-md-12">
                                <label htmlFor="" class="fw-bold mr-2">
                                  Account Holder's Name /खातेधारकाचे नाव
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    class="pl-2"
                                    style="width:550px"
                                    name="accHolderName"
                                    value=${listProfe[0].accHolderName}
                                    onChange={changeHandle}
                                  />
                                </div>

                              </div>
                              <div class="col-md-12 mt-3">
                                <label htmlFor="" class="fw-bold mr-2">
                                  Account Number/खाते क्रमांक
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    class="pl-2"
                                    style="width:550px"
                                    name="accNo"
                                    value=${listProfe[0].accNo}
                                    onChange={changeHandle}
                                  />
                                </div>

                              </div>
                              <div class="col-md-12 mt-3">
                                <label htmlFor="" class="fw-bold mr-2">
                                  Bank Name/बँकेचे नाव
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    class="pl-2"
                                    style="width:550px"
                                    name="bankName"
                                    value=${listProfe[0].bankName}
                                    onChange={changeHandle}
                                  />
                                </div>

                              </div>
                              <div class="col-md-12 mt-3">
                                <label htmlFor="" class="fw-bold mr-2">
                                  IFSC Code/IFSC कोड
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    class="pl-2"
                                    style="width:550px"
                                    name="ifscCode"
                                    value=${listProfe[0].ifscCode}
                                    onChange={changeHandle}
                                  />
                                </div>

                              </div>
                              <div class="col-md-12 mt-3">
                                <label htmlFor="" class="fw-bold mr-2">
                                  Branch Name/शाखेचे नाव
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    class="pl-2"
                                    style="width:550px"
                                    name="branchName"
                                    value=${listProfe[0].branchName}
                                    onChange={changeHandle}
                                  />
                                </div>

                              </div>
                              <div class="col-md-12 mt-3">
                                <label htmlFor="" class="fw-bold mr-2">
                                  Branch Code/शाखा कोड
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    class="pl-2"
                                    style="width:550px"
                                    name="branchCode"
                                    value=${listProfe[0].branchCode}
                                    onChange={changeHandle}
                                  />
                                </div>

                              </div>
                            </li>

                            <li class="mt-5">
                              <label htmlFor="" class="fw-bold mr-2">
                                Please upload Front Page of Bank Passbook/कृपया
                                बँक पासबुकचे पहिले पान अपलोड करा<sup>*</sup>

                               
                                <p
                                  class="error-message"
                                  style={{ color: "red" }}
                                >

                                </p>
                              </label>
                              <div class="d-flex">
                                <input
                                  type="file"
                                  id="fileInput5"
                                  class="pl-2"
                                  name="frontOfBankPassBook"
                                  onChange={handleImage}
                                />

                              </div>


                              <div class="card" style="border 1px solid gray; width:300px;height:300px;display:flex;justify-content:center">
                              <img src=${frontOfBankPassBook}
                              alt="passbook" style="max-width:100%; max-height:100% ; margin:auto" />
                              
                              </div>
                              
                            </li>

                            <li class="mt-4">
                              <label htmlFor="" class="fw-bold mr-2">
                                Applicant’s Affidavit/अर्जदाराचे प्रतिज्ञापत्रक*
                                <sup>*</sup>

                                

                                <p
                                  class="error-message"
                                  style={{ color: "red" }}
                                >
                                 
                                </p>
                              </label>

                              <div class="d-flex ">
                                <input
                                  type="file"
                                  id="fileInput"
                                  class="pl-2"
                                  name="affidavith"
                                 
                                />
                                <p>
                                 

                                </p>
                              </div>








                              
                              <div class="card card-2" style="border 1px solid gray; width:300px;height:340px;display:flex;justify-content:center">
                              <img src=${affidavith}
                              alt="affidavith" style="width:100%; height:100% ; margin:auto" />
                              
                              </div>
                              

                             
                            </li>

                            <li class="mt-3">
                              <label htmlFor="" class="fw-bold mr-2">
                                What Business would you like to do?/तुम्ही कोणता
                                व्यवसाय करण्यास इच्छुक आहात?<sup>*</sup>
                              </label>

                              <div class="col-md-6 border border-2 border-secondary mt-3 ">
                                <div class="mt-2 ">
                                  <div class="form-check mr-2">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      id="flexRadioDefault70"
                                      name="businessYouLikeToDo"
                                     
                                      ${listProfe[0].businessYouLikeToDo ==
        "Food Item"
        ? "checked"
        : ""
      }
                                      />
                                    <label
                                      class="form-check-label"
                                      htmlFor="flexRadioDefault70"
                                    >
                                      Food item / खादयपदार्थ
                                    </label>
                                  </div>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault71"
                                    name="businessYouLikeToDo"
                                    ${listProfe[0].businessYouLikeToDo ==
        "Minor"
        ? "checked"
        : ""
      }
                                    />
                                  
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault71"
                                  >
                                    Minor/किरकोळ
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault72"
                                    name="businessYouLikeToDo"
                                   
                                    ${listProfe[0].businessYouLikeToDo ==
        " Independent Business"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault72"
                                  >
                                    Independent Business/स्वतंत्र व्यवसाय
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault73"
                                    name="businessYouLikeToDo"
                                   
                                    ${listProfe[0].businessYouLikeToDo ==
        "Transport business"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault73"
                                  >
                                    Transport business/वाहतूक
                                  </label>
                                </div>
                                <div class="form-check mr-2">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault74"
                                    name="businessYouLikeToDo"
                                   
                                    ${listProfe[0].businessYouLikeToDo ==
        "Others"
        ? "checked"
        : ""
      }
                                    />
                                  <label
                                    class="form-check-label"
                                    htmlFor="flexRadioDefault74"
                                  >
                                    Others
                                  </label>
                                </div>
                              </div>
                            </li>
                            <li class="mt-5" >
                              <label
                                htmlFor=""
                                class="fw-bold mr-2 fw-bold-fw-bold"
                               
                              >
                                Declaration<sup>*</sup>
                              </label>
                              <div class="col-md-12"  style="margin-top: 10px">
                                <span
                                  style={{
                                    lineHeight: "normal",
                                    lineBreak: "auto",
                                  }}
                                >
                                  I, Mr/Ms/Mrs
                                  <input
                                    type="text"
                                    id=""
                                    style={{
                                      borderTop: "none",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      paddingLeft: "5px",
                                      textAlign: "center",
                                    }}
                                    name="declareName"
                                    value=${listProfe[0].bene_firstname +
      listProfe[0].bene_lastname
      }
                                    onChange={handleInputChange}
                                  />

                                  Son/Daughter/Wife of
                                  <input
                                    type="text"
                                    id=""
                                    style={{
                                      borderTop: "none",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      paddingLeft: "5px",
                                      textAlign: "center",
                                    }}
                                    name="declareGuard"
                                    value=${listProfe[0].guardian_name}
                                    onChange={changeHandle}
                                  />
                                  Age
                                  <div class="mt-2">
                                  <input
                                    type="number"
                                    id=""
                                    style={{
                                      borderTop: "none",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      paddingLeft: "5px",
                                      width: "50px",
                                      textAlign: "center",
                                      marginTop:"5px"
                                    }}
                                    name="declareAge"
                                    value=${new Number(
        (new Date().getTime() -
          new Date(listProfe[0].dob).getTime()) /
        31536000000
      ).toFixed(0)}
                                    onChange={changeHandle}
                                  />
                                  Caste
                                  <input
                                    type="text"
                                    id=""
                                    style={{
                                      borderTop: "none",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      paddingLeft: "5px",
                                      textAlign: "center",
                                       marginTop:"5px"
                                    }}
                                    name="declareCaste"
                                    value=${listProfe[0].castCategory}
                                    onChange={changeHandle}
                                  />
                                  </div>
                                  (General/OBC/SC/ST/VJNT/NT-B/NT-C/NT-D/SBC)
                                  ,Resident of
                                  <input
                                    type="text"
                                    id=""
                                    style={{
                                      borderTop: "none",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      paddingLeft: "5px",
                                      textAlign: "center",
                                       marginTop:"5px"
                                    }}
                                    name="residentOf"
                                    value=${listProfe[0].village}
                                    onChange={changeHandle}
                                  />
                                  , Post

                                  <div class="mt-2 mb-2">
                                  <input
                                    type="text"
                                    id=""
                                    style={{
                                      borderTop: "none",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      paddingLeft: "5px",
                                      textAlign: "center",
                                       marginTop:"5px"
                                    }}
                                    name="declarePost"
                                    value=${listProfe[0].post}
                                    onChange={changeHandle}
                                  />
                                  ,Block
                                  <input
                                    type="text"
                                    id=""
                                    style={{
                                      borderTop: "none",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      paddingLeft: "5px",
                                      textAlign: "center",
                                       marginTop:"5px"
                                    }}
                                    name="block"
                                    value=${listProfe[0].block}
                                    
                                    onChange={changeHandle}
                                  />
                                  ,District
                                  <input
                                    type="text"
                                    id=""
                                    style={{
                                      borderTop: "none",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      paddingLeft: "5px",
                                      textAlign: "center",
                                       marginTop:"5px"
                                    }}
                                    name="district"
                                    value=${listProfe[0].district[0]?.title}
                                    onChange={changeHandle}
                                  />
                                  </div>
                                  ,State Maharashtra, Pin code
                                  <input
                                    type="text"
                                    id=""
                                    style={{
                                      borderTop: "none",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      paddingLeft: "5px",
                                      textAlign: "center",
                                       marginTop:"5px"
                                    }}
                                    name="pincode"
                                    value=${listProfe[0].pincode}
                                    // onChange={changeHandle}
                                  />
                                  do hereby declare that, the Information given
                                  above and in the enclosed documents is true to
                                  the best of my knowledge and belief, and nothing
                                  has been concealed therein. I am well aware of
                                  the fact that if the information given by me is
                                  provided false/not true, then I will be liable
                                  for disqualifying the application as per the
                                  law. Also, if any benefits availed by me shall
                                  be summarily withdrawn. I will use the
                                  grant/e-vehicle/e-cart for the same purpose as
                                  per the scheme guidelines dated 10 June 2019
                                </span>
                              </div>
                            </li>

                          </ul>

                        </div>
                      </div>

                      <div class="description">
                        <h4>Terms and conditions of the scheme*</h4>
                        <p class="mb-1">
                          1. Applicant should be resident of Maharashtra State.
                        </p>
                        <p class="mb-1">
                          2. Applicant should have at least 40% disability and
                          should have a fitness certificate from District Surgeon
                          / Competent Authority.
                        </p>
                        <p class="mb-1">
                          3. Age of the applicant should be between 18 to 55 year
                        </p>
                        <p class="mb-1">
                          4. In case of intellectual disabilities applicants their
                          legal guardians/parents will be eligible to apply for
                          the Scheme.
                        </p>
                        <p class="mb-1">
                          5. Annual income of the applicant should not exceed INR
                          2.50 lakhs.
                        </p>
                        <p class="mb-1">
                          6. First priority will be given to those having severe
                          disabilities while selecting the beneficiaries. So, the
                          order of selection will be from severe disability to
                          mild disability.
                        </p>
                        <p class="mb-1">
                          7. If person having severe disability is denied for
                          driving license, even in such a situation preference
                          will be given to a severely disabled person who does not
                          have a license to do mobile business with the help of an
                          escort.
                        </p>
                        <p class="mb-2">
                          8. At the time of submission of the application, the
                          applicant required to submit a bond/affidavit agreeing
                          to all rules and conditions and taking proper care of
                          his/her-vehicle/e-cart.
                        </p>
                        <p class="mb-1 mt-5">
                          9. District wise list of the beneficiaries will be
                          announced in proportion to number of persons with
                          disabilities in the district.
                        </p>
                        <p class="mb-1">
                          10. The applicant should not be employed by the
                          Government, Semi Government, or any Government
                          body/Corporation.
                        </p>
                        <p class="mb-1">
                          11. If the applicant has previously availed a loan from
                          our corporation, they should not be in default of the
                          said loan.
                        </p>
                        <h4 class="mt-4">
                          योजनेच्या अटी व शर्ती*
                        </h4>
                        <p class="mb-1">
                          1. अर्जदार हा महाराष्ट्र राज्याचा रहिवाशी असावा.
                        </p>
                        <p class="mb-1">
                          2. अर्जदाराकडे दिव्यांगत्वाचे प्रमाण किमान ४०% टक्के
                          असावे तसेच जिल्हा शल्य चिकित्सक / सक्षम प्राधिकारी यांनी
                          प्रमाणित केलेले प्रमाणपत्रधारक असावा
                        </p>
                        <p class="mt-3 ">
                          3. अर्जदार हा १८ ते ५५ या वयोगटातील असावा
                        </p>
                        <p class="mb-1">
                          4. बौध्दिक अक्षम अर्जदाराच्या बाबतीत त्यांचे कायदेशीर
                          पालक अर्ज करण्यास सक्षम असतील
                        </p>
                        <p class="mb-1">
                          5. दिव्यांग अर्जदाराचे वार्षिक उत्पन्नरु. २.५० लाख
                          पेक्षा अधिक नसावे.
                        </p>
                        <p class="mb-1">
                          6. लाभार्थी निवड करताना जास्त अपंगत्व असलेल्यास प्रथम
                          प्राधान्य दिले जाईल. त्यामुळे निवडीचा क्रम हा अतितीव्र
                          दिव्यांगत्व ते कमी दिव्यांगत्व या क्रमाने राहील.
                        </p>
                        <p class="mb-1">
                          7. अतितीव्र दिव्यांगत्व असणाऱ्या दिव्यांग व्यक्तीस वाहन
                          चालविण्याचा परवाना नाकारला असल्यास अशा परिस्थितीत देखील
                          परवाना धारक नसलेल्या अतितीव्र दिव्यांग व्यक्तीच्या
                          बाबतीत सोबत्याच्या (Escort) सहाय्याने फिरता मोबाईल
                          व्यवसाय करण्यास प्राधान्य दिले जाईल.
                        </p>
                        <p class="mb-1">
                          8. अर्जाच्या वेळी अर्जदाराने सर्व अटी मान्य असल्याचे
                          तसेच संबंधीत वाहनाची योग्यती काळजी घेण्याचे बंधपत्र सादर
                          करणे आवश्यक राहील
                        </p>
                        <p class="mb-1">
                          9. जिल्हानिहाय लाभार्थ्यांची घोषणा दिव्यांगांच्या
                          संख्येच्या प्रमाणात केली जाईल.
                        </p>
                        <p class="mb-1">
                          10. अर्जदार हा शासकीय/निमशासकीय/मंडळे/महामंडळे यांचा
                          कर्मचारी नसावा.
                        </p>
                        <p class="mb-1">
                          11. या योजनेसाठी अर्ज करणारा अर्जदार जर दिव्यांग वित्त व
                          विकास महामंडळाचा कर्जदार असेल तर तो थकबाकीदार नसावा.
                        </p>
                        <p class="fw-bold">
                          <div class="form-check">
                            <input
                              class="form-check-input form-check-input-2"
                              type="checkbox"
                              checked
                            />
                            <label
                              class="form-check-label mt-2 ml-2"
                              htmlFor="conditions"
                            >
                              Are the terms and conditions acceptable?
                              <sup>*</sup>
                            </label>
                          </div>
                        </p>

                        <p>

                        </p>

                      </div>

                        <div
                          class=".table-to-pdf ffef text-center mt-1"
                          ref={pdfRef2}
                        >
                          <h2>प्रतिज्ञापत्र</h2>

                          <div class="col-md-12" style="line-height: 1.6;">
                            <span
                              style=' lineHeight: "1.6", lineBreak: "auto"'
                            >
                              मी श्री/श्रीमती
                              <input
                                type="text"
                                id=""
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  paddingLeft: "5px",
                                }}
                                name="declareName"
                                value=${listProfe[0].bene_firstname +
      " " +
      listProfe[0].bene_lastname
      }
                                onChange={handleInputChange}
                              />

                              वय
                              <input
                                type="text"
                                id=""
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  paddingLeft: "5px",
                                }}
                                name="declareGuard"
                                value=${listProfe[0].relatedToGuardian}
                                onChange={changeHandle}
                              />
                              वर्षे
                              <input
                                type="number"
                                id=""
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  paddingLeft: "5px",
                                  width: "50px",
                                  marginTop:"5px"
                                }}
                                name="declareAge"
                                value=${new Number(
        (new Date().getTime() -
          new Date(listProfe[0].dob).getTime()) /
        31536000000
      ).toFixed(0)}
                               
                              />
                              <div class="mt-2 mb-2">
                              जात.
                              <input
                                type="text"
                                id=""
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  paddingLeft: "5px",
                                  marginTop:"5px"
                                }}
                                name="declareCaste"
                                value=${listProfe[0].castCategory}
                                onChange={changeHandle}
                              />
                              यांचा/यांचीमुलगा/मुलगी/पत्नी असून, मी गांव/शहर
                              <input
                                type="text"
                                id=""
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  paddingLeft: "5px",
                                  marginTop:"5px"
                                }}
                                name="residentOf"
                                value=${listProfe[0].village}
                                
                              />
                               </div>
                               <div class="mt-2 mb-2">
                              , पोष्ट
                              <input
                                type="text"
                                id=""
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  paddingLeft: "5px",
                                  marginTop:"5px"
                                }}
                                name="declarePost"
                                value=${listProfe[0].post}
                               
                              />
                              ,तालुका
                              <input
                                type="text"
                                id=""
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  paddingLeft: "5px",
                                  marginTop:"5px"
                                }}
                                name="block"
                                value=${listProfe[0].block}
                               
                               
                              />
                              ,जिल्हा
                              <input
                                type="text"
                                id=""
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  paddingLeft: "5px",
                                  marginTop:"5px"
                                }}
                                name="district"
                                value=${listProfe[0].district[0]?.title}
                                
                                
                              />
                              </div>
                             

                              ,राज्य महाराष्ट्र, पिनकोड
                              <input
                                type="text"
                                id=""
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  paddingLeft: "5px",
                                  marginTop:"5px"
                                }}
                                name="pincode"
                                value=${listProfe[0].pincode}
                                // onChange={changeHandle}
                              />
                              येथील रहिवासी असून याद्वारे प्रतिज्ञापत्र करतो/करते
                              की, वर दिलेली आणि संलग्न दस्तऐवजांमध्ये सादर केलेली
                              माहिती माझ्या सर्वोत्तम माहितीनुसार आणि
                              विश्वासानुसार सत्य आहे आणि त्यात कोणतीही गोष्ट लपवून
                              ठेवण्यात आलेली नाही. मला या वस्तुस्थितीची चांगली
                              जाणीव आहे की जर मी दिलेली माहिती खरी नसली, तर
                              कायद्यानुसार अर्ज अपात्र ठरवण्यास मी जबाबदार असेन.
                              तसेच, मला मिळालेले सर्व फायदे सरसकट काढून घेतले
                              जातील. मी महाराष्ट्र राज्यातील इतर कोणत्याही योजने
                              अंतर्गत मोफत ई-वाहनाचा लाभ घेतलेला नाही. मी 10 जून
                              2019 च्या शासन निर्णयातील योजनेच्या मार्गदर्शक
                              तत्त्वांनुसार अनुदान / ई-वाहन / ई-कार्टचा वापर त्याच
                              उद्देशासाठी करेन तसेच संबंधीत वाहनाची योग्य ती काळजी
                              घेईन. मी याद्वारे प्रतिज्ञापत्रावर असेही कथन करतो
                              की, मी शासकीय/निमशासकीय/मंडळे/महामंडळे
                              <p class="mt-2">यांचा
                              कर्मचारी नाही. तसेच महाराष्ट्र राज्य दिव्यांग वित्त
                              व विकास महामंडळ, मुंबई यांचा थकीत कर्जदार लाभार्थी
                              नाही.</p>
                            </span>
                            <div class="sign-section">
                              <div class="date-sec">दिनांक:</div>
                              <div class="box-doc-2">
                                <h5>सही</h5>
                                <div class="box-doc"></div>
                              </div>
                            </div>
                          </div>
                        </div>

                      <div class="text-center mb-3">
                        <button
                          type="button"
                          class="text-center seller-btn seller-btn-2"
                          onClick={saveDraft}
                        >
                          Save As Draft
                        </button>
                        <button
                          type="submit"
                          disabled={!listProfe[0].termsAndCondition}
                          class="text-center seller-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </form>

                  </div>

                </div>
              </div>
            </div>
            <ToastContainer />

          </div>
          </body></html>`;
    html2pdf(html, { return: true })
      // .outputPdf()
      .get("pdf")
      .then(async (outputData) => { });
  }, 15000);
  // } else {
  //   cyndd = true;
  //   downloadPdf();
  // }
};


// return (
//     <>
//         PdfGeneratePdf
//     </>
// )
// }
// export default PdfGeneratePdf