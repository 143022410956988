import { Button, Modal } from "react-bootstrap"

function ShowImage(props) {
    // console.log(props);
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Certificate
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {props?.imagePopup && <div>
                        <iframe src={props?.imagePopup} frameborder="0" style={{ width: '100%', height: '400px' }}></iframe>
                        {/* <img src={props?.imagePopup} alt="" /> */}
                    </div>}


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ShowImage