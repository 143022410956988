
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function createMarkup(data) {
    return { __html: data };
}
function DynemicPoUp(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {/* {props?.popups?.page} */}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='img-sec-sec'>
                    <img src={props?.popups?.image?.url} />
                </div>
                <div className="tabs_item">
                    <div className="products-details-tab-content" dangerouslySetInnerHTML={createMarkup(props?.popups?.description)} />
                </div>
                {/* <p>
                    {props?.popups?.description}
                </p> */}
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}
export default DynemicPoUp