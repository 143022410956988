import React from 'react'
import DownloadBeneficiaryDivisionWiseComp from '../../Components/downloadBeneficiaryDivisionWiseComp/DownloadBeneficiaryDivisionWiseComp'

function DownloadBeneficiaryDivisionWisePage({ fontSize }) {
    return (
        <>
            <DownloadBeneficiaryDivisionWiseComp fontSize={fontSize}/>
        </>
    )
}

export default DownloadBeneficiaryDivisionWisePage