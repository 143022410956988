import Tracking from "../../Components/track/Tracking"

function TrackingPage({ fontSize }) {
    return (
        <>
            <Tracking fontSize={fontSize} />

        </>
    )
}
export default TrackingPage