

export default function Validation(addSeller, postData, mobileset, comboId, education, vlueDistric, docAddressProfe, tOfDisability, aOfDisabality, identityProof, businessLike, empDetails, dateOfBirth, inputValue, birthday, divisonn) {
    // console.log('divisonn', vlueDistric);
    const errors = {}
    // console.log('errors', errors);
    // debugger
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/

    if (addSeller.bene_firstname === '') {
        errors.bene_firstname = 'First Name is Required!'
    }
    if (addSeller.bene_lastname === '') {
        errors.bene_lastname = 'Last Name is Required!'
    }
    // if (addSeller.bene_middlename === '') {
    //     errors.bene_middlename = 'Middle Name is Required!'
    // }
    if (addSeller.mother_firstname === '') {
        errors.mother_firstname = 'First Name is Required!!'
    }
    if (addSeller.mother_lastname === '') {
        errors.mother_lastname = 'Last Name is Required!'
    }
    // if (addSeller.mother_middlename === '') {
    //     errors.mother_middlename = 'Middle Name is Required!'
    // }
    if (dateOfBirth === undefined || dateOfBirth === null) {
        errors.dob = 'Date Of Birth is Required!'
    }
    if (mobileset === '') {
        errors.mobileset = 'Mobile Number is Required!'
    }
    if (addSeller.email === '') {
        errors.email = 'email is required'
    }
    if (addSeller.gender === '') {
        errors.gender = 'Gender Select is Required!'
    }
    if (addSeller.bloodGroup === '') {
        errors.bloodGroup = 'Blood Group Select is Required!'
    }
    if (addSeller.castCategory === '') {
        errors.castCategory = 'Cast Select is Required!'
    }
    if (addSeller.udidType === '' || addSeller.udidType === undefined || addSeller.udidType === null) {
        errors.udidType = 'UDID Type is Required!'
    }
    if (comboId === '' || comboId === undefined || comboId === null) {
        errors.comboId = 'Business Select is Required!'
    }
    // if (addSeller.disType === '') {
    //     errors.disType = 'Disability Category Select is Required!'
    // }
    // if (addSeller.maritalStatus === '') {
    //     errors.maritalStatus = 'Marital Status Select is Required!'
    // }

    if (addSeller.maritalStatus != 'Unmarried' && addSeller.spouse_name === '') {
        errors.spouse_name = 'The name of the Spouse is Required!'
    }
    if (addSeller.guardian_name === '') {
        errors.guardian_name = 'Guardian Name is Required!'
    }
    if (addSeller.relatedToGuardian === '') {
        errors.relatedToGuardian = 'Guardian Name Select is Required!'
    }
    // if (education.diploma || education.graduate || education.higher_secondary || education.post_graduate || education.primary || education.secondary || education.unGraduate === false) {

    //     errors.education = 'Education Select is Required!'
    // }
    if (addSeller.residentOf === '') {
        errors.residentOf = 'Maharashtra State Select is Required!'
    }
    if (addSeller.coresAddress === '') {
        errors.coresAddress = 'Correspondence Address is Required!'
    }
    if (addSeller.village === '') {
        errors.village = 'Village is Required!'
    }
    if (addSeller.block === '') {
        errors.block = 'block is Required!'
    }
    // if (vlueDistric === '') {
    //     errors.vlueDistric = 'Correspondence Address is Required!'
    // }
    if (addSeller.state === '') {
        errors.state = 'Sate is Required!'
    }
    if (inputValue === '') {
        errors.pincode = 'Pincode is Required!'
    }
    if (addSeller.permanentAddress === '') {
        errors.permanentAddress = 'Permanent Address is Required!'
    }
    if (addSeller.disabilityCert === '') {
        errors.disabilityCert = 'Disability Certificate select is Required!'
    }
    if (addSeller.udId_No === '') {
        errors.udId_No = 'UDID Certificate No is Required!'
    }
    if (addSeller.PerOfDis === '') {
        errors.PerOfDis = 'Percentage of disability is Required!'
    }
    if (addSeller.status === '') {
        errors.status = 'Employment Status is Required!'
    }
    if (addSeller.adhaarCard === '') {
        errors.adhaarCard = 'Aadhar Card No is Required!'
    }
    if (addSeller.accHolderName === '') {
        errors.accHolderName = 'Account Holder Name is Required!'
    }
    if (addSeller.guardian_contact === '') {
        errors.guardian_contact = 'His/her Contact No is Required!'
    }
    if (addSeller.accNo === '') {
        errors.accNo = 'Account Number is Required!'
    }
    if (addSeller.bankName === '') {
        errors.bankName = 'Bank Name is Required!'
    }
    if (addSeller.ifscCode === '') {
        errors.ifscCode = 'IFSC Code is Required!'
    }
    if (addSeller.branchName === '') {
        errors.branchName = 'Branch Name is Required!'
    }
    if (addSeller.branchCode === '') {
        errors.branchCode = 'Branch Code is Required!'
    }
    if (addSeller.post === '') {
        errors.post = 'Post is Required!'
    }

    if (vlueDistric === '' || vlueDistric === undefined || vlueDistric === null) {
        errors.divisonn = 'Division is Required!'
    }

    if (addSeller.district === '' || addSeller.district === undefined || addSeller.district === null) {
        errors.district = 'District is Required!'
    }




    // if (addSeller.declareName === '') {
    //     errors.declareName = 'Declaration Name is Required!'
    // }
    // if (docAddressProfe.driveLicen || docAddressProfe.electricBill || docAddressProfe.passport || docAddressProfe.properTaxRec || docAddressProfe.rationCard
    //     || docAddressProfe.voterId === false) {

    //     errors.docAddressProfe = 'Documents of Address Proof is Required!'
    // }
    // if (tOfDisability.acidAttactVic || tOfDisability.autism || tOfDisability.blindness || tOfDisability.cerebralPal || tOfDisability.chronicNeu
    //     || tOfDisability.dwarfism || tOfDisability.hearingImp || tOfDisability.hemoPhilia || tOfDisability.intellect || tOfDisability.leprosy || tOfDisability.locoMotor || tOfDisability.lowVision || tOfDisability.mentalBehave || tOfDisability.multiScler || tOfDisability.multipleDisab || tOfDisability.muscular_dy || tOfDisability.parkinson || tOfDisability.sickieCell || tOfDisability.specific_learn || tOfDisability.speechNlang || tOfDisability.thalassemia === false) {

    //     errors.tOfDisability = 'Types Of Disability is Required!'
    // }
    // if (aOfDisabality.chest || aOfDisabality.ears || aOfDisabality.leftEye || aOfDisabality.leftHand || aOfDisabality.leftLeg
    //     || aOfDisabality.mouth || aOfDisabality.nois || aOfDisabality.rightEye || aOfDisabality.rightHand || aOfDisabality.rightLeg || aOfDisabality.shoulder || aOfDisabality.stomach || aOfDisabality.throat === false) {

    //     errors.aOfDisabality = 'Area of Disability is Required!'
    // }
    if (identityProof === '') {
        errors.identityProof = 'Identity Proof Select is Required!'
    }

    if (addSeller.areaOfDisable === '') {
        errors.areaOfDisable = 'Area of Disability is Required!'
    }
    // if (businessLike.foodItem || businessLike.independentBus || businessLike.minor || businessLike.other || businessLike.transport === false) {

    //     errors.businessLike = 'Business would you like to do is Required!'
    // }
    // if (empDetails.status === '') {
    //     errors.empDetails = 'Employment Status is Required!'
    // }
    // if (empDetails.bpl === '') {
    //     errors.empDetails.bpl = 'BPL/APL is Required!'
    // }
    // if (empDetails.annualIncom === '') {
    //     errors.empDetails = 'Annual Income of the beneficiary is Required!'
    // }
    // if (dateOfBirth === '') {
    //     errors.dob = 'Date of Birth is Required!'
    // }
    else if (!email_pattern.test(addSeller.email)) {
        errors.email = 'Email is not correct'
    }




    const objlenght = Object.keys(errors).length


    if (objlenght == 0) {
        postData()
    }
    if (!objlenght == 0) {
        alert('All Field are Required!')
    }

    return errors
}
