import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import jsPDF from 'jspdf'
// import autoTable from 'jspdf-autotable'

function DownloadBeneficiaryDivisionWiseComp({ fontSize, colorMode }) {
    const [spinn, setSpinn] = useState(false);
    const [shownDownloadPdfBtn, setShownDownloadPdfBtn] = useState(false);
    const [alldivisionD, setAllDivisionD] = useState();
    const [districtD, setDistrictD] = useState();
    const [finalDivisionD, setFinalDivisionD] = useState();
    const [finalDistrictD, setFinalDistrictD] = useState();
    // const doc = new jsPDF();
    const [pdfimgD, setPdfImgD] = useState();


    const getAllDivisionD = async () => {
        setSpinn(true)
        try {
            const res = await axios.get(`https://evehicleadmin.mshfdc.co.in/api/division/public`, {
                withCredentials: true
            });
            setAllDivisionD(res?.data)
            setSpinn(false)
        } catch (error) {
            setSpinn(false)
            alert(error?.message)
        }
    };
    useEffect(() => {
        getAllDivisionD();
    }, []);

    const PostDivisionD = async (e) => {
        setSpinn(true)
        const selectedDiv = e.target.value;
        setFinalDivisionD(selectedDiv)
        try {
            const res = await axios.get(`https://evehicleadmin.mshfdc.co.in/api/district/division/public/${selectedDiv}`, { Division: [selectedDiv] }, {
                withCredentials: true
            });
            setDistrictD(res?.data)
            setSpinn(false)
        } catch (error) {
            setSpinn(false)
            alert(error?.message)
        }
    };
    const handleChnageDistrict = (e) => {
        const selectedDist = e.target.value;
        setFinalDistrictD(selectedDist);
    };

    const postTrack = async () => {
        setSpinn(true)
        try {
            const res = await axios.get(`https://evehicleadmin.mshfdc.co.in/api/sellerDoc/public?district=${finalDistrictD}&division=${finalDivisionD}`, {
                withCredentials: true
            });
            setPdfImgD(res?.data?.doc)
            if (res?.data?.doc) {
                setShownDownloadPdfBtn(true)
            }
            setSpinn(false)
        } catch (error) {
            setSpinn(false)
            alert(error?.response?.data?.message)
        }
    };

    // const downloadPdf = () => {
    //     doc.autoTable({ html: '#beneficiaryList' });
    //     doc.save('Beneficiary.Pdf')
    // };

    return (
        <>
            <section className="track-application" style={{ padding: '103px 0' }}>
                <div className="container">

                    {spinn && <div className="preloaderCount">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>}

                    <div className="row">
                        <div className="col-lg-12">
                            <h2
                                className="text-center mb-3"
                                style={{ fontSize: `${fontSize}px` }}
                            >
                                Check Your Beneficiary Status
                            </h2>
                            <div className="tract-sec ">

                                <div className="tract-sec-form d-flex">
                                    <div className="tract-sec-2  mb-3 me-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                            style={{ fontSize: `${fontSize}px`, marginLeft: '8px' }}
                                        >
                                            Select Division
                                        </label>
                                        <select className="form-select" aria-label="Default select example" onChange={PostDivisionD}>
                                            <option selected>Select Division</option>
                                            {alldivisionD && alldivisionD.map((item, i) => {
                                                return <option value={item?.uid} key={i}>{item?.title}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="tract-sec-2 mb-3 me-3">
                                        <label
                                            htmlFor="exampleFormControlInput1"
                                            className="form-label"
                                            style={{ fontSize: `${fontSize}px`, marginLeft: '8px' }}
                                        >
                                            Select District
                                        </label>
                                        <select className="form-select" aria-label="Default select example" onChange={handleChnageDistrict}>
                                            <option selected>Select District</option>
                                            {districtD && districtD.map((item, i) => {
                                                return <option value={item?.uid} key={i}>{item?.title}</option>
                                            })}
                                        </select>

                                    </div>
                                    {finalDistrictD && <div className="tract-sec-2 mb-3">
                                        <button
                                            type="button"
                                            onClick={postTrack}
                                            style={{ fontSize: `${fontSize}px` }}
                                        >
                                            View
                                        </button>
                                    </div>}

                                    {/* {shownDownloadPdfBtn && <div className="tract-sec-2 mb-3 ml-2">
                                        <button
                                            type="button"
                                            style={{ fontSize: `${fontSize}px` }}
                                        >
                                            <a style={{ textDecoration: 'none', color: 'white' }} href={`https://evehicleadmin.mshfdc.co.in/api/cloudinaryimage/${pdfimgD}`} download>Download PDF</a>
                                        </button>
                                    </div>} */}

                                </div>
                            </div>

                            <div className="tract-sec-3 mb-3 text-center">
                                <Link to="/">Back to Home</Link>
                            </div>
                            {/* {shownDownloadPdfBtn && <div id='#beneficiaryList' style={{ width: '1200px', height: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <img src={`https://evehicleadmin.mshfdc.co.in/api/cloudinaryimage/pdf2image/${pdfimgD}`} style={{ width: '100%', objectFit: 'cover' }} />
                            </div>} */}
                            {/* 
                            {shownDownloadPdfBtn && <iframe src={`https://evehicleadmin.mshfdc.co.in/api/cloudinaryimage/${pdfimgD}`} width="100%" height="600px" frameborder="0"></iframe>} */}

                            {/* {shownDownloadPdfBtn && <embed src={`https://evehicleadmin.mshfdc.co.in/api/cloudinaryimage/${pdfimgD}`} type="application/pdf" width="100%" height="600px" />} */}

                            {shownDownloadPdfBtn && <iframe width="100%" height="600px" src={`https://evehicleadmin.mshfdc.co.in/api/cloudinaryimage/${pdfimgD}#toolbar=0`} >
                            </iframe>}


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DownloadBeneficiaryDivisionWiseComp