
// import { initializeApp } from 'firebase/app';
// import { getAuth, initializeAuth, } from 'firebase/auth'

// import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyBxNqVP5r_jNk_5vaa7h4VU_pI7MZeaiLA",
    authDomain: "mshfdc-bd57b.firebaseapp.com",
    projectId: "mshfdc-bd57b",
    storageBucket: "mshfdc-bd57b.appspot.com",
    messagingSenderId: "108802477109",
    appId: "1:108802477109:web:3f3aa1dec2f060683d357c"
    // apiKey: "AIzaSyDIUYgDUmlTCj_hexWl8p1TKI_Zlr1d4UA",
    // authDomain: "otp-meatz.firebaseapp.com",
    // projectId: "otp-meatz",
    // storageBucket: "otp-meatz.appspot.com",
    // messagingSenderId: "612125380515",
    // appId: "1:612125380515:web:75034e32f06db892d85416"
};

// firebase.initializeApp(firebaseConfig);
// let auth = firebase.auth();
let auth = 1234;
export { auth };



// Initialize Firebase
// const app = initializeApp(firebaseConfig);



// export const auth = getAuth(app)

// function FireBase() {
//     return (
//         <>
//             FireBase
//         </>
//     )
// }
// export default FireBase