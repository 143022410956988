import axios from "axios"
import { useEffect, useState } from "react"
import DynemicPoUp from "./DynemicPoUp";



function Footer({ fontSize, colorMode }) {
    const [popups, setPopups] = useState(null)
    const [modalShow, setModalShow] = useState(false);

    const getData2 = async () => {
        try {
            const res = await axios.get(`https://evehicleadmin.mshfdc.co.in/api/popup/public`, {
                withCredentials: true,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                },
            })
            if (!res.data) {
                return
            }
            setPopups(res.data)

            if (res.data?.page) {
                setModalShow(true)
            }
        } catch (error) {

        }

    }

    useEffect(() => {
        // getData()
        getData2()
    }, [])
    return (
        <>
            {modalShow && <DynemicPoUp show={modalShow}
                onHide={() => setModalShow(false)} popups={popups} />}
            <section className={`footer ${colorMode === 'black' ? 'purpal' : colorMode === 'white' ? 'geen' : 'orange'}`} style={{ fontSize: `${fontSize}px` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-containet">
                                <p>Copyright@2023 Maharashtra State Disability Finance and Development Corporation Marya., Mumbai</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Footer