import { useRef, useState } from "react";
import photo from "../../../../assets/WhatsApp Image 2023-11-06 at 12.55.53 PM.jpeg";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import log from "../../../../assets/logo/gov.png";
import log2 from "../../../../assets/logo/logo-removebg-preview.png";
import pdf from "../../../../assets/pdf/Applicant Affidavit.pdf";

import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import SpecialSellerPopup from "../../../common/specialSellerSucessPopup/specialSellerSucessPopup/SpecialSellerSucessPopup";
import LandingNavbar from "../../../LandingNavbar";
import { base_url } from "../../../../server";
import Validation from "./Validation";
import ShowImage from "./showImage/ShowImage";
import { ToastContainer, toast } from "react-toastify";
import PdfGeneratePdf from "../../../../common/pdfGenate/PdfGenerate";
import { downloadPdff } from "../../../../common/pdfGenate/PdfGenerate";
function EnglishSellerSpecial({ colorMode, fontSize }) {
  const [modalShow1, setModalShow1] = useState(false);

  // console.log('form', fontSize);

  const token = window.localStorage.getItem("token");
  const isLogin = window.localStorage.getItem("isLogin");
  const [spinn, setspinn] = useState();
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const [mobileset, setMobileset] = useState();
  const [phoneVerified, setPhoneVerified] = useState();
  const [langId, setLangId] = useState();
  const [successData, setSuccessData] = useState();
  const [divisonn, setDivisionn] = useState(null);
  const [districMaha, setDistricMaha] = useState(null);



  const [file, setFile] = useState();

  const [comboId, setComboId] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();

  const [addSeller, setAddSeller] = useState({
    bene_firstname: "",
    bene_lastname: "",
    bene_middlename: "",

    mother_firstname: "",
    mother_lastname: "",
    mother_middlename: "",

    dob: "",
    mobile: "",
    gender: "",

    bloodGroup: "",
    castCategory: "",

    maritalStatus: "",

    spouse_name: "",

    guardian_name: "",
    relatedToGuardian: "",

    businessCategory: "",
    language_id: "",
    email: "",
    disType: "",
    termsAndCondition: false,
    verifiedMobile: "",
    division: "",
    otherBusiness: "",

    post: "",
    guardian_contact: "",
    employeeGovInDepart: "",

    educationDetails: {
      primary: false,
      secondary: false,
      higher_secondary: false,
      diploma: false,
      graduate: false,
      post_graduate: false,
      unGraduate: false,
    },

    udId_File: {
      public_id: null,
      s3url: null,
      s3key: null,
      url: null,
    },

    userPhoto: {
      public_id: null,
      s3url: null,
      s3key: null,
      url: null,
    },

    signPhoto: {
      public_id: null,
      s3url: null,
      s3key: null,
      url: null,
    },

    domacileCert: {
      public_id: null,
      s3url: null,
      s3key: null,
      url: null,
    },

    disabilityCertImage: {
      public_id: null,
      s3url: null,
      s3key: null,
      url: null,
    },
    castCertificate: {
      public_id: null,
      s3url: null,
      s3key: null,
      url: null,
    },

    pledgeImage: {
      public_id: null,
      s3url: null,
      s3key: null,
      url: null,
    },

    coresAddress: "",

    village: "",
    block: "",
    district: "",
    state: "Maharashtra",
    pincode: "",

    permanentAddress: "",

    docOfAddProof: {
      driveLicen: false,
      rationCard: false,
      voterId: false,
      passport: false,
      electricBill: false,
      properTaxRec: false,
    },

    addressProof: [
      {
        public_id: null,
        s3url: null,
        s3key: null,
        url: null,
      },
    ],

    disabilityCert: false,
    udId_No: "",
    PerOfDis: "40",

    typesOfDisability: {
      locoMotor: false,
      leprosy: false,
      cerebralPal: false,
      dwarfism: false,
      muscular_dy: false,
      acidAttactVic: false,
      blindness: false,
      lowVision: false,
      hearingImp: false,
      speechNlang: false,
      intellect: false,
      specific_learn: false,
      autism: false,
      mentalBehave: false,
      multiScler: false,
      parkinson: false,
      hemoPhilia: false,
      thalassemia: false,
      sickieCell: false,
      chronicNeu: false,
      multipleDisab: false,
    },

    disabltyByBirth: {
      type: false,
    },

    disabtlySince: "",

    areaOfDisable: "",

    employmentDetail: {
      status: "",
      bpl: "",
      annualIncom: "",
      employeeByGov: "",
    },

    idProof: "",

    idProofFile: {
      public_id: null,
      s3url: null,
      s3key: null,
      url: null,
    },

    idProofNo: "",
    adhaarCard: "",

    accHolderName: "",
    accNo: "",
    bankName: "",

    ifscCode: "",
    branchName: "",
    branchCode: "",

    frontOfBankPassBook: {
      public_id: null,
      s3url: null,
      s3key: null,
      url: null,
    },

    affidavith: {
      public_id: null,
      s3url: null,
      s3key: null,
      url: null,
    },

    businessYouLikeToDo: "",

    //declare
    declareName: "",
    declareGuard: "",
    declareAge: "",
    declareCaste: "",
    residentOf: "",
    declarePost: "",
    declareBlock: "",
    declareDistrict: "",
    declareState: "Maharashtra",
    declarePinCode: "",

    // language_id: { type: mongoose.Schema.Types.ObjectId, ref: "languages" },
  });

  const [education, setEducation] = useState({
    primary: false,
    secondary: false,
    higher_secondary: false,
    diploma: false,
    graduate: false,
    post_graduate: false,
    unGraduate: false,
  });

  const [docAddressProfe, setDocAddressProfe] = useState({
    driveLicen: false,
    rationCard: false,
    voterId: false,
    passport: false,
    electricBill: false,
    properTaxRec: false,
  });

  const [tOfDisability, setTOfDisability] = useState({
    locoMotor: false,
    leprosy: false,
    cerebralPal: false,
    dwarfism: false,
    muscular_dy: false,
    acidAttactVic: false,
    blindness: false,
    lowVision: false,
    hearingImp: false,
    speechNlang: false,
    intellect: false,
    specific_learn: false,
    autism: false,
    mentalBehave: false,
    multiScler: false,
    parkinson: false,
    hemoPhilia: false,
    thalassemia: false,
    sickieCell: false,
    chronicNeu: false,
    multipleDisab: false,
  });

  const [aOfDisabality, setAOfDisabality] = useState({
    chest: false,
    ears: false,
    nois: false,
    shoulder: false,
    throat: false,
    leftEye: false,
    leftHand: false,
    leftLeg: false,
    rightEye: false,
    rightHand: false,
    rightLeg: false,
    stomach: false,
    mouth: false,
  });

  const [empDetails, setEmpDetails] = useState({
    status: "",
    bpl: "",
    annualIncom: "",
    employeeByGov: "",
  });

  const [identityProof, setIdentityProof] = useState();

  const [bussi, setBussi] = useState();

  const [businessLike, setBussinessLike] = useState(null
  );

  const [disabilitiesBirth, setDisabilitiesBirth] = useState(false);

  const [distSet, setSistSet] = useState();
  const [errnumber, setnumerr] = useState(false);
  const downloadAffidavit = () => {
    let name = `${addSeller.bene_firstname}  ${addSeller.bene_middlename}  ${addSeller.bene_lastname}`;
    console.log(name);
    const html = `    <h3  style="text-align: center">प्रतिज्ञापत्र</h3>
    <p style="text-align: center">
      मी श्री/श्रीमती <span style="border: 1px solid grey; width: 1050px; padding: auto 6px">${name}</span>  वय <input style="text-align:center" type="text" value=${addSeller.age}>  वर्षे श्री. <input style="text-align:center" type="text" value=${addSeller.guardian_name}>
      _यांचा/यांचीमुलगा/मुलगी/पत्नी असून, मी गांव/शहर <input style="text-align:center" type="text" value=${addSeller.village}> पोष्ट<input type="text" value=${addSeller.block}>
      _ <input style="text-align:center" type="text" value=${addSeller.post}> जिल्हा <input style="text-align:center" type="text" value=${addSeller.district}>  राज्य महाराष्ट्र, पिनकोड <input style="text-align:center" type="text" value=${addSeller.pincode}> येथील
      रहिवासी असून याद्वारे प्रतिज्ञापत्र करतो/करते की, वर दिलेली आणि संलग्न
      दस्तऐवजांमध्ये सादर केलेली माहिती माझ्या सर्वोत्तम माहितीनुसार आणि
      विश्वासानुसार सत्य आहे आणि त्यात कोणतीही गोष्ट लपवून ठेवण्यात आलेली नाही.
      मला या वस्तुस्थितीची चांगली जाणीव आहे की जर मी दिलेली माहिती खरी नसली, तर
      कायद्यानुसार अर्ज अपात्र ठरवण्यास मी जबाबदार असेन. तसेच, मला मिळालेले सर्व
      फायदे सरसकट काढून घेतले जातील.
    </p>
    <p style="text-align: center">
      मी महाराष्ट्र राज्यातील इतर कोणत्याही योजने अंतर्गत मोफत ई-वाहनाचा लाम
      घेतलेला नाही. मी १० जून २०१९ च्या शासन निर्णयातील योजनेच्या मार्गदर्शक
      तत्त्वांनुसार अनुदान / ई-वाहन / ई-कार्टचा वापर त्याच उद्देशासाठी करेन तसेच
      संबंधीत वाहनाची योग्य ती काळजी घेईन.
    </p>
    <p style="text-align: center">
      मी याद्वारे प्रतिज्ञापत्रावर असेही कथन करतो की, मी
      शासकीय/निमशासकीय/मंडळे/महामंडळे यांचा कर्मचारी नाही. तसेच महाराष्ट्र राज्य
      दिव्यांग वित्त व विकास महामंडळ, मुंबई यांचा थकीत कर्जदार लाभार्थी नाही.
    </p>
    <div>
     
 <input  type="text" placeholder="सही/-" />
    </div>
    <div class="row" style="display: flex; justify-content: space-around">
      <div class="col-5">दिनांक:</div>
      <div class="col-5" style="padding-left: 190px">
        ( &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp
        &nbsp )
      </div>
    </div>
`;
    const options = {
      margin: 10,
      filename: "affidavith-pdf.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf(html, options)
      .outputPdf()
      .then(async (outputData) => { });
  };
  const cheackMobileDublicate = async (num) => {
    if (num?.length < 10) {
      return;
    }
    try {
      const res = await axios.get(
        `https://evehicleadmin.mshfdc.co.in/api/specialSeller/phoneCheck/${num}`
      );
      if (res.data.alreadyExist) {
        setnumerr(true);
      } else {
        setnumerr(false);
      }
    } catch (error) { }
  };
  const changeHandle = (e, str, sy, idProfe, busi) => {
    if (e.target.name === "district") {
      const findDist = districMaha.find((item) => {
        return e.target.value === item.uid;
      });
      // console.log(findDist.title);
      setSistSet(findDist.title);
    }

    if (e.target.name === "castCategory") {
      const clone = { ...addSeller, castCategory: str, declareCaste: str };
      console.log(clone, "clone");
      setAddSeller(clone);
    }
    if (e.target.name == "mobileset") {
      if (e.target.value?.length == 11) {
        return;
      } else {
        cheackMobileDublicate(e.target.value);
        setMobileset(e.target.value);
      }
    }
    if (e.target.name == "guardian_contact") {
      if (e.target.value?.length == 11) {
        return;
      } else {
        const clone = { ...addSeller };
        const name = e.target.name;
        clone[name] = e.target.value;
        setAddSeller(clone);
      }
    }
    if (e.target.name == "PerOfDis") {
      if (e.target.value > 100) {
        return;
      }

      const clone = { ...addSeller };
      const name = e.target.name;
      clone[name] = e.target.value;
      setAddSeller(clone);
    } else if (busi) {
      const clone = { ...addSeller };
      const name = e.target.name;
      clone[name] = busi;
      setAddSeller(clone);
      console.log(clone);
    } else if (idProfe) {
      const clone = { ...addSeller };
      const name = e.target.name;
      clone[name] = idProfe;
      setAddSeller(clone);
      console.log(clone);
    } else if (sy) {
      const clone = { ...addSeller };
      const name = e.target.name;
      clone[name] = str;
      setAddSeller(clone);
      console.log(clone);
    } else if (str) {
      const clone = { ...addSeller };
      const name = e.target.name;
      clone[name] = str;
      setAddSeller(clone);
      console.log(clone);
    } else {
      const clone = { ...addSeller };
      const val = e.target.value;
      const name = e.target.name;
      clone[name] = val;
      setAddSeller(clone);
    }
  };

  const termConditionChange = (e) => {
    const clone = { ...addSeller };
    const val = e.target.value;
    const name = e.target.name;
    clone[name] = !clone.termsAndCondition;
    setAddSeller(clone);
  };

  const handleChange = (e, bul) => {
    const clone = { ...education };
    const name = e.target.name;
    clone[name] = bul;
    setEducation(clone);
  };

  const AddressHandle = (e, address) => {
    const clone = { ...docAddressProfe };
    const name = e.target.name;
    clone[name] = address;
    setDocAddressProfe(clone);
  };
  const tOfDisabilityChange = (e, tOfDisabilitys) => {
    const clone = { ...tOfDisability };
    const name = e.target.name;
    clone[name] = tOfDisabilitys;
    setTOfDisability(clone);
  };
  // const aOfDisabilityChange = (e, aOfDisabilitys) => {
  //     const clone = { ...aOfDisabality }
  //     const name = e.target.name
  //     clone[name] = aOfDisabilitys
  //     setAOfDisabality(clone)
  // }

  const empDetailsChange = (e, str) => {
    if (str) {
      const clone = { ...empDetails };
      const name = e.target.name;
      clone[name] = str;
      setEmpDetails(clone);
    }
  };
  const IdentityProofChange = (e, str) => {
    console.log(e, str);
    // debugger
    if (str) {
      const clone = { ...identityProof };
      const name = e.target.name;
      clone[name] = str;
      setIdentityProof(clone);
    }
  };

  const busiYouLikeToDoChange = (e, str) => {
    if (str) {
      const clone = { ...identityProof };
      const name = e.target.name;
      clone[name] = str;

    };
  };

  const disabtlyBirth = (e, disabtlyBirths) => {
    // const clone = { ...disabilitiesBirth }
    // const name = e.target.name
    // clone[name] = disabtlyBirths
    setDisabilitiesBirth(disabtlyBirths);
  };

  const [vlueDistric, setVlueDistric] = useState();
  // console.log('vlueDistric', vlueDistric);

  const divisonChange = (e) => {
    const value = e.target.value;
    setVlueDistric(value);
    distric(value);
  };

  const [domacileImg, setDomacileImg] = useState("");

  const [imageAddress, setAddress] = useState();
  const imgShoing = (url) => {
    setModalShow1(true);
    setAddress(url);
  };

  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMsgsign, setErrorMsgSign] = useState(false);
  const [isSuccesssign, setIsSuccessign] = useState(false);
  const [errorMsgdomail, setErrorMsgDomail] = useState(false);
  const [isSuccessdomail, setIsSuccesDomail] = useState(false);

  const [errorMsgcast, setErrorMsgCast] = useState(false);
  const [isSuccessCast, setIsSuccesCast] = useState(false);
  const [errorMsgAddress, setErrorMsgAddress] = useState(false);
  const [isSuccessAddress, setIsSuccesAddress] = useState(false);
  const [errorMsgdiability, setErrorMsgdiability] = useState(false);
  const [isSuccessdiability, setIsSuccesDiability] = useState(false);
  const [errorMsgIdProfe, setErrorMsgIdProfe] = useState(false);
  const [isSuccessMsgIdProfe, setIsSuccesMsgIdProfe] = useState(false);
  const [errorBankPassBook, setErrorBankPassBook] = useState(false);
  const [isSuccessBankPassBook, setIsSuccesBankPassBook] = useState(false);
  const [errorApplicantAffidavit, setErrorApplicantAffidavit] = useState(false);
  const [isSuccessApplicantAffidavit, setIsSuccesApplicantAffidavit] =
    useState(false);
  const [errorUdidCetifi, setErrorUdidCetifi] = useState(false);
  const [isSuccessUdidCetifi, setIsSuccesUdidCetifi] = useState(false);

  const handleUdidCetifi = async (e) => {
    const MIN_FILE_SIZE = 10;
    const MAX_FILE_SIZE = 500;
    const fileSizeKiloBytes = e?.target?.files[0]?.size / 1000;

    if (!e.target.files[0]) {
      // console.log('hghkhk');
      // setErrorMsg("Please choose a file");
      setErrorUdidCetifi("Please choose a file");
      // setIsSuccess(false)
      setIsSuccesUdidCetifi(false);
      return;
    }

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      // setErrorMsg("File size is less than minimum limit");
      setErrorUdidCetifi("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesUdidCetifi(false);
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // setErrorMsg("File size is greater than maximum limit");
      setErrorUdidCetifi("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesUdidCetifi(false);
      return;
    }

    // setErrorMsg("")
    setErrorUdidCetifi("");
    // setIsSuccess(true)

    const imgs = new FormData();
    const obj = {};
    // console.log(e.target.files);
    const tempt = addSeller.udId_File;
    try {
      imgs.append("image", e.target.files[0]);
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/cloudinaryImage/addImage1`,
        imgs
      );
      const obj = {
        public_id: res.data.public_id,
        url: res.data.url ? res.data.url : res.data.s3url,
      };
      // console.log(obj.url);
      const clone = { ...addSeller };
      clone[e.target.name] = obj;
      setIsSuccesUdidCetifi(true);
      setAddSeller(clone);
      await deleteImage(tempt);
      // console.log('cloneclone', clone.userPhoto);
      // console.log('cloneclone', e.target.name);
    } catch (error) {
      setErrorUdidCetifi(false);
      setIsSuccesUdidCetifi("");
      alert(error.response.data.message);
    }
  };

  const handleApplicantAffidavit = async (e) => {
    const MIN_FILE_SIZE = 10;
    const MAX_FILE_SIZE = 500;
    const fileSizeKiloBytes = e?.target?.files[0]?.size / 1000;

    if (!e.target.files[0]) {
      // console.log('hghkhk');
      // setErrorMsg("Please choose a file");
      setErrorApplicantAffidavit("Please choose a file");
      // setIsSuccess(false)
      setIsSuccesApplicantAffidavit(false);
      return;
    }

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      // setErrorMsg("File size is less than minimum limit");
      setErrorApplicantAffidavit("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesApplicantAffidavit(false);
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // setErrorMsg("File size is greater than maximum limit");
      setErrorApplicantAffidavit("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesApplicantAffidavit(false);
      return;
    }

    // setErrorMsg("")
    setErrorApplicantAffidavit("");
    // setIsSuccess(true)

    const imgs = new FormData();
    const obj = {};
    const tempt = addSeller.affidavith;

    try {
      imgs.append("image", e.target.files[0]);
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/cloudinaryImage/addImage1`,
        imgs
      );
      const obj = {
        public_id: res.data.public_id,
        url: res.data.url ? res.data.url : res.data.s3url,
      };
      // console.log(obj.url);
      const clone = { ...addSeller };
      clone[e.target.name] = obj;
      setIsSuccesApplicantAffidavit(true);
      setAddSeller(clone);
      await deleteImage(tempt);
      // console.log('cloneclone', clone.userPhoto);
      // console.log('cloneclone', e.target.name);
    } catch (error) {
      setErrorApplicantAffidavit(false);
      setIsSuccesApplicantAffidavit("");
      alert(error.response.data.message);
    }
  };

  const handleBankPassBook = async (e) => {
    const MIN_FILE_SIZE = 10;
    const MAX_FILE_SIZE = 500;
    const fileSizeKiloBytes = e?.target?.files[0]?.size / 1000;

    if (!e.target.files[0]) {
      console.log("hghkhk");
      // setErrorMsg("Please choose a file");
      setErrorBankPassBook("Please choose a file");
      // setIsSuccess(false)
      setIsSuccesBankPassBook(false);
      return;
    }

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      // setErrorMsg("File size is less than minimum limit");
      setErrorBankPassBook("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesBankPassBook(false);
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // setErrorMsg("File size is greater than maximum limit");
      setErrorBankPassBook("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesBankPassBook(false);
      return;
    }

    // setErrorMsg("")
    setErrorBankPassBook("");
    // setIsSuccess(true)

    const imgs = new FormData();
    const obj = {};
    const tempt = addSeller.frontOfBankPassBook;

    try {
      imgs.append("image", e.target.files[0]);
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/cloudinaryImage/addImage1`,
        imgs
      );
      const obj = {
        public_id: res.data.public_id,
        url: res.data.url ? res.data.url : res.data.s3url,
      };
      // console.log(obj.url);
      const clone = { ...addSeller };
      clone[e.target.name] = obj;
      setIsSuccesBankPassBook(true);
      setAddSeller(clone);
      await deleteImage(tempt);
      // console.log('cloneclone', clone.userPhoto);
      // console.log('cloneclone', e.target.name);
    } catch (error) {
      setErrorBankPassBook(false);
      setIsSuccesBankPassBook("");
      alert(error.response.data.message);
    }
  };

  const handleIdProfe = async (e) => {
    const MIN_FILE_SIZE = 10;
    const MAX_FILE_SIZE = 500;
    const fileSizeKiloBytes = e?.target?.files[0]?.size / 1000;

    if (!e.target.files[0]) {
      // console.log('hghkhk');
      // setErrorMsg("Please choose a file");
      setErrorMsgIdProfe("Please choose a file");
      // setIsSuccess(false)
      setIsSuccesMsgIdProfe(false);
      return;
    }

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      // setErrorMsg("File size is less than minimum limit");
      setErrorMsgIdProfe("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesMsgIdProfe(false);
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // setErrorMsg("File size is greater than maximum limit");
      setErrorMsgIdProfe("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesMsgIdProfe(false);
      return;
    }

    // setErrorMsg("")
    setErrorMsgIdProfe("");
    // setIsSuccess(true)

    const imgs = new FormData();
    const obj = {};
    const tempt = addSeller.idProofFile;

    try {
      imgs.append("image", e.target.files[0]);
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/cloudinaryImage/addImage1`,
        imgs
      );
      const obj = {
        public_id: res.data.public_id,
        url: res.data.url ? res.data.url : res.data.s3url,
      };
      // console.log(obj.url);
      const clone = { ...addSeller };
      clone[e.target.name] = obj;
      setIsSuccesMsgIdProfe(true);
      setAddSeller(clone);
      await deleteImage(tempt);
      // console.log('cloneclone', clone.userPhoto);
      // console.log('cloneclone', e.target.name);
    } catch (error) {
      setIsSuccesMsgIdProfe(false);
      setErrorMsgIdProfe("");
      alert(error.response.data.message);
    }
  };

  const handlediability = async (e) => {
    const MIN_FILE_SIZE = 10;
    const MAX_FILE_SIZE = 500;
    const fileSizeKiloBytes = e?.target?.files[0]?.size / 1000;

    if (!e.target.files[0]) {
      console.log("hghkhk");
      // setErrorMsg("Please choose a file");
      setErrorMsgdiability("Please choose a file");
      // setIsSuccess(false)
      setIsSuccesDiability(false);
      return;
    }

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      // setErrorMsg("File size is less than minimum limit");
      setErrorMsgdiability("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesDiability(false);
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // setErrorMsg("File size is greater than maximum limit");
      setErrorMsgdiability("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesDiability(false);
      return;
    }

    // setErrorMsg("")
    setErrorMsgdiability("");
    // setIsSuccess(true)

    const imgs = new FormData();
    const obj = {};

    try {
      imgs.append("image", e.target.files[0]);
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/cloudinaryImage/addImage1`,
        imgs
      );
      const obj = {
        public_id: res.data.public_id,
        url: res.data.url ? res.data.url : res.data.s3url,
      };

      console.log(res.data);
      const clone = { ...addSeller };
      clone[e.target.name] = obj;
      setIsSuccesDiability(true);
      setAddSeller(clone);

      // console.log('cloneclone', clone.userPhoto);
      // console.log('cloneclone', e.target.name);
    } catch (error) {
      setIsSuccesDiability(false);
      setErrorMsgdiability("");
      alert(error.response.data.message);
    }
  };

  const handleAddressProfe = async (e) => {
    const MIN_FILE_SIZE = 10;
    const MAX_FILE_SIZE = 500;
    const fileSizeKiloBytes = e?.target?.files[0]?.size / 1000;
    console.log("fileSizeKiloBytes", fileSizeKiloBytes);

    if (!e.target.files[0]) {
      console.log("hghkhk");
      // setErrorMsg("Please choose a file");
      setErrorMsgAddress("Please choose a file");
      // setIsSuccess(false)
      setIsSuccesAddress(false);
      return;
    }

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      // setErrorMsg("File size is less than minimum limit");
      setErrorMsgAddress("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesAddress(false);
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // setErrorMsg("File size is greater than maximum limit");
      setErrorMsgAddress("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesAddress(false);
      return;
    }

    // setErrorMsg("")
    setErrorMsgAddress("");
    // setIsSuccess(true)

    const imgs = new FormData();
    const obj = {};

    const tempt = addSeller.addressProof;

    try {
      imgs.append("image", e.target.files[0]);
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/cloudinaryImage/addImage1`,
        imgs
      );
      const obj = {
        public_id: res.data.public_id,
        url: res.data.url ? res.data.url : res.data.s3url,
      };
      // console.log(obj.url);
      const clone = { ...addSeller };
      clone[e.target.name] = obj;
      setIsSuccesAddress(true);
      setAddSeller(clone);
      setAddress(clone.addressProof.url);
      await deleteImage(tempt);
      // console.log('cloneclone', clone.userPhoto);
      // console.log('cloneclone', e.target.name);
    } catch (error) {
      setIsSuccesAddress(false);
      setErrorMsgAddress("");
      alert(error.response.data.message);
    }
  };

  const [imagePopup, setImagePopup] = useState();

  const handleCastCertifiacte = async (e) => {
    const MIN_FILE_SIZE = 10;
    const MAX_FILE_SIZE = 500;
    const fileSizeKiloBytes = e?.target?.files[0]?.size / 1000;

    if (!e.target.files[0]) {
      // console.log("hghkhk");
      // setErrorMsg("Please choose a file");
      setErrorMsgCast("Please choose a file");
      // setIsSuccess(false)
      setIsSuccesCast(false);
      return;
    }

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      // setErrorMsg("File size is less than minimum limit");
      setErrorMsgCast("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesCast(false);
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // setErrorMsg("File size is greater than maximum limit");
      setErrorMsgCast("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesCast(false);
      return;
    }

    // setErrorMsg("")
    setErrorMsgCast("");
    // setIsSuccess(true)

    const imgs = new FormData();
    const obj = {};

    const tempt = addSeller.castCertificate;

    try {
      imgs.append("image", e.target.files[0]);
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/cloudinaryImage/addImage1`,
        imgs
      );
      const obj = {
        public_id: res.data.public_id,
        url: res.data.url ? res.data.url : res.data.s3url,
      };
      // console.log(obj.url);
      const clone = { ...addSeller };
      clone[e.target.name] = obj;
      setIsSuccesCast(true);
      setAddSeller(clone);
      await deleteImage(tempt);
      // console.log('cloneclone', clone);
      // setImagePopup(clone.castCertificate.url)

      // console.log('cloneclone', e.target.name);
    } catch (error) {
      setIsSuccesCast(false);
      setErrorMsgCast("");
      alert(error.response.data.message);

    }
  };

  const [domaileImagePopup, setDomaileImagePopup] = useState();

  const handleDomail = async (e) => {
    const MIN_FILE_SIZE = 10;
    const MAX_FILE_SIZE = 500;
    const fileSizeKiloBytes = e?.target?.files[0]?.size / 1000;

    if (!e.target.files[0]) {
      console.log("hghkhk");
      // setErrorMsg("Please choose a file");
      setErrorMsgDomail("Please choose a file");
      // setIsSuccess(false)
      setIsSuccesDomail(false);
      return;
    }

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      // setErrorMsg("File size is less than minimum limit");
      setErrorMsgDomail("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesDomail(false);
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      // setErrorMsg("File size is greater than maximum limit");
      setErrorMsgDomail("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccesDomail(false);
      return;
    }

    // setErrorMsg("")
    setErrorMsgDomail("");
    // setIsSuccess(true)

    const imgs = new FormData();
    const obj = {};

    const tempt = addSeller.domacileCert;

    try {
      imgs.append("image", e.target.files[0]);
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/cloudinaryImage/addImage1`,
        imgs
      );
      const obj = {
        public_id: res.data.public_id,
        url: res.data.url ? res.data.url : res.data.s3url,
      };
      // console.log(obj.url);
      const clone = { ...addSeller };
      clone[e.target.name] = obj;
      setIsSuccesDomail(true);
      setAddSeller(clone);
      setDomaileImagePopup(clone.domacileCert.url);
      await deleteImage(tempt);

      // console.log('cloneclone', clone);
      // console.log('cloneclone', e.target.name);
    } catch (error) {
      setIsSuccesDomail(false);
      setErrorMsgDomail("");
      alert(error.response.data.message);
    }
  };

  const handleSign = async (e) => {
    const MIN_FILE_SIZE = 3; //0.003
    const MAX_FILE_SIZE = 30; ///0.03
    const fileSizeKiloBytes = e?.target?.files[0]?.size / 1000;
    if (!e.target.files[0]) {
      // setErrorMsg("Please choose a file");
      setErrorMsgSign("Please choose a file");
      // setIsSuccess(false)
      setIsSuccessign(false);
      return;
    }

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      console.log("hghkhk");
      // setErrorMsg("File size is less than minimum limit");
      setErrorMsgSign("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccessign(false);
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      console.log("hghkhk,", fileSizeKiloBytes, MAX_FILE_SIZE);
      // setErrorMsg("File size is greater than maximum limit");
      setErrorMsgSign("File size is less than minimum limit");
      // setIsSuccess(false)
      setIsSuccessign(false);
      return;
    }

    // setErrorMsg("")
    setErrorMsgSign("");
    // setIsSuccess(true)

    const imgs = new FormData();
    const obj = {};

    const tempt = addSeller.signPhoto;

    try {
      imgs.append("image", e.target.files[0]);
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/cloudinaryImage/addImage2`,
        imgs
      );
      const obj = { public_id: res.data.public_id, url: res.data.url };
      // console.log(obj.url);
      const clone = { ...addSeller };
      clone[e.target.name] = obj;
      setIsSuccessign(true);
      setAddSeller(clone);
      toDataURL(res.data.url, e.target.name);
      await deleteImage(tempt);

      // console.log('cloneclone', clone.userPhoto);
      // console.log('cloneclone', e.target.name);
    } catch (error) {
      setIsSuccessign(false);
      setErrorMsgSign("");
      alert(error.response.data.message);
    }
  };
  const handleImage = async (e) => {
    // console.log(e.target.files);
    if (e.target.name === "signPhoto") {
      handleSign(e);
      return;
    }

    if (e.target.name === "domacileCert") {
      handleDomail(e);
      return;
    }
    if (e.target.name === "castCertificate") {
      handleCastCertifiacte(e);
      return;
    }

    if (e.target.name === "addressProof") {
      handleAddressProfe(e);
      return;
    }

    if (e.target.name === "disabilityCertImage") {
      handlediability(e);
      return;
    }
    if (e.target.name === "idProofFile") {
      handleIdProfe(e);
      return;
    }

    if (e.target.name === "frontOfBankPassBook") {
      handleBankPassBook(e);
      return;
    }

    if (e.target.name === "affidavith") {
      handleApplicantAffidavit(e);
      return;
    }

    if (e.target.name === "udId_File") {
      handleUdidCetifi(e);
      return;
    }

    // setDomacileImg(e.target.files[0])
    const imgs = new FormData();
    const obj = {};

    const MIN_FILE_SIZE = 150; ///0.015
    const MAX_FILE_SIZE = 1020;
    const fileSizeKiloBytes = e?.target?.files[0]?.size / 150;

    if (!e.target.files[0]) {
      setErrorMsg("Please choose a file");
      // setErrorMsgSign("Please choose a file")
      setIsSuccess(false);
      // setIsSuccessign(false)
      return;
    }

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      setErrorMsg("File size is less than minimum limit");
      // setErrorMsgSign("File size is less than minimum limit")
      setIsSuccess(false);
      // setIsSuccessign(false)
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      // setErrorMsgSign("File size is less than minimum limit")
      setIsSuccess(false);
      // setIsSuccessign(false)
      return;
    }

    setErrorMsg("");
    // setErrorMsgSign("")

    // setIsSuccessign(true)

    const tempt = addSeller.userPhoto;

    try {
      imgs.append("image", e.target.files[0]);
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/cloudinaryImage/addImage2`,
        imgs
      );
      setTimeout(() => {
        const obj = { public_id: res.data.public_id, url: res.data.url };
        // console.log('error', res.data);
        // console.log(obj.url);
        const clone = { ...addSeller };
        clone[e.target.name] = obj;
        setIsSuccess(true);
        setAddSeller(clone);
        toDataURL(res.data.url, e.target.name);
      }, 1000)

      await deleteImage(tempt);
      // console.log('cloneclone', clone.userPhoto);
      // console.log('cloneclone', e.target.name);
    } catch (error) {
      setIsSuccess(false);
      setErrorMsg("");
      alert(error.response.data.message);
    }
  };

  const [combo, setCombo] = useState(null);

  const [filterImage, setFilterImage] = useState(null);
  // console.log('combo', filterImage?.icon.url);
  const formCombo = async () => {
    try {
      const res = await axios.get(
        `https://evehicleadmin.mshfdc.co.in/api/category/child/${25}`,
        {
          withCredentials: true,
        }
      );
      console.log(res?.data);
      setCombo(res?.data);
    } catch (error) { }
  };

  // console.log(comboId);

  const [showInput, setShowInput] = useState(false);

  const comboHandleChange = (e) => {
    setComboId(e.target.value);
    const filter = combo.find((item) => {
      return item?._id == e.target.value;
    });
    setFilterImage(filter);
    if (e.target.value === "655871355f3044de88295e94") {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  };
  const divison = async () => {
    try {
      const res = await axios.get(`${base_url}division/public`, {
        withCredentials: true,
      });
      setDivisionn(res?.data);
    } catch (error) { }
  };
  const distric = async (val) => {
    console.log(val);
    try {
      const res = await axios.get(
        `${base_url}district/division/public/${val}`,
        {
          withCredentials: true,
        }
      );
      setDistricMaha(res?.data);
    } catch (error) { }
  };

  // console.log(addSeller);
  // console.log(education);
  // console.log(addSeller);
  // console.log(docAddressProfe);
  // console.log(tOfDisability);
  // console.log(aOfDisabality);
  // console.log(empDetails);
  // console.log(identityProof);
  // console.log(businessLike);

  const [Afedefit, setAfedefit] = useState();

  const [listProfe, setListProfe] = useState(null);


  const postData = async () => {
    // console.log(addSeller);
    // handleConvertToPdf();
    const clone = {
      ...addSeller,
      educationDetails: education,
      docOfAddProof: docAddressProfe,
      typesOfDisability: tOfDisability,
      employmentDetail: empDetails,
      disabltyByBirth: disabilitiesBirth,
      businessCategory: comboId,
      language_id: langId,
      mobile: mobileset,
      dob: dateOfBirth,
      verifiedMobile: phoneVerified,
      division: vlueDistric,
      pincode: inputValue,
    };
    if (!clone.affidavith.url && !clone.affidavith.s3url) {
      alert("Please upload Front Page of Affidavit");
      return;
      // alert('Please upload Front Page of Affidavit!')
    }
    if (!clone.userPhoto.url) {
      alert("please upload Profile Photo");
      return;
    }
    if (!clone.signPhoto.url) {
      alert("please upload Sign Photo");
      return;
    }
    if (!clone.castCertificate.url && clone.castCategory != "General") {
      alert("please upload Cast Certificate");
      return;
    }
    if (!clone.domacileCert.url && !clone.domacileCert.s3url) {
      alert("please upload Domacile Certificate");
      return;
    }
    if (!clone.addressProof.url && !clone.addressProof.s3url) {
      alert("please upload Address proof");
      return;
    }
    if (!clone.udId_File.url && !clone.udId_File.s3url) {
      alert("please upload UDID Certificate");
      return;
    }
    if (!clone.disabilityCertImage.url && !clone.disabilityCertImage.s3url) {
      alert("please upload Disability Certificate");
      return;
    }
    if (!clone.idProofFile.url && !clone.idProofFile.s3url) {
      alert("please upload Identity Proof");
      return;
    }
    if (!clone.frontOfBankPassBook.url && !clone.frontOfBankPassBook.s3url) {
      alert("Please upload Front Page of Bank Passbook");
      return;
    }
    if (!clone.affidavith.url && !clone.affidavith.s3url) {
      alert("Please upload Front Page of Bank Passbook");
      return;
    }
    setspinn(true);
    try {
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/specialSeller/add_Seller`,
        // `https://evehicleadmin.mshfdc.co.in/api/specialSeller/add_Seller`,
        clone,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccessData(res.data);
      setListProfe(res.data)
      // console.log('post-data', res.data);
      if (res.data?.status == 500 || res?.status != 200) {
        alert("All Field are Mandatory");
      } else {
        setModalShow(true);
      }
      setspinn(false);
    } catch (error) {
      setspinn(false);
      if (error.response.data.message) {
        alert(error.response.data.message)
      } else {
        alert("!Failed to submit, Please try after some time")
      }
    }
    // console.log('clone', clone);
    // console.log(formData);
  };

  const toastSuccessMessage = () => {
    toast.success("Your form Draft Save", {
      position: "top-center",
    });
  };

  const saveDraft = async () => {
    // console.log('darft', addSeller);
    const clone = {
      ...addSeller,
      educationDetails: education,
      docOfAddProof: docAddressProfe,
      typesOfDisability: tOfDisability,
      employmentDetail: empDetails,
      // businessYouLikeToDo: businessLike,
      disabltyByBirth: disabilitiesBirth,
      businessCategory: comboId,
      language_id: langId,
      mobile: mobileset,
      dob: dateOfBirth,
      verifiedMobile: phoneVerified,
      division: vlueDistric,
    };
    try {
      const res = await axios.post(
        `https://evehicleadmin.mshfdc.co.in/api/specialSeller/saveDraft`,
        clone,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        toastSuccessMessage();
      }
    } catch (error) { }
  };

  const saveDreftGet = async (mobileset) => {
    try {
      const res = await axios.get(
        `https://evehicleadmin.mshfdc.co.in/api/specialSeller/draft/${mobileset}`
      );
      if (!res?.data?.data) {
        return;
      }
      setAddSeller(res.data.data);
      setEducation(res.data.data.educationDetails);
      setDocAddressProfe(res.data.data.docOfAddProof);
      setComboId(res.data.data.businessCategory);
      const filter = combo.find((item) => {
        return item?._id == res.data.data.businessCategory;
      });
      setFilterImage(filter);
      setDisabilitiesBirth(res.data.data.disabltyByBirth);

      setVlueDistric(res.data.data.division);
      distric(res.data.data.division);
      setTOfDisability(res.data.data.typesOfDisability);
      // setIdentityProof(res.data.data.idProof)
      IdentityProofChange(res.data.data.idProof);
      // setBussinessLike(res.data.data.businessYouLikeToDo);
      setEmpDetails(res.data.data.employmentDetail);
    } catch (error) { }
  };

  useEffect(() => {
    saveDreftGet(window.localStorage.getItem("phoneNumber"));
  }, [combo]);

  // const downloadPdf = () => {
  //     const capture = document.querySelector('actual-recipt')
  //     html2canvas(capture).then((canvas) => {
  //         const imgData = canvas.toDataURL('img/png')
  //         const doc = new jsPDF('p', 'mm', 'a4')
  //         const componentWidth = doc.internal.pageSize.getWidth()
  //         const componentHeight = doc.internal.pageSize.getHeight()
  //         doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight)
  //         doc.save('receipt.pdf')
  //     })
  // }

  const pdfRef = useRef();
  const downloadPdf = () => {
    downloadPdff(fontSize, colorMode, [listProfe[0]?.seller])
    //     const input = pdfRef.current;
    //     if (input) {
    //       // Configuration for html2pdf
    //       const options = {
    //         margin: 10,
    //         filename: "generated-pdf.pdf",
    //         image: { type: "jpeg", quality: 0.98 },
    //         html2canvas: { scale: 2 },
    //         jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    //       };

    //       const fileInput = document.getElementById("fileInput");
    //       const fileInput1 = document.getElementById("fileInput1");
    //       const fileInput2 = document.getElementById("fileInput2");
    //       const fileInput3 = document.getElementById("fileInput3");
    //       const fileInput4 = document.getElementById("fileInput4");
    //       const fileInput5 = document.getElementById("fileInput5");
    //       const fileInput6 = document.getElementById("addressProo");
    //       const fileInput7 = document.getElementById("domacileCert");
    //       const fileInput8 = document.getElementById("castCertificate");

    //       const textInput = document.createElement("input");
    //       textInput.type = "text";
    //       textInput.style.display = "none";
    //       fileInput?.parentNode.replaceChild(textInput, fileInput);
    //       fileInput1?.parentNode.replaceChild(textInput, fileInput1);
    //       fileInput2?.parentNode.replaceChild(textInput, fileInput2);
    //       fileInput3?.parentNode.replaceChild(textInput, fileInput3);
    //       fileInput4?.parentNode.replaceChild(textInput, fileInput4);
    //       fileInput5?.parentNode.replaceChild(textInput, fileInput5);
    //       fileInput6?.parentNode.replaceChild(textInput, fileInput6);
    //       fileInput7?.parentNode.replaceChild(textInput, fileInput7);
    //       fileInput8?.parentNode.replaceChild(textInput, fileInput8);
    //       /*       const fileInput2 = document.getElementById("domacileCrt");
    //       /*       const fileInput2 = document.getElementById("domacileCrt");
    //       /*       const fileInput2 = document.getElementById("domacileCrt");
    //       const fileInput3 = document.getElementById("flex");
    //       const fileInput4 = document.getElementById("castCertificate");
    //       const fileInput5 = document.getElementById("udidType");
    //  */ //fileInput1.parentNode.replaceChild(textInput, fileInput);
    //       /*       fileInput2.parentNode.replaceChild(textInput, fileInput);
    //       fileInput3.parentNode.replaceChild(textInput, fileInput);
    //       fileInput4.parentNode.replaceChild(textInput, fileInput);
    //       fileInput5.parentNode.replaceChild(textInput, fileInput);
    //  */ // // Generate PDF from HTML content
    //       html2pdf(input, options)
    //         .outputPdf()
    //         .then(async (outputData) => { });
    //     }





    // html2canvas(input).then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png')
    //     const pdf = new jsPDF('p', 'mm', 'a2', true)
    //     const pdfWidth = pdf.internal.pageSize.getWidth()
    //     const pdfHeight = pdf.internal.pageSize.getHeight()
    //     const imgWidth = canvas.width
    //     const imgHeight = canvas.height
    //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
    //     const imgX = (pdfWidth - imgWidth * ratio) / 2
    //     const imgY = 10
    //     pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio)
    //     pdf.save(`Applicant No ${successData?.applicationNo}`)
    // })
  };

  const [birthday, setBirthday] = useState();

  function submitBday() {
    var Q4A = "Your birthday is: ";
    var Bdate = document.getElementById("bday").value;
    // console.log(Bdate);
    setDateOfBirth(Bdate);
    var Bday = +new Date(Bdate);
    // console.log('Bday', Bday);
    Q4A +=
      Bdate + ". You are age is " + " " + ~~((Date.now() - Bday) / 31557600000);
    var theBday = document.getElementById("resultBday");
    console.log("theBday", ~~((Date.now() - Bday) / 31557600000));
    setBirthday(~~((Date.now() - Bday) / 31557600000));
    theBday.innerHTML = Q4A;
    // console.log(Q4A);
  }

  const [errors, setErrors] = useState({});
  const [customError, setcustomError] = useState(false);
  const handleValidation = (event) => {
    event.preventDefault();
    let iserror = false;
    setErrors(
      Validation(
        addSeller,
        postData,
        mobileset,
        comboId,
        education,
        vlueDistric,
        docAddressProfe,
        tOfDisability,
        aOfDisabality,
        identityProof,
        businessLike,
        empDetails,
        dateOfBirth,
        inputValue,
        divisonn

      )
    );
    customAddressProff();
    customtOfDisability();
    customtareaofDisability();
    customtbusinessWould();
    customtDisabilitybyBirth();
    customtDisabilityCertificate();
    customEmploymentStatus();
    customBplApl();
    customAnualIncom();
    bussilikeToDo();
    identyprooof();
    // customtdisablebybirth();
    for (const key in education) {
      const val = education[key];
      if (val === false) {
        iserror = true;
      } else {
        iserror = false;
        setcustomError(false);
        return;
      }
    }
    if (iserror) {
      setcustomError(true);
    }
  };
  const [customAddress, setcustomAddress] = useState(false);

  const customAddressProff = () => {
    let iserror = false;
    for (const key in docAddressProfe) {
      const val = docAddressProfe[key];
      if (val === false) {
        iserror = true;
      } else {
        iserror = false;
        setcustomAddress(false);
        return;
      }
    }
    if (iserror) {
      setcustomAddress(true);
    }
  };

  const [customDisability, setcustomDisability] = useState(false);

  const customtOfDisability = () => {
    let iserror = false;
    for (const key in tOfDisability) {
      const val = tOfDisability[key];
      if (val === false) {
        iserror = true;
      } else {
        iserror = false;
        setcustomDisability(false);
        return;
      }
    }
    if (iserror) {
      setcustomDisability(true);
    }
  };

  const [areaofDisability, setareaofDisability] = useState(false);

  const customtareaofDisability = () => {
    let iserror = false;
    for (const key in aOfDisabality) {
      const val = aOfDisabality[key];
      if (val === false) {
        iserror = true;
      } else {
        iserror = false;
        setareaofDisability(false);
        return;
      }
    }
    if (iserror) {
      setareaofDisability(true);
    }
  };

  const [businessWould, setbusinessWould] = useState(false);

  const customtbusinessWould = () => {
    let iserror = false;
    for (const key in businessLike) {
      const val = businessLike[key];
      if (val === false) {
        iserror = true;
      } else {
        iserror = false;
        setbusinessWould(false);
        return;
      }
    }
    if (iserror) {
      setbusinessWould(true);
    }
  };
  const [DisabilitybyBirth, setDisabilitybyBirth] = useState(false);

  const customtDisabilitybyBirth = () => {
    let iserror = false;

    if (disabilitiesBirth === false) {
      iserror = true;
    } else {
      iserror = false;
      setDisabilitybyBirth(false);
      return;
    }
    if (iserror) {
      setDisabilitybyBirth(true);
    }
  };

  const [DisabilityCertificate, setDisabilityCertificate] = useState(false);

  const customtDisabilityCertificate = () => {
    let iserror = false;

    if (addSeller.disabilityCert === false) {
      iserror = true;
    } else {
      iserror = false;
      setDisabilityCertificate(false);
      return;
    }
    if (iserror) {
      setDisabilityCertificate(true);
    }
  };

  // const [disablebybirth, setDisablebybirth] = useState(false);
  // const customtdisablebybirth = () => {
  //   let iserror = false;

  //   if (addSeller.disabilityCert === false) {
  //     iserror = true;
  //   } else {
  //     iserror = false;
  //     setDisablebybirth(false);
  //     return;
  //   }
  //   if (iserror) {
  //     setDisablebybirth(true);
  //   }
  // };



  const [empDetailss, setEmpDetailss] = useState(false);

  const customEmploymentStatus = () => {
    if (empDetails.status === "") {
      setEmpDetailss(true);
    } else {
      setEmpDetailss(false);
    }
  };
  const [bpl, setbpl] = useState(false);
  const customBplApl = () => {
    if (empDetails.bpl === "") {
      setbpl(true);
    } else {
      setbpl(false);
    }
  };

  const [likeToDo, setLikeToDo] = useState(false);
  const bussilikeToDo = () => {
    if (addSeller.businessYouLikeToDo === "") {
      setLikeToDo(true);
    } else {
      setLikeToDo(false);
    }
  };

  const [identyProoof, setIdentyProoof] = useState(false);
  const identyprooof = () => {
    if (addSeller.idProof === "") {
      setIdentyProoof(true);
    } else {
      setIdentyProoof(false);
    }
  };


  const [anual, setAnual] = useState(false);

  const customAnualIncom = () => {
    if (empDetails.annualIncom === "") {
      setAnual(true);
    } else {
      setAnual(false);
    }
  };

  const [aadharCardVerified, setAadhaCardVerified] = useState(null);

  // console.log(aadharCardVerified);

  const [aadharNumber, setAadharNumber] = useState();
  const adharCardVerified = async (val) => {
    try {
      const res = await axios.get(
        `${base_url}specialSeller/isAdhaarExist/${val}`
      );
      // console.log(res.data.isExist);
      if (res.data.isExist === true) {
        setAadhaCardVerified(true);
        setTimeout(() => {
          setAadhaCardVerified(false);
        }, 3000);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      alert(error.message);
      return true;
    }
  };

  useEffect(() => {
    formCombo();
    divison();
    // adharCardVerified()
  }, []);

  useEffect(() => {
    setMobileset(window.localStorage.getItem("phoneNumber"));
    setPhoneVerified(window.localStorage.getItem("phoneNumber"));
  }, []);
  useEffect(() => {
    setLangId(window.localStorage.getItem("lang"));
  }, []);

  const handleInputChange = (event) => {
    const onlyAlphabets = /^[a-zA-Z]+$/;
    const clone = { ...addSeller };
    const newValue = event.target.value;
    clone[event.target.name] = newValue;

    if (onlyAlphabets.test(newValue) || newValue === "") {
      setAddSeller(clone);
    }
  };

  const handleAadharChange = (event) => {
    const clone = { ...addSeller };
    const inputAadhar = event.target.value;
    // setAadharNumber(inputAadhar)
    clone[event.target.name] = inputAadhar;
    const cleanedAadhar = inputAadhar.replace(/\D/g, "");
    if (inputAadhar?.length == 12) {
      adharCardVerified(inputAadhar);
    }
    if (cleanedAadhar.length <= 12) {
      setAddSeller(clone);
    }
  };

  // console.log(addSeller);

  const pdfRef2 = useRef();
  const downloadPdfDraf = () => {
    const input = pdfRef2.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 10;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save(`Applicant No ${successData?.applicationNo}`);
    });
  };

  const [loader, setLoader] = useState(false);
  const handleGenerateExcel = (item) => {
    setLoader(true);
    // setPdfprinting(item)
    setTimeout(() => {
      downloadPdfDraf();
      setLoader(false);
    }, 1000);
  };

  const pdfContainerRef = useRef();

  const [imgPhoto, setImgPhoto] = useState("");
  const [imgSign, setImgSign] = useState("");

  function toDataURL(url, na) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();

      reader.onloadend = function () {
        // console.log(reader.result);
        if (na == "signPhoto") {
          setImgSign(reader.result.toString());
        } else {
          setImgPhoto(reader.result.toString());
        }
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
    // setImgPhoto()
  }

  const handleConvertToPdf = async () => {
    const pdfContainer = pdfContainerRef.current;
    // Ensure the PDF container is not empty
    if (pdfContainer) {
      // Configuration for html2pdf
      const options = {
        margin: 10,
        filename: "generated-pdf.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      // // Generate PDF from HTML content
      html2pdf(pdfContainer, options)
        .outputPdf()
        .then(async (outputData) => { });
    }
  };

  const handeImage = (e) => {
    // debugger
    console.log(e);
    let file = e.target.files[0];
    console.log(file);
    pdfSend(file);
  };

  const pdfSend = async (outputData) => {
    const pdf = await html2pdf(outputData);
    const formData = new FormData();
    formData.append(
      "image",
      new Blob([pdf.output("blob")], { type: "application/pdf" }),
      "converted.pdf"
    );

    // formData.append('image', outputData);

    // console.log(formData.get('image'));
    const res = await axios.post(`${base_url}cloudinaryimage/test`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(res);
  };

  const [selectedImage, setSelectedImage] = useState(null);
  // console.log('selectedImage', selectedImage);
  const ImageUpload = (img) => {
    // console.log('img', img);
    const file = img.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const codeSendHtml = () => {
    const add = document.getElementById("code-are-send");

    const clone = { ...addSeller, html: add };
    // setAddSeller(clone)
    console.log(clone);
    console.log(add);
  };

  const deleteImage = async (data) => {
    try {
      const res = await axios.delete(
        `${base_url}cloudinaryimage/deleteImage/${data.public_id}&${data.s3key}`
      );
      console.log(res);
    } catch (error) { }
  };

  const [coress, setCores] = useState();

  // console.log(addSeller);
  const sameAsAbove = () => {
    setAddSeller({ ...addSeller, permanentAddress: addSeller.coresAddress });
  };


  const [inputValue, setInputValue] = useState('');



  const handleInputChangee = (e) => {
    const value = e.target.value;

    // Validate if the input is a 6-digit number
    const isValidInput = /^\d{0,6}$/.test(value);

    if (isValidInput) {
      setInputValue(value);
    }
  };


  if (1) {
    return (
      <>
        {spinn && (
          <div className="preloaderCount">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}


        <div className="seller-special mb-3" ref={pdfRef} id="code-are-send">
          <div className="container page" ref={pdfContainerRef} id="pdf-contentpdf-content">
            <div className="row">
              <div
                className={`col-lg-12 conatct-seller ${colorMode === "white" ? "form-boder" : ""
                  }`}
                style={{ fontSize: `${fontSize}px` }}
              >
                <div
                  className={`border border-primary ${colorMode === "white" ? "border-primaryy-2" : ""
                    }`}
                >
                  <div className="form-logo">
                    <img src={log} alt="" />
                  </div>
                  <div className="col-md-12 text-center mt-2">
                    <p className="fs-6 fw-bold">
                      महाराष्ट्र राज्य दिव्यांग वित्त व विकास महामंडळ मर्या.
                      मुंबई
                    </p>
                    <p className="fs-6 fw-bold">
                      (ISO 9001 : 2008 Certified Organisation)
                    </p>
                    <span className="fs-6 fw-bold">
                      Person with Disabilities Welfare Department, Government of
                      Maharashtra
                    </span>{" "}
                    <br />
                    <span>दिव्यांग कल्याण विभाग, महाराष्ट्र शासन </span>
                  </div>
                  <div className="form-logo-2">
                    <img src={log2} alt="" />
                  </div>
                </div>
                {/* <hr className='border border-1 border-secondary' /> */}
                <div className="border border-primary">
                  <div className="col-md-12 text-center mt-2">
                    <span className="fs-6 ">
                      Application form for Environmentally Friendly
                      e-Vehicles/E-cart for Divyangjan to become self-reliant{" "}
                    </span>{" "}
                    <br />
                    <span>
                      दिव्यांग व्यक्तींना स्वावलंबी होण्याच्या दृष्टीने हरित
                      उर्जेवर चालणाऱ्या पर्यावरण स्नेही फिरत्या वाहनावरील दुकान
                      योजने अंतर्गत अर्ज करण्यासाठीचा अर्ज
                    </span>
                  </div>
                </div>
                <div
                  className={`border border-primary actual-recipt ${colorMode === "white" ? "actual-recipt-2" : ""
                    }`}
                >
                  <div className="col-md-12 mt-4 padding-class">
                    <h6>Personal Details/वैयक्तिक माहिती</h6>
                    <hr className="border border-1 border-primary m-0 p-0" />
                  </div>
                  {successData?.applicationNo ? (
                    <h3 className="text-center">
                      Your Registration No:{" "}
                      <span style={{ color: "red" }}>
                        {successData[0]?.seller?.applicationNo}
                      </span>
                    </h3>
                  ) : (
                    ""
                  )}
                  {successData?.applicationDate ? (
                    <h3 className="text-center">
                      Your Registration Date :
                      <span style={{ color: "red" }}>
                        {successData[0]?.seller?.applicationDate}
                      </span>
                    </h3>
                  ) : (
                    ""
                  )}
                  <form action="" onSubmit={handleValidation}>
                    <div className="row m-0 p-0">
                      <div className="col-md-8 mt-3 m-0 p-0 disc-3">
                        <ul
                          type="disc"
                          className="disc-2 "
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2 mb-3">
                              Name of Applicant/अर्जदाराचे नाव <sup>*</sup>
                            </label>
                            <br />
                            <input
                              type="text"
                              placeholder="First Name"
                              className="pl-2 mr-2"
                              name="bene_firstname"
                              id="#txtNumeric"
                              required
                              value={addSeller?.bene_firstname}
                              onChange={handleInputChange}
                            />
                            {errors.bene_firstname && (
                              <p style={{ color: "red" }}>
                                {errors.bene_firstname}
                              </p>
                            )}
                            <input
                              type="text"
                              className="pl-2 mr-2"
                              placeholder="Middle name"
                              name="bene_middlename"
                              value={addSeller?.bene_middlename}
                              onChange={handleInputChange}
                            />
                            {/* {errors.bene_middlename && <p style={{ color: 'red' }}>{errors.bene_middlename}</p>} */}
                            <input
                              type="text"
                              placeholder="Last Name"
                              className="pl-2"
                              name="bene_lastname"
                              value={addSeller?.bene_lastname}
                              onChange={handleInputChange}
                            />{" "}
                            {errors.bene_lastname && (
                              <p style={{ color: "red" }}>
                                {errors.bene_lastname}
                              </p>
                            )}
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्याचेनाव <sup>*</sup>
                                </label>
                                <div>
                                    <input type="text" placeholder="आडनाव" className="pl-2" />{" "}
                                    <input type="text" placeholder="नाव" className="pl-2" />{" "}
                                    <input
                                        type="text"
                                        className="pl-2"
                                        placeholder="वडिलांचे/तिचे नाव"
                                    />
                                </div>
                            </li> */}
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2 mb-3">
                              Mother’s Name/आईचे नाव <sup>*</sup>
                            </label>
                            <div>
                              <input
                                type="text"
                                placeholder="First Name"
                                className="pl-2"
                                name="mother_firstname"
                                value={addSeller.mother_firstname}
                                onChange={handleInputChange}
                              />{" "}
                              {errors.mother_firstname && (
                                <p style={{ color: "red" }}>
                                  {errors.mother_firstname}
                                </p>
                              )}
                              <input
                                type="text"
                                className="pl-2 me-2"
                                placeholder="Middle name"
                                name="mother_middlename"
                                value={addSeller.mother_middlename}
                                onChange={handleInputChange}
                              />
                              {/* {errors.mother_middlename && <p style={{ color: 'red' }}>{errors.mother_middlename}</p>} */}
                              <input
                                type="text"
                                placeholder="Last Name"
                                className="pl-2 "
                                name="mother_lastname"
                                value={addSeller.mother_lastname}
                                onChange={handleInputChange}
                              />{" "}
                              {errors.mother_lastname && (
                                <p style={{ color: "red" }}>
                                  {errors.mother_lastname}
                                </p>
                              )}
                            </div>
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    आईचे नाव<sup>*</sup>
                                </label>
                                <div>
                                    <input type="text" placeholder="आडनाव" className="pl-2" />{" "}
                                    <input type="text" placeholder="नाव" className="pl-2" />{" "}
                                    <input type="text" className="pl-2" placeholder="तिचे नाव" />
                                </div>
                            </li> */}
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2 mb-2">
                              Date of Birth/जन्मतारीख <sup>*</sup>
                            </label>
                            <div>
                              <input
                                type="date"
                                className="pl-2"
                                id="bday"
                                name="bday"
                                onChange={submitBday}
                              />{" "}
                              {errors.dob && <p style={{ color: 'red' }}>{errors.dob}</p>}
                              <p id="resultBday"></p>
                              {/* <input
                                                            type="text"
                                                            placeholder="Age(Min 18 to Max 55)"
                                                            className="ml-5 pl-2"
                                                            style={{ width: 250 }}
                                                            name="dob"
                                                            value={resultBday}

                                                        /> */}
                            </div>
                            {/* {errors.dateOfBirth && (
                              <p style={{ color: "red" }}>
                                {errors.dateOfBirth}
                              </p>
                            )} */}
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    जन्मतारीख<sup>*</sup>
                                </label>
                                <div>
                                    <input type="date" className="pl-2" />{" "}
                                    <input
                                        type="text"
                                        placeholder="लाभार्थ्याचे वय (किमान १८ वर्षे ते कमाल ५५ वर्षे)"
                                        className="ml-5 pl-2"
                                        style={{ width: 250 }}
                                    />
                                </div>
                            </li> */}

                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2 mb-2">
                              Mobile No./भ्रमणध्वनी क्र. +९१<sup>*</sup>
                            </label>
                            <div>
                              <input
                                type="number"
                                placeholder="Only 10 Digit Number"
                                className="pl-2"
                                name="mobileset"
                                value={mobileset}
                                onChange={changeHandle}
                              />{" "}
                              {errnumber && (
                                <p style={{ color: "red" }}>
                                  Mobile Already Exist!
                                </p>
                              )}
                              {errors.mobileset && (
                                <p style={{ color: "red" }}>
                                  {errors.mobileset}
                                </p>
                              )}
                              <input
                                type="number"
                                placeholder="+91"
                                className="pl-2 d-none"
                                name="phoneVerified"
                                value={phoneVerified}
                                onChange={changeHandle}
                              />{" "}
                              {/* <input
                                            type="text"
                                            placeholder="भ्रमणध्वनी क्र. +९१"
                                            className="ml-5 pl-2"
                                            style={{ width: 250 }}
                                            onChange={changeHandle}
                                        /> */}
                            </div>
                          </li>
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2 mb-2">
                              Email./ईमेल<sup>*</sup>
                            </label>
                            <div className="mb-3">
                              <input
                                type="email"
                                placeholder="example@gmail.com"
                                className="pl-2"
                                name="email"
                                value={addSeller.email}
                                onChange={changeHandle}
                              />{" "}
                              {errors.email && (
                                <p style={{ color: "red" }}>{errors.email}</p>
                              )}
                              {/* <input
                                            type="text"
                                            placeholder="भ्रमणध्वनी क्र. +९१"
                                            className="ml-5 pl-2"
                                            style={{ width: 250 }}
                                            onChange={changeHandle}
                                        /> */}
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4 mt-4 text-center imag-sec">
                        <img
                          src={imgPhoto ? imgPhoto : photo}
                          style={{ width: "200px" }}
                          alt="Photograph"
                          className="border border-primary"
                        />

                        {isSuccess ? (
                          <p
                            className="success-message"
                            style={{ color: "green" }}
                          >
                            Uploaded Successfully
                          </p>
                        ) : null}

                        <p className="error-message" style={{ color: "red" }}>
                          {errorMsg}
                        </p>
                        {/* <p className="error-message" style={{ color: 'red' }}>{errorMsg}</p> */}
                        <input
                          type="file"
                          className="mt-1 mt-1-1"
                          name="userPhoto"
                          id="fileInput"
                          onChange={handleImage}
                        />
                        <label for="img">
                          Photograph <sup>*</sup>
                        </label>
                        <div className="space-between">
                          <p className="info-message">
                            (Only jpeg, jpg ,png and gif image with size 15 KB
                            to 100 KB allowed and Photo image Width and Height
                            less than 800px allowed)
                          </p>
                          {/* <p className="info-message">Max size: 5MB</p> */}
                        </div>
                        {/* <img src={addSeller?.signPhoto?.url ? addSeller?.signPhoto?.url : photo} style={{ width: '200px' }} alt="Sign" className="border border-primary" /> */}
                        <div
                          className="box box-box"
                          style={{
                            width: "200px",
                            border: "1px solid black",
                            height: "50px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={imgSign ? imgSign : ""}
                            style={{ width: "200px" }}
                            className="border border-primary"
                          />
                        </div>

                        {isSuccesssign ? (
                          <p
                            className="success-message"
                            style={{ color: "green" }}
                          >
                            Uploaded Successfully
                          </p>
                        ) : null}
                        <p className="error-message" style={{ color: "red" }}>
                          {errorMsgsign}
                        </p>
                        <input
                          type="file"
                          className="mt-1 mt-1-1"
                          name="signPhoto"
                          id="fileInput1"
                          onChange={handleImage}
                        />
                        <label for="img">
                          Upload Signature<sup>*</sup>
                        </label>
                        <div className="space-between">
                          <p className="info-message">
                            (Only jpeg, jpg ,png and gif image with size 3 KB to
                            30 KB allowed)
                          </p>
                          <p className="info-message">
                            (Only width:190px,height:50px)
                          </p>
                          {/* <p className="info-message">Max size: 5MB</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8">
                        <ul
                          type="disc "
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Gender/लिंग<sup>*</sup>
                            </label>
                            <div className="Gender">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  checked={addSeller.gender == "Male"}
                                  value={addSeller.gender}
                                  id="flexRadioDefault1"
                                  onChange={(e) => {
                                    changeHandle(e, "Male");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault1"
                                >
                                  Male/पु
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  checked={addSeller.gender == "Female"}
                                  value={addSeller.gender}
                                  id="flexRadioDefault2"
                                  onChange={(e) => {
                                    changeHandle(e, "Female");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault2"
                                >
                                  Female/स्त्री
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  checked={addSeller.gender == "Trans"}
                                  value={addSeller.gender}
                                  id="flexRadioDefault3"
                                  onChange={(e) => {
                                    changeHandle(e, "Trans");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault3"
                                >
                                  Transgender/ट्रान्सजेडर
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  checked={addSeller.gender == "Other"}
                                  value={addSeller.gender}
                                  id="flexRadioDefault4"
                                  onChange={(e) => {
                                    changeHandle(e, "Other");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault4"
                                >
                                  Other/इतर
                                </label>
                              </div>
                            </div>
                            {errors.gender && (
                              <p style={{ color: "red" }}>{errors.gender}</p>
                            )}
                          </li>
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Blood Group/रक्त गट<sup>*</sup>
                            </label>
                            <div className="Blood-Group">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="bloodGroup"
                                  checked={addSeller.bloodGroup == "O+"}
                                  value={addSeller.bloodGroup}
                                  id="flexRadioDefault5"
                                  onChange={(e) => {
                                    changeHandle(e, "O+");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault5"
                                >
                                  O+
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="bloodGroup"
                                  checked={addSeller.bloodGroup == "O-"}
                                  value={addSeller.bloodGroup}
                                  id="flexRadioDefault6"
                                  onChange={(e) => {
                                    changeHandle(e, "O-");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault6"
                                >
                                  O-
                                </label>
                              </div>
                              {/* <div className="form-check mr-2">
                                                                <input className="form-check-input" type="radio" name="bloodGroup" checked={addSeller.bloodGroup == 'A'} value={addSeller.bloodGroup} id="flexRadioDefault7" onChange={(e) => { changeHandle(e, 'A') }} />
                                                                <label className="form-check-label" htmlFor="flexRadioDefault7">
                                                                    A
                                                                </label>
                                                            </div> */}
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="bloodGroup"
                                  checked={addSeller.bloodGroup == "A+"}
                                  value={addSeller.bloodGroup}
                                  id="flexRadioDefault8"
                                  onChange={(e) => {
                                    changeHandle(e, "A+");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault8"
                                >
                                  A+
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="bloodGroup"
                                  checked={addSeller.bloodGroup == "A-"}
                                  value={addSeller.bloodGroup}
                                  id="flexRadioDefault9"
                                  onChange={(e) => {
                                    changeHandle(e, "A-");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault9"
                                >
                                  A-
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="bloodGroup"
                                  checked={addSeller.bloodGroup == "B+"}
                                  value={addSeller.bloodGroup}
                                  id="flexRadioDefault10"
                                  onChange={(e) => {
                                    changeHandle(e, "B+");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault10"
                                >
                                  B+
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="bloodGroup"
                                  checked={addSeller.bloodGroup == "B-"}
                                  value={addSeller.bloodGroup}
                                  id="flexRadioDefault11"
                                  onChange={(e) => {
                                    changeHandle(e, "B-");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault11"
                                >
                                  B-
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="bloodGroup"
                                  checked={addSeller.bloodGroup == "AB+"}
                                  value={addSeller.bloodGroup}
                                  id="flexRadioDefault12"
                                  onChange={(e) => {
                                    changeHandle(e, "AB+");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault12"
                                >
                                  AB+
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="bloodGroup"
                                  checked={addSeller.bloodGroup == "AB-"}
                                  value={addSeller.bloodGroup}
                                  id="flexRadioDefault13"
                                  onChange={(e) => {
                                    changeHandle(e, "AB-");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault13"
                                >
                                  AB-
                                </label>
                              </div>
                              {errors.bloodGroup && (
                                <p style={{ color: "red" }}>
                                  {errors.bloodGroup}
                                </p>
                              )}
                            </div>
                          </li>

                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Category/जात/प्रवर्ग <sup>*</sup>
                            </label>
                            <div className="Category-1">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="castCategory"
                                  checked={addSeller.castCategory == "General"}
                                  value={addSeller.castCategory}
                                  id="flexRadioDefault14"
                                  onChange={(e) => {
                                    changeHandle(e, "General");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault14"
                                >
                                  General
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="castCategory"
                                  checked={addSeller.castCategory == "OBC"}
                                  value={addSeller.castCategory}
                                  id="flexRadioDefault16"
                                  onChange={(e) => {
                                    changeHandle(e, "OBC");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault16"
                                >
                                  OBC
                                </label>
                              </div>

                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="castCategory"
                                  checked={addSeller.castCategory == "SC"}
                                  value={addSeller.castCategory}
                                  id="flexRadioDefault17"
                                  onChange={(e) => {
                                    changeHandle(e, "SC");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault17"
                                >
                                  SC
                                </label>
                              </div>

                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="castCategory"
                                  checked={addSeller.castCategory == "ST"}
                                  value={addSeller.castCategory}
                                  id="flexRadioDefault18"
                                  onChange={(e) => {
                                    changeHandle(e, "ST");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault18"
                                >
                                  ST
                                </label>
                              </div>

                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="castCategory"
                                  checked={addSeller.castCategory == "VJNT"}
                                  value={addSeller.castCategory}
                                  id="flexRadioDefault19"
                                  onChange={(e) => {
                                    changeHandle(e, "VJNT");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault19"
                                >
                                  VJNT
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="castCategory"
                                  checked={addSeller.castCategory == "NT-B"}
                                  value={addSeller.castCategory}
                                  id="flexRadioDefault20"
                                  onChange={(e) => {
                                    changeHandle(e, "NT-B");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault20"
                                >
                                  NT-B
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="castCategory"
                                  checked={addSeller.castCategory == "NT-C"}
                                  value={addSeller.castCategory}
                                  id="flexRadioDefault21"
                                  onChange={(e) => {
                                    changeHandle(e, "NT-C");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault21"
                                >
                                  NT-C
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="castCategory"
                                  checked={addSeller.castCategory == "NT-D"}
                                  value={addSeller.castCategory}
                                  id="flexRadioDefault22"
                                  onChange={(e) => {
                                    changeHandle(e, "NT-D");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault22"
                                >
                                  NT-D
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="castCategory"
                                  checked={addSeller.castCategory == "SBC"}
                                  value={addSeller.castCategory}
                                  id="flexRadioDefault22"
                                  onChange={(e) => {
                                    changeHandle(e, "SBC");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault22"
                                >
                                  SBC
                                </label>
                              </div>
                              {errors.castCategory && (
                                <p style={{ color: "red" }}>
                                  {errors.castCategory}
                                </p>
                              )}
                            </div>
                            <span>
                              (Attached Cast Certificate and Non-Creamy Layer
                              Certificate for OBC/SC/ST/VJNT/NT-B/NT-C/NT-D/SBC){" "}
                            </span>
                          </li>

                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Caste Certificate / जातीचा दाखला<sup>*</sup>
                            </label>
                            <div className="d-flex">
                              <input
                                type="file"
                                className="pl-2"
                                disabled={addSeller.castCategory == "General"}
                                name="castCertificate"
                                id="castCertificate"
                                onChange={handleImage}
                              />
                              (Size:10-500 KB, and Format: jpeg, jpg, png and
                              pdf)
                            </div>
                            {addSeller?.castCertificate?.url ? (
                              <p
                                className="success-message"
                                style={{ color: "green" }}
                              >
                                Uploaded Successfully{" "}
                              </p>
                            ) : null}

                            {addSeller?.castCertificate?.url && (
                              <button
                                type="button"
                                className="mt-2"
                                disabled={addSeller.castCategory == "General"}
                                onClick={() =>
                                  imgShoing(addSeller?.castCertificate?.url)
                                }
                              >
                                Show
                              </button>
                            )}
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {errorMsgcast}
                            </p>
                          </li>

                          <li className="mt-3">
                            {" "}
                            {errors.businessCategory && (
                              <p style={{ color: "red" }}>
                                {errors.businessCategory}
                              </p>
                            )}
                            <label htmlFor="" className="fw-bold mr-2">
                              Business Category/व्यवसाय श्रेणी <sup>*</sup>
                            </label>
                            <div className="d-flex">
                              <select
                                class="form-select"
                                style={{ width: "300px" }}
                                value={comboId}
                                aria-label="Default select example"
                                onChange={comboHandleChange}
                              >
                                <option>Business Category</option>
                                {combo &&
                                  combo?.map((item) => {
                                    return (
                                      <option value={item?._id}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </li>
                          {showInput && (
                            <div className="showInput">
                              <input
                                type="text"
                                name="otherBusiness"
                                id=""
                                placeholder="Please Enter Business Category"
                                value={addSeller.otherBusiness}
                                onChange={changeHandle}
                              />
                            </div>
                          )}
                          {errors.comboId && (
                            <p style={{ color: "red" }}>{errors.comboId}</p>
                          )}
                          {filterImage?.icon?.url && (
                            <div
                              style={{
                                width: "100px",
                                height: "100px",
                                marginTop: "10px",
                              }}
                            >
                              <img
                                src={filterImage?.icon?.url}
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                alt=""
                              />
                            </div>
                          )}

                          {/* <li className="mt-3" >
                                                        {" "}
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            Disability Category/दिव्यांगत्वाचे प्रमाण  <sup>*</sup>
                                                        </label>
                                                        <div className="d-flex">
                                                            <select class="form-select" style={{ width: '300px' }} name="disType" aria-label="Default select example" onChange={changeHandle}>
                                                                <option value={''}>Disability Category</option>
                                                                <option value={'Mild'}>Mild</option>
                                                                <option value={'Mild'}>Severe</option>

                                                            </select>

                                                        </div>
                                                        {errors.disType && <p style={{ color: 'red' }}>{errors.disType}</p>}
                                                    </li> */}

                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    जात/प्रवर्ग<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        सर्वसाधारण
                                    </label>
                                    <input type="checkbox" className="ml-5 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        इतर मागास वर्ग
                                    </label>
                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अनुसुचित जाती
                                    </label>
                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अनुसुचित जमाती
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विमुक्त जाती
                                    </label>

                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        भटक्या जमाती-ब
                                    </label>

                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        भटक्या जमाती क
                                    </label>

                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        भटक्या जमाती-ड
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="file" className=" " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        (जात प्रमाणपत्र अपलोड करणे)
                                    </label>
                                </div>
                            </li> */}
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Marital Status/वैवाहिक स्थिती<sup>*</sup>
                            </label>
                            <div className="Marital-Status">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="maritalStatus"
                                  checked={addSeller.maritalStatus == "Married"}
                                  value={addSeller.maritalStatus}
                                  id="flexRadioDefault24"
                                  onChange={(e) => {
                                    changeHandle(e, "Married");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault24"
                                >
                                  Married
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="maritalStatus"
                                  checked={
                                    addSeller.maritalStatus == "Unmarried"
                                  }
                                  value={addSeller.maritalStatus}
                                  id="flexRadioDefault23"
                                  onChange={(e) => {
                                    changeHandle(e, "Unmarried");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault23"
                                >
                                  Unmarried
                                </label>
                              </div>

                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="maritalStatus"
                                  checked={addSeller.maritalStatus == "Widow"}
                                  value={addSeller.maritalStatus}
                                  id="flexRadioDefault25"
                                  onChange={(e) => {
                                    changeHandle(e, "Widow");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault25"
                                >
                                  Widow
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="maritalStatus"
                                  checked={
                                    addSeller.maritalStatus == "Divorced"
                                  }
                                  value={addSeller.maritalStatus}
                                  id="flexRadioDefault26"
                                  onChange={(e) => {
                                    changeHandle(e, "Divorced");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault26"
                                >
                                  Divorced
                                </label>
                              </div>
                            </div>
                            {errors.maritalStatus && (
                              <p style={{ color: "red" }}>
                                {errors.maritalStatus}
                              </p>
                            )}
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    वैवाहिक स्थिती <sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        अविवाहित
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विवाहित{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विधव
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        घटस्फोटीत
                                    </label>
                                </div>
                            </li> */}
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              If you are married, then please provide name of
                              the Spouse/विवाहित असल्यास पतिचे/पत्नीचे संपूर्ण
                              नाव
                              <sup>*</sup>
                            </label>
                            <div className="d-flex">
                              <input
                                type="text"
                                className="pl-2"
                                disabled={
                                  addSeller.maritalStatus == "Unmarried" ||
                                  addSeller.maritalStatus == "Widow" ||
                                  addSeller.maritalStatus == "Divorced"
                                }
                                name="spouse_name"
                                value={addSeller.spouse_name}
                                onChange={changeHandle}
                              />
                              {errors.spouse_name && (
                                <p style={{ color: "red" }}>
                                  {errors.spouse_name}
                                </p>
                              )}
                            </div>
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    विवाहित असल्यास पतिचे/पत्निचे संपूर्ण नाव<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <ul
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Name of Guardian of the Applicant/Assistant/ Care
                              Taker अर्जदाराच्या पालकाचे/सहाय्यकाचे नाव
                              <sup>*</sup>
                            </label>
                            <div className="d-flex">
                              <input
                                type="text"
                                className="pl-2"
                                name="guardian_name"
                                value={addSeller.guardian_name}
                                onChange={changeHandle}
                              />
                            </div>
                            {errors.guardian_name && (
                              <p style={{ color: "red" }}>
                                {errors.guardian_name}
                              </p>
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul style={{ listStyle: "unset" }}>
                          <li className="">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              His/her Contact No<sup>*</sup>
                            </label>
                            {errors.guardian_contact && (
                              <p style={{ color: "red" }}>
                                {errors.guardian_contact}
                              </p>
                            )}
                            <div className="d-flex">
                              <input
                                type="number"
                                className="pl-2"
                                name="guardian_contact"
                                value={addSeller.guardian_contact}
                                onChange={changeHandle}
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ul style={{ listStyle: "unset" }}>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्याच्या पालकाचे/सहाय्यकाचे नाव<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li> */}
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    पालकाचा/सहायकाचा भ्रमणध्वनी क्र.<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="number" className="pl-2" placeholder="+91" />
                                </div>
                            </li> */}
                          <li className="mt-3" style={{ listStyle: "none" }}>
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Relationship of guardian with the
                              applicant/अर्जदाराचे पालकांसोबतचे नाते<sup>*</sup>
                            </label>
                            <div className="Relationship">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault27"
                                  checked={
                                    addSeller.relatedToGuardian == "Father"
                                  }
                                  name="relatedToGuardian"
                                  value={addSeller.relatedToGuardian}
                                  onChange={(e) => {
                                    changeHandle(e, "Father");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault27"
                                >
                                  Father
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault28"
                                  name="relatedToGuardian"
                                  checked={
                                    addSeller.relatedToGuardian == "Mother"
                                  }
                                  value={addSeller.relatedToGuardian}
                                  onChange={(e) => {
                                    changeHandle(e, "Mother");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault28"
                                >
                                  Mother{" "}
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault29"
                                  name="relatedToGuardian"
                                  checked={
                                    addSeller.relatedToGuardian == "Wife"
                                  }
                                  value={addSeller.relatedToGuardian}
                                  onChange={(e) => {
                                    changeHandle(e, "Wife");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault29"
                                >
                                  Wife
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault30"
                                  name="relatedToGuardian"
                                  checked={
                                    addSeller.relatedToGuardian == "Husband"
                                  }
                                  value={addSeller.relatedToGuardian}
                                  onChange={(e) => {
                                    changeHandle(e, "Husband");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault30"
                                >
                                  Husband
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault31"
                                  name="relatedToGuardian"
                                  checked={
                                    addSeller.relatedToGuardian == "Other"
                                  }
                                  value={addSeller.relatedToGuardian}
                                  onChange={(e) => {
                                    changeHandle(e, "Other");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault31"
                                >
                                  Other
                                </label>
                              </div>
                            </div>
                            {errors.relatedToGuardian && (
                              <p style={{ color: "red" }}>
                                {errors.relatedToGuardian}
                              </p>
                            )}
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्यासोबतचे नाते<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        वडिल
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        आई{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पति
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पत्नि
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        इतर
                                    </label>
                                </div>
                            </li> */}
                          <li className="mt-3" style={{ listStyle: "none" }}>
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Educational Details/शैक्षणिक अर्हता<sup>*</sup>
                            </label>
                            <div className="Educational">
                              <input
                                type="checkbox"
                                className="seller-checkbox "
                                id="Primary"
                                name="primary"
                                checked={education.primary}
                                value={education.primary}
                                onChange={(e) => {
                                  handleChange(e, !education.primary);
                                }}
                              />
                              <label htmlFor="Primary" className="ml-1  ">
                                Primary
                              </label>
                              <input
                                type="checkbox"
                                className="ml-3 seller-checkbox  "
                                id="Secondary"
                                name="secondary"
                                checked={education.secondary}
                                value={education.secondary}
                                onChange={(e) => {
                                  handleChange(e, !education.secondary);
                                }}
                              />
                              <label
                                htmlFor="Secondary"
                                className="ml-1 seller-label"
                              >
                                Secondary{" "}
                              </label>
                              <input
                                type="checkbox"
                                className="ml-3 seller-checkbox "
                                id="Higher Secondary"
                                name="higher_secondary"
                                checked={education.higher_secondary}
                                value={education.higher_secondary}
                                onChange={(e) => {
                                  handleChange(e, !education.higher_secondary);
                                }}
                              />
                              <label
                                htmlFor="Higher Secondary"
                                className="ml-1 seller-label"
                              >
                                Higher Secondary
                              </label>
                              <input
                                type="checkbox"
                                className="ml-3 seller-checkbox "
                                id="Diploma"
                                name="diploma"
                                checked={education.diploma}
                                value={education.diploma}
                                onChange={(e) => {
                                  handleChange(e, !education.diploma);
                                }}
                              />
                              <label
                                htmlFor="Diploma"
                                className="ml-1 seller-label"
                              >
                                Diploma
                              </label>
                              <input
                                type="checkbox"
                                className="ml-3 seller-checkbox "
                                id="Graduate"
                                name="graduate"
                                checked={education.graduate}
                                value={education.graduate}
                                onChange={(e) => {
                                  handleChange(e, !education.graduate);
                                }}
                              />
                              <label
                                htmlFor="Graduate"
                                className="ml-1 seller-label"
                              >
                                Graduate
                              </label>
                            </div>
                            <div className="d-flex mt-3">
                              <input
                                type="checkbox"
                                className=" seller-checkbox "
                                id="Postgraduate"
                                name="post_graduate"
                                checked={education.post_graduate}
                                value={education.post_graduate}
                                onChange={(e) => {
                                  handleChange(e, !education.post_graduate);
                                }}
                              />
                              <label
                                htmlFor="Postgraduate"
                                className="ml-1 seller-label"
                              >
                                Postgraduate
                              </label>
                              <input
                                type="checkbox"
                                className="ml-3 seller-checkbox "
                                id="Uneducated"
                                name="unGraduate"
                                checked={education.unGraduate}
                                value={education.unGraduate}
                                onChange={(e) => {
                                  handleChange(e, !education.unGraduate);
                                }}
                              />
                              <label
                                htmlFor="Uneducated"
                                className="ml-1 seller-label"
                              >
                                Uneducated
                              </label>
                            </div>
                            {customError && (
                              <p style={{ color: "red" }}>
                                Educational Details is Required!
                              </p>
                            )}
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    शैक्षणिक अर्हता<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        प्राथमिक
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        माध्यमिक{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        उच्च माध्यमिक
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        डिप्लोमा
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पदवीधर
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पदव्युत्तर
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अशिक्षित
                                    </label>
                                </div>
                            </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4 padding-class">
                      <h6 style={{ fontWeight: "800" }}>
                        Address Details/ निवासाचे तपशिल
                      </h6>
                      <hr className="border border-1 border-primary m-0 p-0" />
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ul
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Are you being resident of Maharashtra State?/आपण
                              महाराष्ट्र राज्याचे रहिवासी आहात का? <sup>*</sup>
                            </label>
                            <div className="d-flex">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault32"
                                  name="residentOf"
                                  checked={addSeller.residentOf == "Yes"}
                                  value={addSeller.residentOf}
                                  onChange={(e) => {
                                    changeHandle(e, "Yes");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault32"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault33"
                                  name="residentOf"
                                  checked={addSeller.residentOf == "No"}
                                  value={addSeller.residentOf}
                                  onChange={(e) => {
                                    changeHandle(e, "No");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault33"
                                >
                                  No
                                </label>
                              </div>
                              <span className="ml-3">
                                (If Yes Please Upload Domicile Certificate)
                              </span>
                            </div>
                            {errors.residentOf && (
                              <p style={{ color: "red" }}>
                                {errors.residentOf}
                              </p>
                            )}
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    आपण महाराष्ट्र राज्याचे रहिवासी आहत का?
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        होय{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        नाही
                                    </label>
                                    <span className="ml-3">
                                        (असल्यास अधिवास प्रमाणपत्र अपलोड करावे)
                                    </span>
                                </div>
                            </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ul
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Domacile Certificate/अधिवास प्रमाणपत्र<sup>*</sup>
                            </label>
                            <div className="d-flex">
                              <input
                                type="file"
                                id="domacileCert"
                                className="pl-2"
                                name="domacileCert"
                                onChange={handleImage}
                              />
                              (Size:10-500 KB, and Format: jpeg, jpg, png and
                              pdf)
                              {addSeller?.domacileCert?.url ? (
                                <p
                                  className="success-message"
                                  style={{ color: "green" }}
                                >
                                  Uploaded Successfully{" "}
                                </p>
                              ) : null}
                              {addSeller?.domacileCert?.url ? (
                                <button
                                  type="button"
                                  className="mt-2"
                                  onClick={() =>
                                    imgShoing(addSeller?.domacileCert?.url)
                                  }
                                >
                                  Show
                                </button>
                              ) : (
                                ""
                              )}
                              {/* {isSuccessdomail ? <p className="success-message" style={{ color: 'green' }}>Upload Successfully <button onClick={() => imgShoing(addSeller?.domacileCert?.url)}>Show</button></p> : null} */}
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {errorMsgdomail}
                              </p>
                              {/* <span>
                                                                (कागदपत्रे जेपीईजी/पीडीफ स्वरूपातच असावा. कागदपत्राचा आकार
                                                                किमान ७५ केबी आणि कमाल २५६ केबी इतकी असावी)
                                                            </span> */}
                            </div>
                          </li>
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Correspondence Address / सध्याचा पत्ता<sup>*</sup>
                            </label>
                            <div className="Correspondence">
                              <textarea
                                name="coresAddress"
                                id=""
                                cols="80"
                                rows="2"
                                className="pl-2"
                                value={addSeller.coresAddress}
                                onChange={changeHandle}
                              ></textarea>
                              {errors.coresAddress && (
                                <p style={{ color: "red" }}>
                                  {errors.coresAddress}
                                </p>
                              )}
                            </div>
                          </li>
                        </ul>
                        <div className="Village-sec ml-5 Village-sec-2">
                          <div className="row">
                            <div className="col-md-4">
                              <li
                                className="mt-3"
                                style={{ listStyleType: "none" }}
                              >
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                  Post / पोस्ट<sup>*</sup>
                                </label>
                                {errors.post && (
                                  <p style={{ color: "red" }}>
                                    {errors.post}
                                  </p>
                                )}
                                <div className="d-flex">
                                  <input
                                    type="text"
                                    className="pl-2"
                                    name="post"
                                    value={addSeller.post}
                                    onChange={changeHandle}
                                  />
                                </div>
                                {/* {errors.state && <p style={{ color: 'red' }}>{errors.state}</p>} */}
                              </li>
                            </div>
                            <div className="col-md-4">
                              <li
                                className="mt-3"
                                style={{ listStyleType: "none" }}
                              >
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                  State / राज्य<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                  <input
                                    type="text"
                                    className="pl-2"
                                    name="state"
                                    value={addSeller.state}
                                    onChange={changeHandle}
                                  />
                                </div>
                                {errors.state && (
                                  <p style={{ color: "red" }}>{errors.state}</p>
                                )}
                              </li>
                            </div>
                            <div className="col-md-4">
                              <li
                                className=""
                                style={{ listStyleType: "none" }}
                              >
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                  Division/विभाग<sup>*</sup>
                                </label>
                                {errors.division && (
                                  <p style={{ color: "red" }}>
                                    {errors?.division}
                                  </p>
                                )}
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  value={vlueDistric}
                                  onChange={divisonChange}
                                >
                                  <option selected>
                                    Open this select menu
                                  </option>
                                  {divisonn &&
                                    divisonn?.map((item) => {
                                      return (
                                        <option value={item?.uid}>
                                          {item?.title}
                                        </option>
                                      );
                                    })}
                                </select>
                                {errors.divisonn && (
                                  <p style={{ color: "red" }}>
                                    {errors.divisonn}
                                  </p>
                                )}
                                {/* <div className="d-flex">
                                                            <input type="text" className="pl-2" name="district" value={addSeller.district} onChange={changeHandle} />
                                                        </div> */}
                              </li>
                            </div>
                            <div className="col-lg-4">
                              <li
                                className=""
                                style={{ listStyleType: "none" }}
                              >
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                  District/जिल्हा<sup>*</sup>
                                </label>
                                {errors.district && (
                                  <p style={{ color: "red" }}>
                                    {errors.district}
                                  </p>
                                )}
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  value={addSeller.district}
                                  name="district"
                                  onChange={changeHandle}
                                >
                                  <option selected>
                                    Open this select menu
                                  </option>
                                  {districMaha &&
                                    districMaha?.map((item) => {
                                      return (
                                        <option value={item?.uid}>
                                          {item?.title}
                                        </option>
                                      );
                                    })}
                                </select>
                                {/* <div className="d-flex">
                                                            <input type="text" className="pl-2" name="district" value={addSeller.district} onChange={changeHandle} />
                                                        </div> */}
                              </li>
                            </div>
                            <div className="col-md-4">
                              <li
                                className=""
                                style={{ listStyleType: "none" }}
                              >
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                  Block/तालुका<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                  <input
                                    type="text"
                                    className="pl-2"
                                    name="block"
                                    value={addSeller.block}
                                    onChange={changeHandle}
                                  />
                                </div>
                                {errors.block && (
                                  <p style={{ color: "red" }}>{errors.block}</p>
                                )}
                              </li>
                            </div>
                            <div className="col-md-4">
                              <li
                                className=""
                                style={{ listStyleType: "none" }}
                              >
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                  Village/गांव<sup>*</sup>
                                </label>
                                <div className="Village">
                                  <input
                                    type="text"
                                    className="pl-2"
                                    name="village"
                                    value={addSeller.village}
                                    onChange={changeHandle}
                                  />
                                </div>
                                {errors.village && (
                                  <p style={{ color: "red" }}>
                                    {errors.village}
                                  </p>
                                )}
                              </li>
                            </div>

                            <div className="col-md-4">
                              <li
                                className=""
                                style={{ listStyleType: "none" }}
                              >
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                  Pincode / पिनकोड<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                  {/* <input
                                    type="number"
                                    className="pl-2"
                                    name="pincode"
                                    max={7}
                                    value={addSeller.pincode}
                                    onChange={changeHandle}
                                  /> */}
                                  <input
                                    type="number"
                                    className="pl-2"
                                    name="inputValue"
                                    value={inputValue}
                                    onChange={handleInputChangee}
                                  />
                                </div>
                                {errors.pincode && (
                                  <p style={{ color: "red" }}>
                                    {errors.pincode}
                                  </p>
                                )}
                              </li>
                            </div>

                            <ul style={{ listStyle: "unset" }}>
                              <li
                                className="mt-3"
                                style={{ listStyleType: "none" }}
                              >
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                  Permanent Address / कायमचा पत्ता<sup>*</sup>
                                </label>
                                <div className="Permanent">
                                  <textarea
                                    name="permanentAddress"
                                    id=""
                                    cols="80"
                                    rows="2"
                                    className="pl-2"
                                    value={addSeller.permanentAddress}
                                    onChange={changeHandle}
                                  ></textarea>
                                  <button
                                    type="button"
                                    style={{
                                      height: "30px",
                                      marginLeft: "10px",
                                      border: "none",
                                      borderRadius: "6px",
                                    }}
                                    onClick={sameAsAbove}
                                  >
                                    Same as above
                                  </button>
                                </div>
                                {errors.permanentAddress && (
                                  <p style={{ color: "red" }}>
                                    {errors.permanentAddress}
                                  </p>
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ul
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Documents of Address Proof/ निवासी असल्याचा पुरावा
                              <sup>*</sup>
                            </label>
                            <div className="Muscular-1 Muscular-2 d-flex">
                              <input
                                type="checkbox"
                                className="seller-checkbox "
                                name="driveLicen"
                                checked={docAddressProfe.driveLicen}
                                value={docAddressProfe.driveLicen}
                                onChange={(e) => {
                                  AddressHandle(e, !docAddressProfe.driveLicen);
                                }}
                              />
                              <label
                                htmlFor="Driving License"
                                className="ml-1 seller-label"
                              >
                                Driving License
                              </label>
                              <input
                                type="checkbox"
                                className="ml-3 seller-checkbox  "
                                id="Ration Card"
                                name="rationCard"
                                checked={docAddressProfe.rationCard}
                                value={docAddressProfe.rationCard}
                                onChange={(e) => {
                                  AddressHandle(e, !docAddressProfe.rationCard);
                                }}
                              />
                              <label
                                htmlFor="Ration Card"
                                className="ml-1 seller-label"
                              >
                                Ration Card
                              </label>
                              <input
                                type="checkbox"
                                className=" ml-3 seller-checkbox "
                                id="Voter Id"
                                name="voterId"
                                checked={docAddressProfe.voterId}
                                value={docAddressProfe.voterId}
                                onChange={(e) => {
                                  AddressHandle(e, !docAddressProfe.voterId);
                                }}
                              />
                              <label htmlFor="Voter Id" className="ml-1  ">
                                Voter Id
                              </label>
                              <input
                                type="checkbox"
                                className="ml-3 seller-checkbox  "
                                id="Passport"
                                name="passport"
                                checked={docAddressProfe.passport}
                                value={docAddressProfe.passport}
                                onChange={(e) => {
                                  AddressHandle(e, !docAddressProfe.passport);
                                }}
                              />
                              <label
                                htmlFor="Passport"
                                className="ml-1 seller-label"
                              >
                                Passport{" "}
                              </label>
                            </div>
                            <div className="d-flex mt-3 Muscular-2">
                              <input
                                type="checkbox"
                                className=" seller-checkbox "
                                id="Electricity Bill"
                                name="electricBill"
                                checked={docAddressProfe.electricBill}
                                value={docAddressProfe.electricBill}
                                onChange={(e) => {
                                  AddressHandle(
                                    e,
                                    !docAddressProfe.electricBill
                                  );
                                }}
                              />
                              <label
                                htmlFor="Electricity Bill"
                                className="ml-1  seller-label"
                              >
                                Electricity Bill
                              </label>
                              <input
                                type="checkbox"
                                className="ml-3 seller-checkbox  "
                                id="Property Tax Receipt"
                                name="properTaxRec"
                                checked={docAddressProfe.properTaxRec}
                                value={docAddressProfe.properTaxRec}
                                onChange={(e) => {
                                  AddressHandle(
                                    e,
                                    !docAddressProfe.properTaxRec
                                  );
                                }}
                              />
                              <label
                                htmlFor="Property Tax Receipt"
                                className="ml-1 seller-label"
                              >
                                Property Tax Receipt
                              </label>
                            </div>
                            {customAddress && (
                              <p style={{ color: "red" }}>
                                Documents of Address Proof is Required!
                              </p>
                            )}
                          </li>
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Address Proof / पत्त्याचा पुरावा<sup>*</sup>
                              {addSeller?.addressProof?.url ? (
                                <p
                                  className="success-message"
                                  style={{ color: "green" }}
                                >
                                  Uploaded Successfully{" "}
                                </p>
                              ) : null}
                              {addSeller?.addressProof?.url ? (
                                <button
                                  type="button"
                                  className="mt-2"
                                  onClick={() =>
                                    imgShoing(addSeller?.addressProof?.url)
                                  }
                                >
                                  Show
                                </button>
                              ) : (
                                ""
                              )}
                              {/* {isSuccessAddress ? <p className="success-message" style={{ color: 'green' }}>Upload Successfully <button onClick={() => imgShoing(addSeller?.addressProof?.url)}>Show</button></p> : null} */}
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {errorMsgAddress}
                              </p>
                            </label>
                            <div className="d-flex">
                              <input
                                type="file"
                                id="addressProo"
                                className="pl-2"
                                name="addressProof"
                                onChange={handleImage}
                              />
                              (Size:10-500 KB, and Format: jpeg, jpg, png and
                              pdf)
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4 padding-class">
                      <h6>Disability Details / दिव्यांगाची माहिती</h6>
                      <hr className="border border-1 border-primary m-0 p-0" />
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ul
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Do You have Disability Certificate /आपल्याकडे
                              दिव्यांगत्व प्रमाणपत्र आहे का<sup>*</sup>
                            </label>
                            {DisabilityCertificate && <p style={{ color: 'red' }}> Do You have Disability Certificate is Requried</p>}
                            <div className="d-flex">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flex"
                                  name="disabilityCert"
                                  value={addSeller.disabilityCert}
                                  onChange={(e) => {
                                    changeHandle(e, true);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flex1"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flex2"
                                  name="disabilityCert"
                                  value={addSeller.disabilityCert}
                                  onChange={(e) => {
                                    changeHandle(e, false);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flex2"
                                >
                                  No
                                </label>
                              </div>
                              <span className="ml-3">
                                {" "}
                                (If Yes, Please fill in the following Details &
                                attach disability certificate)
                              </span>
                              {errors.disabilityCert && (
                                <p style={{ color: "red" }}>
                                  {errors.disabilityCert}
                                </p>
                              )}
                            </div>
                          </li>
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Disability Certificate/दिव्यांगत्वाचे प्रमाणपत्र
                              <sup>*</sup>
                              {isSuccessdiability ? (
                                <p
                                  className="success-message"
                                  style={{ color: "green" }}
                                >
                                  Uploaded Successfully{" "}
                                </p>
                              ) : null}
                              {addSeller?.disabilityCertImage?.url && (
                                <button
                                  type="button"
                                  className="mt-2"
                                  onClick={() =>
                                    imgShoing(
                                      addSeller?.disabilityCertImage?.url
                                    )
                                  }
                                >
                                  Show
                                </button>
                              )}
                              {/* {isSuccessdiability ? <p className="success-message" style={{ color: 'green' }}>Upload Successfully  <button onClick={() => imgShoing(addSeller?.disabilityCertImage?.url)}>Show</button></p> : null} */}
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {errorMsgdiability}
                              </p>
                            </label>
                            <div className="d-flex">
                              <input
                                type="file"
                                id="fileInput2"
                                className="pl-2"
                                name="disabilityCertImage"
                                onChange={handleImage}
                              />
                              (Size:10-500 KB, and Format: jpeg, jpg, png and
                              pdf)
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="col-md-12">
                        <ul
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Do You have UDID Number<sup>*</sup>
                            </label>
                            {errors.udidType && (
                              <p style={{ color: "red" }}>
                                {errors.udidType}
                              </p>
                            )}
                            <div className="d-flex">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flex8 udidType"
                                  name="udidType"
                                  checked={
                                    addSeller.udidType == "Permanent UDID"
                                  }
                                  value={addSeller.udidType}
                                  onChange={(e) => {
                                    changeHandle(e, "Permanent UDID");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flex8"
                                >
                                  Permanent UDID /कायमस्वरूपी UDID
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flex6"
                                  name="udidType"
                                  checked={
                                    addSeller.udidType == "Temporary UDID"
                                  }
                                  value={addSeller.udidType}
                                  onChange={(e) => {
                                    changeHandle(e, "Temporary UDID");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flex6"
                                >
                                  Temporary UDID /तात्पुरता UDID
                                </label>
                              </div>
                              {/* <span className="ml-3">
                                                                {" "}
                                                                (If Yes, Please fill in the following Details & attach
                                                                disability certificate)
                                                            </span>
                                                            {errors.disabilityCert && <p style={{ color: 'red' }}>{errors.disabilityCert}</p>} */}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <ul
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              UDID Certificate No/UDID प्रमाणपत्र क्र{" "}
                              <sup>*</sup>
                            </label>
                            <div className="d-flex">
                              <input
                                type="text"
                                className="pl-2"
                                name="udId_No"
                                value={addSeller.udId_No}
                                onChange={changeHandle}
                              />
                            </div>
                            {errors.udId_No && (
                              <p style={{ color: "red" }}>{errors.udId_No}</p>
                            )}
                          </li>

                          <li className="">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              UDID Certificate/UDID प्रमाणपत्र <sup>*</sup>
                              {addSeller?.udId_File?.url ? (
                                <p
                                  className="success-message"
                                  style={{ color: "green" }}
                                >
                                  Uploaded Successfully{" "}
                                </p>
                              ) : null}
                              {addSeller?.udId_File?.url && (
                                <button
                                  type="button"
                                  className="mt-2"
                                  onClick={() =>
                                    imgShoing(addSeller?.udId_File?.url)
                                  }
                                >
                                  Show
                                </button>
                              )}
                              {/* {isSuccessUdidCetifi ? <p className="success-message" style={{ color: 'green' }}>Upload Successfully <button onClick={() => imgShoing(addSeller?.udId_File?.url)}>Show</button></p> : null} */}
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {errorUdidCetifi}
                              </p>
                            </label>
                            <div className="d-flex">
                              <input
                                type="file"
                                id="fileInput3"
                                className="pl-2"
                                name="udId_File"
                                onChange={handleImage}
                              />
                              (Size:10-500 KB, and Format: jpeg, jpg, png and
                              pdf)
                            </div>
                          </li>
                        </ul>
                      </div>
                      {/* <div className="col-md-6">
                                                <ul style={{ listStyle: "unset" }}>
                                                    <li className="">
                                                        {" "}
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            Date of issue/प्रमाणपत्र दिल्याचा दिनांक <sup>*</sup>
                                                        </label>
                                                        <div className="d-flex">
                                                            <input type="date" className="pl-2" />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div> */}
                      <div className="col-md-6">
                        {/* <ul style={{ listStyle: "unset" }}>
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        UDID प्रमाणपत्र क्र<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </ul> */}
                      </div>
                      <div className="col-md-6">
                        {/* <ul style={{ listStyle: "unset" }}>
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        प्रमाणपत्र दिल्याचा दिनांक<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="date" className="pl-2" />
                                    </div>
                                </li>
                            </ul> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ul
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Percentage of disability (40% or more than
                              40%)/दिव्यांगत्व प्रमाण (किमान ४०% अथवा त्यापेक्षा
                              अधिक) <sup>*</sup>
                            </label>
                            <div className="d-flex">
                              <input
                                type="number"
                                className="pl-2"
                                name="PerOfDis"
                                min="40"
                                max="100"
                                value={addSeller.PerOfDis}
                                onChange={changeHandle}
                              />
                            </div>
                            {errors.PerOfDis && (
                              <p style={{ color: "red" }}>{errors.PerOfDis}</p>
                            )}
                          </li>
                          {/* <li className="mt-3">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        दिव्यांगत्व प्रमाण (किमान ४०% अथवा त्यापेक्षा अधिक)
                                        <sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li> */}
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Types Of Disability/दिव्यांगत्वाचा प्रकार
                              <sup>*</sup>
                            </label>
                            {customDisability && (
                              <p style={{ color: "red" }}>
                                Types Of Disability is Required!
                              </p>
                            )}
                            <div className="Disability">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="locoMotor"
                                  id="Locomotors Disability"
                                  checked={tOfDisability.locoMotor}
                                  value={tOfDisability.locoMotor}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.locoMotor
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Locomotors Disability"
                                >
                                  Locomotors Disability/अस्थिव्यंग
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="leprosy"
                                  id="Leprosy Cured persons"
                                  checked={tOfDisability.leprosy}
                                  value={tOfDisability.leprosy}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.leprosy
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Leprosy Cured persons"
                                >
                                  Leprosy Cured persons/कुष्ठरोग निवारीत मुक्त
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="cerebralPal"
                                  id="Cerebral Palsy"
                                  checked={tOfDisability.cerebralPal}
                                  value={tOfDisability.cerebralPal}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.cerebralPal
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Cerebral Palsy"
                                >
                                  Cerebral Palsy/मेंदूचा पक्षाघात
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="dwarfism"
                                  id="Dwarfism"
                                  checked={tOfDisability.dwarfism}
                                  value={tOfDisability.dwarfism}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.dwarfism
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Dwarfism"
                                >
                                  Dwarfism /शारीरिक वाढ खुंटणे
                                </label>
                              </div>
                            </div>
                            <div className="Muscular-1 d-flex mt-3">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="muscular_dy"
                                  id="Muscular Dystrophy"
                                  checked={tOfDisability.muscular_dy}
                                  value={tOfDisability.muscular_dy}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.muscular_dy
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Muscular Dystrophy"
                                >
                                  Muscular Dystrophy/स्नायुंची विकृती
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="acidAttactVic"
                                  id="Acid Attack victim"
                                  checked={tOfDisability.acidAttactVic}
                                  value={tOfDisability.acidAttactVic}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.acidAttactVic
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Acid Attack victim"
                                >
                                  Acid Attack victim/आम्ल हल्ला पीडित
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="blindness"
                                  id="Blindness"
                                  checked={tOfDisability.blindness}
                                  value={tOfDisability.blindness}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.blindness
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Blindness"
                                >
                                  Blindness/पुर्णतः अंध
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="lowVision"
                                  id="Low-vision"
                                  checked={tOfDisability.lowVision}
                                  value={tOfDisability.lowVision}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.lowVision
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Low-vision"
                                >
                                  Low-vision/अंशतः अंध (दृष्टिदोष)
                                </label>
                              </div>
                            </div>
                            <div className="Muscular-1 d-flex mt-3">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="hearingImp"
                                  id="Hearing Impairment"
                                  checked={tOfDisability.hearingImp}
                                  value={tOfDisability.hearingImp}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.hearingImp
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Hearing Impairment"
                                >
                                  Hearing Impairment (Deaf and hard of
                                  Hearing)/कर्णबधीर / ऐकू कमी येणे
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="speechNlang"
                                  id="Speech"
                                  checked={tOfDisability.speechNlang}
                                  value={tOfDisability.speechNlang}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.speechNlang
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Speech"
                                >
                                  Speech and Language disability/वाचा / भाषा दोष
                                </label>
                              </div>
                            </div>
                            <div className="Muscular-1 d-flex mt-3">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="intellect"
                                  id="Intellectual Disability"
                                  checked={tOfDisability.intellect}
                                  value={tOfDisability.intellect}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.intellect
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Intellectual Disability"
                                >
                                  Intellectual Disability/बौद्धिक अक्षम
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="specific_learn"
                                  id="Specific"
                                  checked={tOfDisability.specific_learn}
                                  value={tOfDisability.specific_learn}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.specific_learn
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Specific"
                                >
                                  Specific Learning Disabilities/विशिष्ट अध्ययन
                                  अक्षम
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="autism"
                                  id="Autism"
                                  checked={tOfDisability.autism}
                                  value={tOfDisability.autism}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.autism
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Autism"
                                >
                                  Autism Spectrum Disorder/स्वमग्न
                                </label>
                              </div>
                            </div>
                            <div className="Muscular-1 d-flex mt-3">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="mentalBehave"
                                  id="Mental"
                                  checked={tOfDisability.mentalBehave}
                                  value={tOfDisability.mentalBehave}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.mentalBehave
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Mental"
                                >
                                  Mental Behavior/Mental illness/मानसिक वर्तन /
                                  मानसिक आधार
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="multiScler"
                                  id="Multiple"
                                  checked={tOfDisability.multiScler}
                                  value={tOfDisability.multiScler}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.multiScler
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Multiple"
                                >
                                  Multiple Sclerosis/हातापायातील स्नायू कमजोर /
                                  शिथिल होणे
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="parkinson"
                                  id="Parkinson"
                                  checked={tOfDisability.parkinson}
                                  value={tOfDisability.parkinson}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.parkinson
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Parkinson"
                                >
                                  Parkinson's disease/कंपावात
                                </label>
                              </div>
                            </div>
                            <div className="Muscular-1 d-flex mt-3">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="hemoPhilia"
                                  id="Hemophilia"
                                  checked={tOfDisability.hemoPhilia}
                                  value={tOfDisability.hemoPhilia}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.hemoPhilia
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Hemophilia"
                                >
                                  Hemophilia/अधिक रक्तस्त्राव
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="thalassemia"
                                  id="Thalassemia"
                                  checked={tOfDisability.thalassemia}
                                  value={tOfDisability.thalassemia}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.thalassemia
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Thalassemia"
                                >
                                  Thalassemia/रक्ताची कमतरता
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="sickieCell"
                                  id="Sickle"
                                  checked={tOfDisability.sickieCell}
                                  value={tOfDisability.sickieCell}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.sickieCell
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Sickle"
                                >
                                  Sickle Cell disease/रक्ताचे हिमोग्लोबीनचे
                                  प्रमाण कमी होणे
                                </label>
                              </div>
                            </div>
                            <div className="Muscular-1 d-flex mt-3">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="chronicNeu"
                                  id="Chronic"
                                  checked={tOfDisability.chronicNeu}
                                  value={tOfDisability.chronicNeu}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.chronicNeu
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Chronic"
                                >
                                  Chronic Neurological conditions/मज्जासंस्थेचे
                                  तीव्र आजार
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="multipleDisab"
                                  id="Multiple"
                                  checked={tOfDisability.multipleDisab}
                                  value={tOfDisability.multipleDisab}
                                  onChange={(e) => {
                                    tOfDisabilityChange(
                                      e,
                                      !tOfDisability.multipleDisab
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault56"
                                >
                                  Multiple Disabilities/बहुविकलांग
                                </label>
                              </div>
                            </div>
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    दिव्यांगत्वाचा प्रकार<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अस्थिव्यंग
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        कुष्ठरोग निवारीत मुक्त अंशतः अंध (दृष्टिदोष)
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        मेंदूचा पक्षाघात
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        शारीरिक वाढ खुंटणे{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        स्नायुंची विकृती
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        आम्ल हल्ला पीडित
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        पुर्णतः अंध
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अंशतः अंध
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        कर्णबधीर / ऐकू कमी येणे
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        वाचा / भाषा दोष
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        बौद्धिक अक्षम
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विशिष्ट अध्ययन अक्षम
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        स्वमग्न
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        मानसिक वर्तन/मानसिक आधार
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        हातापायातील स्नायू कमजोर / शिथिल होणे
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        कंपावात
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        अधिक रक्तस्त्राव
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        {" "}
                                        रक्ताची कमतरता
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        रक्ताचे हिमोग्लोबीनचे प्रमाण कमी होणे
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className="seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        मज्जासंस्थेचे तीव्र आजार
                                    </label>

                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        बहुविकलांग
                                    </label>
                                </div>
                            </li> */}
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Do you have Disability by Birth/तुम्हाला जन्मतःच
                              दिव्यांगत्व आहे का<sup>*</sup>
                            </label>
                            {/* {DisabilitybyBirth && <p style={{ color: 'red' }}> Do you have Disability by Birth is Required !</p>} */}
                            {/* {DisabilityCertificate && <p style={{ color: 'red' }}> Do You have Disability Certificate is Requried</p>} */}

                            <div className="have-Disability">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault57"

                                  name="disabilitiesBirth"
                                  value={disabilitiesBirth}
                                  onChange={(e) => {
                                    disabtlyBirth(e, !disabilitiesBirth);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault57"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault58"

                                  name="disabilitiesBirth"
                                  value={disabilitiesBirth}
                                  onChange={(e) => {
                                    disabtlyBirth(e, !disabilitiesBirth);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault58"
                                >
                                  No
                                </label>
                              </div>
                              <label htmlFor="" className="ml-5 seller-label">
                                If No then Disability Since
                              </label>
                              <input
                                type="date"
                                className="ml-3"
                                disabled={disabilitiesBirth === true}
                                name="disabtlySince"
                                value={addSeller.disabtlySince}
                                onChange={changeHandle}
                              />
                            </div>
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    तुम्हाला जन्मतःच दिव्यांगत्व आहे का होय<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        होय{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        नाही{" "}
                                    </label>
                                    <label htmlFor="" className="ml-5 seller-label">
                                        जर होय तर दिव्यांगत्व कधीपासून आहे
                                    </label>
                                    <input type="date" className="ml-3" />
                                </div>
                            </li> */}
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Area of Disability/दिव्यांगत्व क्षेत्र<sup>*</sup>
                            </label>
                            {/* {areaofDisability && <p style={{ color: 'red' }}>Area of Disability is Required !</p>} */}
                            {errors.areaOfDisable && (
                              <p style={{ color: "red" }}>{errors.areaOfDisable}</p>
                            )}
                            <div className="Area-of-Disability">
                              <textarea
                                name="areaOfDisable"
                                id=""
                                value={addSeller.areaOfDisable}
                                cols="100"
                                rows="3"
                                onChange={changeHandle}
                              ></textarea>

                              {/* <input type="checkbox" className="seller-checkbox " name="chest" value={aOfDisabality.chest} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.chest) }} />
                                                            <label htmlFor="" className="ml-1 seller-label">
                                                                Chest
                                                            </label>
                                                            <input type="checkbox" className="ml-3 seller-checkbox  " name="ears" value={aOfDisabality.ears} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.ears) }} />
                                                            <label htmlFor="" className="ml-1 seller-label">
                                                                {" "}
                                                                Ears
                                                            </label>
                                                            <input type="checkbox" className=" ml-3 seller-checkbox " name="leftEye" value={aOfDisabality.leftEye} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.leftEye) }} />
                                                            <label htmlFor="" className="ml-1  seller-label">
                                                                Left Eye
                                                            </label>
                                                            <input type="checkbox" className=" ml-3 seller-checkbox " name="leftHand" value={aOfDisabality.leftHand} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.leftHand) }} />
                                                            <label htmlFor="" className="ml-1  seller-label">
                                                                Left Hand
                                                            </label>
                                                            <input type="checkbox" className=" ml-3 seller-checkbox " name="leftLeg" value={aOfDisabality.leftLeg} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.leftLeg) }} />
                                                            <label htmlFor="" className="ml-1  seller-label">
                                                                Left Leg
                                                            </label>
                                                            <input type="checkbox" className=" ml-3 seller-checkbox " name="mouth" value={aOfDisabality.mouth} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.mouth) }} />
                                                            <label htmlFor="" className="ml-1  seller-label">
                                                                Mouth
                                                            </label> */}
                            </div>
                            {/* <div className="Area-of-Disability mt-3">
                                                            <input type="checkbox" className=" seller-checkbox  " name="nois" value={aOfDisabality.nois} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.nois) }} />
                                                            <label htmlFor="" className="ml-1 seller-label">
                                                                Nois
                                                            </label>
                                                            <input type="checkbox" className="ml-3 seller-checkbox " name="shoulder" value={aOfDisabality.shoulder} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.shoulder) }} />
                                                            <label htmlFor="" className="ml-1 seller-label ">
                                                                Shoulder
                                                            </label>
                                                            <input type="checkbox" className="ml-3 seller-checkbox  " name="throat" value={aOfDisabality.throat} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.throat) }} />
                                                            <label htmlFor="" className="ml-1 seller-label">
                                                                Throat
                                                            </label>
                                                            <input type="checkbox" className="ml-3 seller-checkbox " name="rightEye" value={aOfDisabality.rightEye} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.rightEye) }} />
                                                            <label htmlFor="" className="ml-1  seller-label">
                                                                Right Eye
                                                            </label>
                                                            <input type="checkbox" className="ml-3 seller-checkbox " name="rightLeg" value={aOfDisabality.rightLeg} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.rightLeg) }} />
                                                            <label htmlFor="" className="ml-1  seller-label">
                                                                Right Leg
                                                            </label>
                                                            <input type="checkbox" className="ml-3 seller-checkbox " name="stomach" value={aOfDisabality.stomach} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.stomach) }} />
                                                            <label htmlFor="" className="ml-1  seller-label">
                                                                Stomach
                                                            </label>
                                                        </div> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4 padding-class">
                      <h6 style={{ fontWeight: "700" }}>
                        Employment Details /रोजगाराची सद्यस्थिती
                      </h6>
                      <hr className="border border-1 border-primary m-0 p-0" />
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ul
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Employment Status/रोजगाराची स्थिती<sup>*</sup>
                            </label>
                            {empDetailss && (
                              <p style={{ color: "red" }}>
                                Employment Status is Required!
                              </p>
                            )}
                            <div className="d-flex">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="Employed"
                                  name="status"
                                  checked={empDetails.status == "Employed"}
                                  value={empDetails.status}
                                  onChange={(e) => {
                                    empDetailsChange(e, "Employed");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Employed"
                                >
                                  Employed
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="Unemployed"
                                  name="status"
                                  checked={empDetails.status == "Unemployed"}
                                  value={empDetails.status}
                                  onChange={(e) => {
                                    empDetailsChange(e, "Unemployed");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Unemployed"
                                >
                                  Unemployed
                                </label>
                              </div>
                            </div>
                          </li>

                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Are you employed by the Government, Semi
                              Government, or any Government body/Corporation?
                              /आपण शासकीय/निमशासकीय/मंडळे/महामंडळे यांचे
                              कर्मचारी आहात का?<sup>*</sup>
                            </label>
                            {empDetailss && (
                              <p style={{ color: "red" }}>
                                Employment Status is Required!
                              </p>
                            )}
                            <div className="d-flex">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="employeeByGov"
                                  name="employeeByGov"
                                  checked={empDetails.employeeByGov == "Yes"}
                                  value={empDetails.status}
                                  onChange={(e) => {
                                    empDetailsChange(e, "Yes");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="employeeByGov"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="employeeByGov"
                                  name="employeeByGov"
                                  checked={empDetails.employeeByGov == "No"}
                                  value={empDetails.status}
                                  onChange={(e) => {
                                    empDetailsChange(e, "No");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="employeeByGov"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                            <div className="Semi-Government-2 mt-2">
                              <textarea
                                name="employeeGovInDepart"
                                id=""
                                cols="70"
                                rows="2"
                                disabled={empDetails.employeeByGov == "No"}
                                value={addSeller.employeeGovInDepart}
                                onChange={changeHandle}
                              ></textarea>
                            </div>
                          </li>

                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              BPL/APL/दारिद्र्यरेषेच्या वर / दारिद्र्यरेषेच्या
                              खालील<sup>*</sup>
                            </label>
                            {bpl && (
                              <p style={{ color: "red" }}>
                                BPL/APL is Required!
                              </p>
                            )}
                            <div className="d-flex">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault62"
                                  name="bpl"
                                  checked={empDetails.bpl == "N/A"}
                                  value={empDetails.bpl}
                                  onChange={(e) => {
                                    empDetailsChange(e, "N/A");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault62"
                                >
                                  N/A
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault63"
                                  name="bpl"
                                  checked={empDetails.bpl == "APL"}
                                  value={empDetails.bpl}
                                  onChange={(e) => {
                                    empDetailsChange(e, "APL");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault63"
                                >
                                  APL{" "}
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault64"
                                  name="bpl"
                                  checked={empDetails.bpl == "BPL"}
                                  value={empDetails.bpl}
                                  onChange={(e) => {
                                    empDetailsChange(e, "BPL");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault64"
                                >
                                  BPL{" "}
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault65"
                                  name="bpl"
                                  checked={empDetails.bpl == "Antoday"}
                                  value={empDetails.bpl}
                                  onChange={(e) => {
                                    empDetailsChange(e, "Antoday");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault65"
                                >
                                  Antyoday{" "}
                                </label>
                              </div>
                            </div>
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    दारिद्र्यरेषेच्या वर / दारिद्र्यरेषेच्या खालील<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        N/A
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        APL{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        BPL{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Antoday{" "}
                                    </label>
                                </div>
                            </li> */}
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Annual Income of the Applicant/लाभार्थ्याचे
                              वार्षिक उत्पन्न<sup>*</sup>
                            </label>
                            {anual && (
                              <p style={{ color: "red" }}>
                                Annual Income of the Applicant is Required!
                              </p>
                            )}
                            <div className="d-flex">
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault66"
                                  name="annualIncom"
                                  checked={
                                    empDetails.annualIncom == "Below 10k"
                                  }
                                  value={empDetails.annualIncom}
                                  onChange={(e) => {
                                    empDetailsChange(e, "Below 10k");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault66"
                                >
                                  Below 10k{" "}
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault67"
                                  name="annualIncom"
                                  checked={
                                    empDetails.annualIncom == "10k to 1 lakhs"
                                  }
                                  value={empDetails.annualIncom}
                                  onChange={(e) => {
                                    empDetailsChange(e, "10k to 1 lakhs");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault67"
                                >
                                  10k to 1 lakhs{" "}
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault68"
                                  name="annualIncom"
                                  checked={
                                    empDetails.annualIncom ==
                                    "1 lakhs to 2.5 lakhs"
                                  }
                                  value={empDetails.annualIncom}
                                  onChange={(e) => {
                                    empDetailsChange(e, "1 lakhs to 2.5 lakhs");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault68"
                                >
                                  01 lakhs to 2.5 lakhs{" "}
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault69"
                                  name="annualIncom"
                                  checked={
                                    empDetails.annualIncom ==
                                    "2.5 lakhs and more"
                                  }
                                  value={empDetails.annualIncom}
                                  onChange={(e) => {
                                    empDetailsChange(e, "2.5 lakhs and more");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault69"
                                >
                                  Above 2.5 lakhs{" "}
                                </label>
                              </div>
                            </div>
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्याचे वार्षिक उत्पन्न<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        १० हजार पैक्षा कमी
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        १० हजार ते १ लक्ष{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        लक्ष ते २.५ लक्ष
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        २.५ लक्ष{" "}
                                    </label>
                                </div>
                            </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4 padding-class">
                      <h6 style={{ fontWeight: "700" }}>
                        Identity Details (Min-1)/ओळखीचा पुरावा (किमान - १)
                      </h6>
                      <hr className="border border-1 border-primary m-0 p-0" />
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ul
                          style={{ listStyle: "unset", listStyleType: "none" }}
                        >
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Identity Proof/ओळखपत्र क<sup>*</sup>
                            </label>
                            {identyProoof && (
                              <p style={{ color: "red" }}>
                                Identity Proof is Required!
                              </p>
                            )}
                            {/* {errors.identityProof && (
                              <p style={{ color: "red" }}>
                                {errors.identityProof}
                              </p>
                            )} */}
                            <div className="Identity-proof Identity-proof-2">
                              <input
                                type="radio"
                                className="seller-checkbox "
                                id="Driving License1"
                                name="idProof"
                                checked={addSeller.idProof == "Driving License"}
                                onChange={(e) => {
                                  changeHandle(e, "Driving License");
                                }}
                              />
                              <label
                                htmlFor="Driving License1"
                                className="ml-1 seller-label"
                              >
                                Driving License
                              </label>
                              <input
                                type="radio"
                                className="ml-3 seller-checkbox"
                                id="PAN Card1"
                                name="idProof"
                                checked={addSeller.idProof == "PAN Card"}
                                onChange={(e) => {
                                  changeHandle(e, "PAN Card");
                                }}
                              />
                              <label
                                htmlFor="PAN Card1"
                                className="ml-1 seller-label"
                              >
                                PAN Card
                              </label>
                              <input
                                type="radio"
                                className="ml-3 seller-checkbox  "
                                id="Ration Card1"
                                name="idProof"
                                checked={addSeller.idProof == "Ration Card"}
                                onChange={(e) => {
                                  changeHandle(e, "Ration Card");
                                }}
                              />
                              <label
                                htmlFor="Ration Card1"
                                className="ml-1 seller-label"
                              >
                                Ration Card
                              </label>
                              <input
                                type="radio"
                                className="ml-3 seller-checkbox  "
                                id="Voter ID1"
                                name="idProof"
                                checked={addSeller.idProof == "Voter ID"}
                                onChange={(e) => {
                                  changeHandle(e, "Voter ID");
                                }}
                              />
                              <label
                                htmlFor="Voter ID1"
                                className="ml-1 seller-label"
                              >
                                Voter ID
                              </label>
                              <input
                                type="radio"
                                className="ml-3 seller-checkbox  "
                                id="Aadhar Card1"
                                name="idProof"
                                checked={addSeller.idProof == "Aadhar Card"}
                                onChange={(e) => {
                                  changeHandle(e, "Aadhar Card");
                                }}
                              />
                              <label
                                htmlFor="Aadhar Card1"
                                className="ml-1 seller-label"
                              >
                                Aadhar Card
                              </label>
                            </div>
                            <div className="d-flex mt-3">
                              <input
                                type="radio"
                                className="seller-checkbox "
                                id="Passport1"
                                name="idProof"
                                checked={addSeller.idProof == "Passport"}
                                onChange={(e) => {
                                  changeHandle(e, "Passport");
                                }}
                              />
                              <label
                                htmlFor="Passport1"
                                className="ml-1 seller-label"
                              >
                                Passport
                              </label>
                              <input
                                type="radio"
                                className="ml-3 seller-checkbox  "
                                id="MGNERGA Job Card1"
                                name="idProof"
                                checked={
                                  addSeller.idProof == "MGNERGA Job Card"
                                }
                                onChange={(e) => {
                                  changeHandle(e, "MGNERGA Job Card");
                                }}
                              />
                              <label
                                htmlFor="MGNERGA Job Card1"
                                className="ml-1 seller-label"
                              >
                                MGNERGA Job Card
                              </label>
                            </div>
                            <div className="d-flex mt-3">
                              <input
                                type="file"
                                id="fileInput4"
                                name="idProofFile"
                                onChange={handleImage}
                              />
                              (Size:10-500 KB, and Format: jpeg, jpg, png and
                              pdf)
                              {addSeller?.idProofFile?.url ? (
                                <p
                                  className="success-message"
                                  style={{ color: "green" }}
                                >
                                  Upload Successfully{" "}
                                </p>
                              ) : null}
                              {addSeller?.idProofFile?.url && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    imgShoing(addSeller?.idProofFile?.url)
                                  }
                                >
                                  Show
                                </button>
                              )}
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {errorMsgIdProfe}
                              </p>
                              {/* <span className="fw-bold">
                                                                (कागदपत्रे जेपीईजी/पीडी स्वरूपातच असावा कागदपत्राचा आकार
                                                                किमान ४५ केबी आणि कमाल २५६ केबी इतकी असावी)
                                                            </span> */}
                            </div>
                          </li>
                          {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    ओळखपत्र प्रमाणपत्र<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        वाहन परवाना
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पॅन कार्ड
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        शिधापत्रिका
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        मतदान कार्ड
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        आधार कार्ड
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पारपत्र
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        मग्रारोहयो जॉब कार्ड
                                    </label>
                                </div>
                                
                            </li> */}
                          <li className="mt-3">
                            {" "}
                            <label htmlFor="" className="fw-bold mr-2">
                              Identity Proof No./ओळखपत्र क्र<sup>*</sup>
                            </label>
                            <div className="d-flex">
                              <input
                                type="text"
                                className="pl-2"
                                value={addSeller.idProofNo}
                                name="idProofNo"
                                onChange={changeHandle}
                              />
                            </div>
                          </li>
                          {/* <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    ओळखपत्र क्र<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li> */}
                          <li className="mt-3">
                            <div className="d-flex">
                              <div className="col-md-6">
                                <label htmlFor="" className="fw-bold">
                                  Aadhar Card No./आधार क्रमांक <sup>*</sup>
                                </label>
                                <div className="d-flex">
                                  <input
                                    type="number"
                                    className="pl-2"
                                    name="adhaarCard"
                                    value={addSeller.adhaarCard}
                                    onChange={handleAadharChange}
                                  />
                                </div>
                                {aadharCardVerified && (
                                  <div style={{ color: "red" }}>
                                    Aadhar Card No Already Exist!
                                  </div>
                                )}
                              </div>
                              {/* <div className="col-md-6">
                                        <label htmlFor="" className="fw-bold ">
                                            आधार क्रमांक<sup>*</sup>
                                        </label>
                                        <div className="d-flex">
                                            <input type="text" className="pl-2" />
                                        </div>
                                    </div> */}
                            </div>
                            {errors.adhaarCard && (
                              <p style={{ color: "red" }}>
                                {errors.adhaarCard}
                              </p>
                            )}
                          </li>
                          <li className="mt-3">
                            <label htmlFor="" className="fw-bold mr-2">
                              Bank Details of the Applicant/अर्जदाराचे बँक
                              तपशील*<sup>*</sup>
                            </label>
                            <div className=" col-md-12">
                              <label htmlFor="" className="fw-bold mr-2">
                                Account Holder's Name /खातेधारकाचे नाव
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="pl-2"
                                  style={{ width: "300px" }}
                                  name="accHolderName"
                                  value={addSeller.accHolderName}
                                  onChange={changeHandle}
                                />
                              </div>
                              {errors.accHolderName && (
                                <p style={{ color: "red" }}>
                                  {errors.accHolderName}
                                </p>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <label htmlFor="" className="fw-bold mr-2">
                                Account Number/खाते क्रमांक
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="pl-2"
                                  style={{ width: "300px" }}
                                  name="accNo"
                                  value={addSeller.accNo}
                                  onChange={changeHandle}
                                />
                              </div>
                              {errors.accNo && (
                                <p style={{ color: "red" }}>{errors.accNo}</p>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <label htmlFor="" className="fw-bold mr-2">
                                Bank Name/बँकेचे नाव
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="pl-2"
                                  style={{ width: "300px" }}
                                  name="bankName"
                                  value={addSeller.bankName}
                                  onChange={changeHandle}
                                />
                              </div>
                              {errors.bankName && (
                                <p style={{ color: "red" }}>
                                  {errors.bankName}
                                </p>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <label htmlFor="" className="fw-bold mr-2">
                                IFSC Code/IFSC कोड
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="pl-2"
                                  style={{ width: "300px" }}
                                  name="ifscCode"
                                  value={addSeller.ifscCode}
                                  onChange={changeHandle}
                                />
                              </div>
                              {errors.ifscCode && (
                                <p style={{ color: "red" }}>
                                  {errors.ifscCode}
                                </p>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <label htmlFor="" className="fw-bold mr-2">
                                Branch Name/शाखेचे नाव
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="pl-2"
                                  style={{ width: "300px" }}
                                  name="branchName"
                                  value={addSeller.branchName}
                                  onChange={changeHandle}
                                />
                              </div>
                              {errors.branchName && (
                                <p style={{ color: "red" }}>
                                  {errors.branchName}
                                </p>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <label htmlFor="" className="fw-bold mr-2">
                                Branch Code/शाखा कोड
                              </label>
                              <div>
                                <input
                                  type="text"
                                  className="pl-2"
                                  style={{ width: "300px" }}
                                  name="branchCode"
                                  value={addSeller.branchCode}
                                  onChange={changeHandle}
                                />
                              </div>
                              {errors.branchCode && (
                                <p style={{ color: "red" }}>
                                  {errors.branchCode}
                                </p>
                              )}
                            </div>
                          </li>
                          {/* <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभाथ्र्यांचे  बँक तपशील<sup>*</sup>
                                </label>
                                <div className=" col-md-12">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        खातेधारकाचे नाव
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        खाते क्रमांक
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        बँकेचे नाव
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        IFSC कोड
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        शाखेचे नाव
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        शाखा कोड
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                            </li> */}
                          <li className="mt-3">
                            <label htmlFor="" className="fw-bold mr-2">
                              Please upload Front Page of Bank Passbook/कृपया
                              बँक पासबुकचे पहिले पान अपलोड करा<sup>*</sup>
                              {addSeller?.frontOfBankPassBook?.url ? (
                                <p
                                  className="success-message"
                                  style={{ color: "green" }}
                                >
                                  Upload Successfully{" "}
                                </p>
                              ) : null}
                              {/* {console.log(addSeller)} */}
                              {addSeller?.frontOfBankPassBook?.url ? (
                                <button
                                  type="button"
                                  onClick={() =>
                                    imgShoing(
                                      addSeller?.frontOfBankPassBook?.url
                                    )
                                  }
                                >
                                  Show
                                </button>
                              ) : (
                                ""
                              )}
                              {/* {isSuccessBankPassBook ? <p className="success-message" style={{ color: 'green' }}>Upload Successfully  <button onClick={() => imgShoing(addSeller?.frontOfBankPassBook?.url)}>Show</button></p> : null} */}
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {errorBankPassBook}
                              </p>
                            </label>
                            <div className="d-flex">
                              <input
                                type="file"
                                id="fileInput5"
                                className="pl-2"
                                name="frontOfBankPassBook"
                                onChange={handleImage}
                              />
                              (Size:10-500 KB, and Format: jpeg, jpg, png and
                              pdf)
                            </div>
                          </li>
                          {/* <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    कृपया बँक पासबुकचे पहिले पान अपलोड करा<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="file" className="pl-2" />
                                </div>
                            </li> */}
                          <li className="mt-3">
                            <label htmlFor="" className="fw-bold mr-2">
                              Applicant’s Affidavit/अर्जदाराचे प्रतिज्ञापत्रक*
                              <sup>*</sup>
                              {/* isSuccessApplicantAffidavit */}
                              {addSeller?.affidavith.url ? (
                                <p
                                  className="success-message"
                                  style={{ color: "green" }}
                                >
                                  Upload Successfully{" "}
                                </p>
                              ) : null}
                              {addSeller?.affidavith.url && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    imgShoing(addSeller?.affidavith.url)
                                  }
                                >
                                  Show
                                </button>
                              )}
                              {/* {isSuccessApplicantAffidavit ? <p className="success-message" style={{ color: 'green' }}>Upload Successfully  <button onClick={() => imgShoing(addSeller?.affidavith.url)}>Show</button></p> : null} */}
                              <p
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {errorApplicantAffidavit}
                              </p>
                            </label>
                            (Size:10-500 KB, and Format: jpeg, jpg, png and pdf)
                            <div className="d-flex ">
                              <input
                                type="file"
                                id="fileInput"
                                className="pl-2"
                                name="affidavith"
                                onChange={handleImage}
                              />
                              <p>
                                To Download Affidavit Please
                                <a
                                  href={pdf}
                                  download="Example-PDF-document"
                                  //onClick={downloadAffidavit}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Click Here
                                </a>
                              </p>
                            </div>
                          </li>
                          {/* <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभाथ्र्यांचे प्रतिज्ञापत्रक<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="file" className="pl-2" />
                                </div>
                            </li> */}
                          <li className="mt-3">
                            <label htmlFor="" className="fw-bold mr-2">
                              What Business would you like to do?/तुम्ही कोणता
                              व्यवसाय करण्यास इच्छुक आहात?<sup>*</sup>
                            </label>
                            {/* {businessWould && <p style={{ color: 'red' }}>What Business would you like to do is Required!</p>} */}
                            {likeToDo && (
                              <p style={{ color: "red" }}>
                                What Business would you like to do!
                              </p>
                            )}

                            <div className="col-md-6 border border-2 border-secondary ">
                              <div className="mt-2 ">
                                <div className="form-check mr-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    id="flexRadioDefault70"
                                    name="businessYouLikeToDo"
                                    checked={addSeller.businessYouLikeToDo == "Food Item"}
                                    onChange={(e) => {
                                      changeHandle(e, "Food Item");
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault70"
                                  >
                                    Food item / खादयपदार्थ{" "}
                                  </label>
                                </div>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault71"
                                  name="businessYouLikeToDo"
                                  checked={addSeller.businessYouLikeToDo == "Minor"}
                                  onChange={(e) => {
                                    changeHandle(e, "Minor");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault71"
                                >
                                  Minor/किरकोळ{" "}
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault72"
                                  name="businessYouLikeToDo"
                                  checked={addSeller.businessYouLikeToDo == "Independent Business"}
                                  onChange={(e) => {
                                    changeHandle(e, "Independent Business");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault72"
                                >
                                  Independent Business/स्वतंत्र व्यवसाय
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault73"
                                  name="businessYouLikeToDo"
                                  checked={addSeller.businessYouLikeToDo == "Transport Business"}
                                  onChange={(e) => {
                                    changeHandle(e, "Transport Business");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault73"
                                >
                                  Transport business/वाहतूक{" "}
                                </label>
                              </div>
                              <div className="form-check mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="flexRadioDefault74"
                                  name="businessYouLikeToDo"
                                  checked={addSeller.businessYouLikeToDo == "Other"}
                                  onChange={(e) => {
                                    changeHandle(e, "Other");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault74"
                                >
                                  Others{" "}
                                </label>
                              </div>
                            </div>
                          </li>
                          <li className="mt-3">
                            <label
                              htmlFor=""
                              className="fw-bold mr-2 fw-bold-fw-bold"
                            >
                              Declaration<sup>*</sup>
                            </label>
                            <div className="col-md-12">
                              <span
                                style={{
                                  lineHeight: "normal",
                                  lineBreak: "auto",
                                }}
                              >
                                I, Mr/Ms/Mrs{" "}
                                <input
                                  type="text"
                                  id=""
                                  style={{
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    paddingLeft: "5px",
                                    textAlign: "center",
                                  }}
                                  name="declareName"
                                  value={
                                    addSeller.bene_firstname +
                                    " " +
                                    addSeller.bene_lastname
                                  }
                                  onChange={handleInputChange}
                                />
                                {errors.declareName && (
                                  <p style={{ color: "red" }}>
                                    {errors.declareName}
                                  </p>
                                )}
                                Son/Daughter/Wife of{" "}
                                <input
                                  type="text"
                                  id=""
                                  style={{
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    paddingLeft: "5px",
                                    textAlign: "center",
                                  }}
                                  name="declareGuard"
                                  value={addSeller.bene_middlename}
                                  onChange={changeHandle}
                                />
                                Age{" "}
                                <input
                                  type="number"
                                  id=""
                                  style={{
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    paddingLeft: "5px",
                                    width: "50px",
                                    textAlign: "center",
                                  }}
                                  name="declareAge"
                                  value={birthday}
                                  onChange={changeHandle}
                                />{" "}
                                Caste{" "}
                                <input
                                  type="text"
                                  id=""
                                  style={{
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    paddingLeft: "5px",
                                    textAlign: "center",
                                  }}
                                  name="declareCaste"
                                  value={addSeller.castCategory}
                                  onChange={changeHandle}
                                />{" "}
                                (General/OBC/SC/ST/VJNT/NT-B/NT-C/NT-D)
                                ,Resident of{" "}
                                <input
                                  type="text"
                                  id=""
                                  style={{
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    paddingLeft: "5px",
                                    textAlign: "center",
                                  }}
                                  name="residentOf"
                                  value={addSeller.village}
                                  onChange={changeHandle}
                                />{" "}
                                , Post{" "}
                                <input
                                  type="text"
                                  id=""
                                  style={{
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    paddingLeft: "5px",
                                    textAlign: "center",
                                  }}
                                  name="declarePost"
                                  value={addSeller.post}
                                  onChange={changeHandle}
                                />
                                ,Block
                                <input
                                  type="text"
                                  id=""
                                  style={{
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    paddingLeft: "5px",
                                    textAlign: "center",
                                  }}
                                  name="block"
                                  value={addSeller.block}
                                  onChange={changeHandle}
                                />
                                ,District{" "}
                                <input
                                  type="text"
                                  id=""
                                  style={{
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    paddingLeft: "5px",
                                    textAlign: "center",
                                  }}
                                  name="district"
                                  value={distSet}
                                  onChange={changeHandle}
                                />
                                ,State Maharashtra, Pin code{" "}
                                <input
                                  type="text"
                                  id=""
                                  style={{
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                    paddingLeft: "5px",
                                    textAlign: "center",
                                  }}
                                  name="pincode"
                                  value={addSeller.pincode}
                                // onChange={changeHandle}
                                />
                                do hereby declare that, the Information given
                                above and in the enclosed documents is true to
                                the best of my knowledge and belief, and nothing
                                has been concealed therein. I am well aware of
                                the fact that if the information given by me is
                                provided false/not true, then I will be liable
                                for disqualifying the application as per the
                                law. Also, if any benefits availed by me shall
                                be summarily withdrawn. I will use the
                                grant/e-vehicle/e-cart for the same purpose as
                                per the scheme guidelines dated 10 June 2019
                              </span>
                            </div>
                          </li>
                          {/* <li className="mt-3 d-flex">
                                <label htmlFor="" className="fw-bold mr-2">
                                    Accept (All terms & Condition)<sup>*</sup>
                                </label>
                                <div className="col-md-6 border border-2 border-secondary ">
                                    <div className="d-flex mt-2 mb-2 ">
                                        <input type="checkbox" className="seller-checkbox " />
                                        <label htmlFor="" className="ml-1 seller-label">
                                            Yes
                                        </label>
                                        <input type="checkbox" className="ml-3 seller-checkbox  " />
                                        <label htmlFor="" className="ml-1 seller-label">
                                            No
                                        </label>
                                    </div>


                                </div>
                            </li> */}
                        </ul>
                        {/* <div className="Affidavit-sec">
                                                    <div className="">
                                                        <button className="Affidavit-bn" onClick={handleGenerateExcel}>Download Affidavit</button>
                                                    </div>
                                                    <div className="mt-3">
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            Please upload Front Page of Affidavit/कृपया प्रतिज्ञापत्राचे पहिले पान अपलोड करा<sup>*</sup>
                                                        </label>
                                                        <div className="d-flex">
                                                            <input type="file" className="pl-2" name="pledgeImage" onChange={handleImage} />

                                                            <p style={{ color: 'red' }}>{Afedefit}</p>
                                                        </div>
                                                    </div>
                                                </div> */}
                      </div>
                    </div>

                    <div className="description">
                      <h4>Terms and conditions of the scheme*</h4>
                      <p className="mb-1">
                        1. Applicant should be resident of Maharashtra State.
                      </p>
                      <p className="mb-1">
                        2. Applicant should have at least 40% disability and
                        should have a fitness certificate from District Surgeon
                        / Competent Authority.
                      </p>
                      <p className="mb-1">
                        3. Age of the applicant should be between 18 to 55 year
                      </p>
                      <p className="mb-1">
                        4. In case of intellectual disabilities applicants their
                        legal guardians/parents will be eligible to apply for
                        the Scheme.
                      </p>
                      <p className="mb-1">
                        5. Annual income of the applicant should not exceed INR
                        2.50 lakhs.
                      </p>
                      <p className="mb-1">
                        6. First priority will be given to those having severe
                        disabilities while selecting the beneficiaries. So, the
                        order of selection will be from severe disability to
                        mild disability.
                      </p>
                      <p className="mb-1">
                        7. If person having severe disability is denied for
                        driving license, even in such a situation preference
                        will be given to a severely disabled person who does not
                        have a license to do mobile business with the help of an
                        escort.
                      </p>
                      <p className="mb-1">
                        8. At the time of submission of the application, the
                        applicant required to submit a bond/affidavit agreeing
                        to all rules and conditions and taking proper care of
                        his/her-vehicle/e-cart.
                      </p>
                      <p className="mb-1">
                        9. District wise list of the beneficiaries will be
                        announced in proportion to number of persons with
                        disabilities in the district.
                      </p>
                      <p className="mb-1">
                        10. The applicant should not be employed by the
                        Government, Semi Government, or any Government
                        body/Corporation.
                      </p>
                      <p className="mb-1">
                        11. If the applicant has previously availed a loan from
                        our corporation, they should not be in default of the
                        said loan.
                      </p>
                      <h4 style={{ marginTop: "10px" }}>
                        योजनेच्या अटी व शर्ती*
                      </h4>
                      <p className="mb-1">
                        1. अर्जदार हा महाराष्ट्र राज्याचा रहिवाशी असावा.
                      </p>
                      <p className="mb-1">
                        2. अर्जदाराकडे दिव्यांगत्वाचे प्रमाण किमान ४०% टक्के
                        असावे तसेच जिल्हा शल्य चिकित्सक / सक्षम प्राधिकारी यांनी
                        प्रमाणित केलेले प्रमाणपत्रधारक असावा
                      </p>
                      <p className="mb-1">
                        3. अर्जदार हा १८ ते ५५ या वयोगटातील असावा
                      </p>
                      <p className="mb-1">
                        4. बौध्दिक अक्षम अर्जदाराच्या बाबतीत त्यांचे कायदेशीर
                        पालक अर्ज करण्यास सक्षम असतील
                      </p>
                      <p className="mb-1">
                        5. दिव्यांग अर्जदाराचे वार्षिक उत्पन्नरु. २.५० लाख
                        पेक्षा अधिक नसावे.
                      </p>
                      <p className="mb-1">
                        6. लाभार्थी निवड करताना जास्त अपंगत्व असलेल्यास प्रथम
                        प्राधान्य दिले जाईल. त्यामुळे निवडीचा क्रम हा अतितीव्र
                        दिव्यांगत्व ते कमी दिव्यांगत्व या क्रमाने राहील.
                      </p>
                      <p className="mb-1">
                        7. अतितीव्र दिव्यांगत्व असणाऱ्या दिव्यांग व्यक्तीस वाहन
                        चालविण्याचा परवाना नाकारला असल्यास अशा परिस्थितीत देखील
                        परवाना धारक नसलेल्या अतितीव्र दिव्यांग व्यक्तीच्या
                        बाबतीत सोबत्याच्या (Escort) सहाय्याने फिरता मोबाईल
                        व्यवसाय करण्यास प्राधान्य दिले जाईल.
                      </p>
                      <p className="mb-1">
                        8. अर्जाच्या वेळी अर्जदाराने सर्व अटी मान्य असल्याचे
                        तसेच संबंधीत वाहनाची योग्यती काळजी घेण्याचे बंधपत्र सादर
                        करणे आवश्यक राहील
                      </p>
                      <p className="mb-1">
                        9. जिल्हानिहाय लाभार्थ्यांची घोषणा दिव्यांगांच्या
                        संख्येच्या प्रमाणात केली जाईल.
                      </p>
                      <p className="mb-1">
                        10. अर्जदार हा शासकीय/निमशासकीय/मंडळे/महामंडळे यांचा
                        कर्मचारी नसावा.
                      </p>
                      <p className="mb-1">
                        11. या योजनेसाठी अर्ज करणारा अर्जदार जर दिव्यांग वित्त व
                        विकास महामंडळाचा कर्जदार असेल तर तो थकबाकीदार नसावा.
                      </p>
                      <p className="fw-bold">
                        <div className="form-check">
                          <input
                            className="form-check-input form-check-input-2"
                            type="checkbox"
                            defaultValue
                            checked={addSeller.termsAndCondition}
                            id="conditions"
                            name="termsAndCondition"
                            value={addSeller.termsAndCondition}
                            onChange={termConditionChange}
                          />
                          <label
                            className="form-check-label mt-2"
                            htmlFor="conditions"
                          >
                            Are the terms and conditions acceptable?{" "}
                            <sup>*</sup>
                          </label>
                        </div>
                      </p>

                      <p>
                        {/* <div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="check" id="flexRadioDefault80" name="termsAndCondition" value={addSeller.termsAndCondition} onChange={(e) => { changeHandle(e, true, 'sss') }} />
                                                        <label className="form-check-label" htmlFor="flexRadioDefault80">
                                                            yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="check" id="flexRadioDefault81" name="termsAndCondition" value={addSeller.termsAndCondition} onChange={(e) => { changeHandle(e, false, 'sss') }} />
                                                        <label className="form-check-label" htmlFor="flexRadioDefault81">
                                                            No
                                                        </label>
                                                    </div>
                                                </div> */}
                      </p>
                      {/* <p>No</p> */}
                      {/* <p>Activities ★</p> */}
                      {/* <p>MH. Np</p>
                                        <p>1350</p> */}
                    </div>

                    {loader && (
                      <div
                        className=".table-to-pdf ffef text-center"
                        ref={pdfRef2}
                      >
                        <h2>प्रतिज्ञापत्र</h2>

                        <div className="col-md-12">
                          <span
                            style={{ lineHeight: "normal", lineBreak: "auto" }}
                          >
                            मी श्री/श्रीमती
                            <input
                              type="text"
                              id=""
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                paddingLeft: "5px",
                              }}
                              name="declareName"
                              value={
                                addSeller.bene_firstname +
                                " " +
                                addSeller.bene_lastname
                              }
                              onChange={handleInputChange}
                            />
                            {errors.declareName && (
                              <p style={{ color: "red" }}>
                                {errors.declareName}
                              </p>
                            )}
                            वय
                            <input
                              type="text"
                              id=""
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                paddingLeft: "5px",
                              }}
                              name="declareGuard"
                              value={addSeller.relatedToGuardian}
                              onChange={changeHandle}
                            />
                            वर्षे
                            <input
                              type="number"
                              id=""
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                paddingLeft: "5px",
                                width: "50px",
                              }}
                              name="declareAge"
                              value={birthday}
                              onChange={changeHandle}
                            />{" "}
                            श्री.
                            <input
                              type="text"
                              id=""
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                paddingLeft: "5px",
                              }}
                              name="declareCaste"
                              value={addSeller.castCategory}
                              onChange={changeHandle}
                            />{" "}
                            यांचा/यांचीमुलगा/मुलगी/पत्नी असून, मी गांव/शहर
                            <input
                              type="text"
                              id=""
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                paddingLeft: "5px",
                              }}
                              name="residentOf"
                              value={addSeller.residentOf}
                              onChange={changeHandle}
                            />{" "}
                            , पोष्ट
                            <input
                              type="text"
                              id=""
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                paddingLeft: "5px",
                              }}
                              name="declarePost"
                              value={addSeller.declarePost}
                              onChange={changeHandle}
                            />
                            ,तालुका
                            <input
                              type="text"
                              id=""
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                paddingLeft: "5px",
                              }}
                              name="block"
                              value={addSeller.block}
                              onChange={changeHandle}
                            />
                            ,जिल्हा
                            <input
                              type="text"
                              id=""
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                paddingLeft: "5px",
                              }}
                              name="district"
                              value={distSet}
                            // onChange={changeHandle}
                            />
                            ,राज्य महाराष्ट्र, पिनकोड{" "}
                            <input
                              type="text"
                              id=""
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                paddingLeft: "5px",
                              }}
                              name="pincode"
                              value={addSeller.pincode}
                            // onChange={changeHandle}
                            />
                            येथील रहिवासी असून याद्वारे प्रतिज्ञापत्र करतो/करते
                            की, वर दिलेली आणि संलग्न दस्तऐवजांमध्ये सादर केलेली
                            माहिती माझ्या सर्वोत्तम माहितीनुसार आणि
                            विश्वासानुसार सत्य आहे आणि त्यात कोणतीही गोष्ट लपवून
                            ठेवण्यात आलेली नाही. मला या वस्तुस्थितीची चांगली
                            जाणीव आहे की जर मी दिलेली माहिती खरी नसली, तर
                            कायद्यानुसार अर्ज अपात्र ठरवण्यास मी जबाबदार असेन.
                            तसेच, मला मिळालेले सर्व फायदे सरसकट काढून घेतले
                            जातील. मी महाराष्ट्र राज्यातील इतर कोणत्याही योजने
                            अंतर्गत मोफत ई-वाहनाचा लाभ घेतलेला नाही. मी 10 जून
                            2019 च्या शासन निर्णयातील योजनेच्या मार्गदर्शक
                            तत्त्वांनुसार अनुदान / ई-वाहन / ई-कार्टचा वापर त्याच
                            उद्देशासाठी करेन तसेच संबंधीत वाहनाची योग्य ती काळजी
                            घेईन. मी याद्वारे प्रतिज्ञापत्रावर असेही कथन करतो
                            की, मी शासकीय/निमशासकीय/मंडळे/महामंडळे यांचा
                            कर्मचारी नाही. तसेच महाराष्ट्र राज्य दिव्यांग वित्त
                            व विकास महामंडळ, मुंबई यांचा थकीत कर्जदार लाभार्थी
                            नाही.
                          </span>
                          <div className="sign-section">
                            <div className="date-sec">दिनांक:</div>
                            <div className="box-doc-2">
                              <h5>सही</h5>
                              <div className="box-doc"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="text-center mb-3">
                      <button
                        type="button"
                        className="text-center seller-btn seller-btn-2"
                        onClick={saveDraft}
                      >
                        Save As Draft
                      </button>
                      <button
                        type="submit"
                        disabled={!addSeller.termsAndCondition == true}
                        className="text-center seller-btn"
                      >
                        Submit
                      </button>
                    </div> 
                  </form>

                  {/* <div className="text-center mb-3">
                                        <button type="button" onClick={postData} disabled={!addSeller.termsAndCondition} className="text-center seller-btn">Submit</button>
                                    </div> */}
                </div>

                <SpecialSellerPopup
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  successData={successData}
                  downloadPdf={downloadPdf}
                  spinn={spinn}
                />
              </div>
            </div>
          </div>
          <ToastContainer />

          <ShowImage
            show={modalShow1}
            onHide={() => setModalShow1(false)}
            imagePopup={imageAddress}
          />
          {/* <PdfGeneratePdf fontSize={fontSize} colorMode={colorMode} listProfe={listProfe} /> */}
        </div>
      </>
    );
  }
}
export default EnglishSellerSpecial;
