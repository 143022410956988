import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css'
import './stylecss/homeStyle.css'
import './stylecss/navbarStyle.css'


import Seller from './Components/sellerr/sellerr/Seller';
import SpecialSellerDetails from './Components/sellerr/sellerr/specialSellerDetails/SpecialSellerDetails';
import LoginPage from './pages/login';
import LandingNavbar from './Components/LandingNavbar';
import { useState } from 'react';
import TrackingPage from './pages/track';
import Footer from './common/footer/Footer';
import DownloadBeneficiaryDivisionWisePage from './pages/downloadBeneficiaryDivisionWisePage';




function App() {
  const [colorMode, setThemeMode] = useState("black");
  const [bgColorMode, setbgColorMode] = useState("white");
  // console.log(bgColorMode, 'bgColorMode');
  // console.log(colorMode, 'colorMode');

  const [fontSize, setFontSize] = useState(16);

  const originalFontSize = () => {
    console.log('jpoijpoj');
    setFontSize('16')
  }
  const FontSizeTwenty = () => {
    setFontSize('22')
  }
  const IncreaseFontSize = () => {
    setFontSize('26')
  }

  const colorThemeSelector = (color) => {
    var bodyStyle = document.body.style;

    if (color === "black") {
      setThemeMode("white");
      setbgColorMode("#fff");
      bodyStyle.backgroundColor = "black";
    } else if (color === "orange") {
      setThemeMode("orange");
      setbgColorMode("#ffdc81");
      bodyStyle.backgroundColor = "#ffdc81";
    }
    else {
      setThemeMode("black");
      setbgColorMode("white");
      bodyStyle.backgroundColor = "white";
    }
  };


  return (
    <>
      {/* <iframe src="https://specialsellerbucket.s3.ap-south-1.amazonaws.com/image-1701264708288.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAWWJHMDRIBEIOZ7VW%2F20231129%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231129T133148Z&X-Amz-Expires=900&X-Amz-Signature=f919a6c5002ce987af6b3f69f3d0a371f3e9515c8662d164267e9708915cd6a2&X-Amz-SignedHeaders=host&x-id=GetObject" frameborder="0"></iframe> */}

      <LandingNavbar colorMode={colorMode} bgColorMode={bgColorMode} colorThemeSelector={colorThemeSelector} originalFontSize={originalFontSize} FontSizeTwenty={FontSizeTwenty} IncreaseFontSize={IncreaseFontSize} fontSize={fontSize} />
      <Routes>
        <Route path='/' element={<LandingPage fontSize={fontSize} bgColorMode={bgColorMode} colorMode={colorMode} />} />
        <Route path="login" element={<LoginPage fontSize={fontSize} />} />
        <Route path="special-seller-sign-up" element={<Seller colorMode={colorMode} fontSize={fontSize} />} />
        <Route path="special-seller/details/:applicationNo" element={<SpecialSellerDetails />} />
        <Route path="track" element={<TrackingPage fontSize={fontSize} />} />
        <Route path="trk" element={<TrackingPage fontSize={fontSize} />} />
        <Route path="beneficiary/divisionwise" element={<DownloadBeneficiaryDivisionWisePage fontSize={fontSize} />} />
      </Routes>
      <Footer fontSize={fontSize} colorMode={colorMode} />
    </>
  );
}

export default App;
