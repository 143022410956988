import React from "react";
// import photo from "../../assets/img/seller-logo.jpeg";

import EnglishSellerSpecial from "./english-seller-speacial/EnglishSellerSpecial";
// import MarathiSellerSpecial from "./marathi-seller-special/MarathiSellerSpecial";
function Seller({ colorMode, fontSize }) {
    console.log(fontSize);
    return (
        <>
            <div className="specil-seller-list mt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className={`tab-seller ${colorMode === 'white' ? 'white' : ''}`}>
                                <EnglishSellerSpecial colorMode={colorMode} fontSize={fontSize} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container border border-primary" style={{ width: 900 }}>
                <div className="col-md-12 text-center mt-2">
                    <span className="fs-6 fw-bold ">
                        Persons with Destabilise Welfare Department, Government of
                        Maharashtra
                    </span>{" "}
                    <br />
                    <span>दिव्यांगकल्याणविभाग,महाराष्ट्रशासन</span>
                </div>
            </div>
        
            <div className="container border border-primary" style={{ width: 900 }}>
                <div className="col-md-12 text-center mt-2">
                    <span className="fs-6 ">
                        Application form for green-powered eco-friendly mobile vehicle shop
                        for people with disabilities to become self-relient.{" "}
                    </span>{" "}
                    <br />
                    <span></span>
                </div>
            </div>
            <div className="container border border-primary" style={{ width: 900 }}>
                <div className="col-md-12 mt-4">
                    <h6>Personal Details/</h6>
                    <hr className="border border-1 border-primary m-0 p-0" />
                </div>
                <div className="row m-0 p-0">
                    <div className="col-md-10 mt-3 m-0 p-0">
                        <ul type="disc " style={{ listStyle: "unset" }}>
                            <li>
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Name of Beneficiery <sup>*</sup>
                                </label>
                                <div>
                                    <input type="text" placeholder="Surname" className="pl-2" />{" "}
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        className="pl-2"
                                    />{" "}
                                    <input
                                        type="text"
                                        className="pl-2"
                                        placeholder="Middle Name"
                                    />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्याचेनाव <sup>*</sup>
                                </label>
                                <div>
                                    <input type="text" placeholder="आडनाव" className="pl-2" />{" "}
                                    <input type="text" placeholder="नाव" className="pl-2" />{" "}
                                    <input
                                        type="text"
                                        className="pl-2"
                                        placeholder="वडिलांचे/तिचे नाव"
                                    />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Mothers Name <sup>*</sup>
                                </label>
                                <div>
                                    <input type="text" placeholder="Surname" className="pl-2" />{" "}
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        className="pl-2"
                                    />{" "}
                                    <input
                                        type="text"
                                        className="pl-2"
                                        placeholder="Middle Name"
                                    />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    आईचे नाव<sup>*</sup>
                                </label>
                                <div>
                                    <input type="text" placeholder="आडनाव" className="pl-2" />{" "}
                                    <input type="text" placeholder="नाव" className="pl-2" />{" "}
                                    <input type="text" className="pl-2" placeholder="तिचे नाव" />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Date of Birth<sup>*</sup>
                                </label>
                                <div>
                                    <input type="date" className="pl-2" />{" "}
                                    <input
                                        type="text"
                                        placeholder="Age(Min 18 to Max 55)"
                                        className="ml-5 pl-2"
                                        style={{ width: 250 }}
                                    />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    जन्मतारीख<sup>*</sup>
                                </label>
                                <div>
                                    <input type="date" className="pl-2" />{" "}
                                    <input
                                        type="text"
                                        placeholder="लाभार्थ्याचे वय (किमान १८ वर्षे ते कमाल ५५ वर्षे)"
                                        className="ml-5 pl-2"
                                        style={{ width: 250 }}
                                    />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Mobile No.<sup>*</sup>
                                </label>
                                <div>
                                    <input type="number " placeholder="+91" className="pl-2" />{" "}
                                    <input
                                        type="text"
                                        placeholder="भ्रमणध्वनी क्र. +९१"
                                        className="ml-5 pl-2"
                                        style={{ width: 250 }}
                                    />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Gender/लिंग<sup>*</sup>
                                </label>
                                <div>
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Male/पु
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox" />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Female/स्त्री
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox" />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Transgender/ट्रान्सजेडर
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox" />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Other/इतर
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2 mt-4">
                        <img src={photo} alt="nizam" className="border border-primary" />
                      
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul type="disc " style={{ listStyle: "unset" }}>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Blood Group/रक्त गट
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        O+
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        O-
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        A
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        A+
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        A-
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        B
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        B-
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        B-
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        AB+
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        AB-
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Category <sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        General
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        OBC
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        SC
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        ST
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        VJNT
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        NT-B
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        NT-C
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        NT-D
                                    </label>
                                </div>
                                <span>
                                    (Atteched Cast Certificate and Non-Creamy Layer Certificate
                                    for OBC/SC/ST/VJNT/NT-B/NT-C/NT-D){" "}
                                </span>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    जात/प्रवर्ग<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        सर्वसाधारण
                                    </label>
                                    <input type="checkbox" className="ml-5 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        इतर मागास वर्ग
                                    </label>
                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अनुसुचित जाती
                                    </label>
                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अनुसुचित जमाती
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विमुक्त जाती
                                    </label>

                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        भटक्या जमाती-ब
                                    </label>

                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        भटक्या जमाती क
                                    </label>

                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        भटक्या जमाती-ड
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="file" className=" " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        (जात प्रमाणपत्र अपलोड करणे)
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Marital Status <sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        Unmarried
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Married
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Widow
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Divorced
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    वैवाहिक स्थिती <sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        अविवाहित
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विवाहित{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विधव
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        घटस्फोटीत
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    If you are married, then please provide name of the spouse
                                    <sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    विवाहित असल्यास पतिचे/पत्निचे संपूर्ण नाव<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Name of Guardian of the beneficiary<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    His/her Contact No<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="number" className="pl-2" placeholder="+91" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्याच्या पालकाचे/सहाय्यकाचे नाव<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    पालकाचा/सहायकाचा भ्रमणध्वनी क्र.<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="number" className="pl-2" placeholder="+91" />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Relationship of Guardian with the beneficiary<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        Father
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Mother{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Wife
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Husband
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Other
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्यासोबतचे नाते<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        वडिल
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        आई{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पति
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पत्नि
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        इतर
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Educational Details<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        Primary
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Secondary{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Higher Secondary
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Diploma
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Graduate
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Postgraduate
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Uneducated
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    शैक्षणिक अर्हता<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        प्राथमिक
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        माध्यमिक{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        उच्च माध्यमिक
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        डिप्लोमा
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पदवीधर
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पदव्युत्तर
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अशिक्षित
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-12 mt-4">
                    <h6>Address Details/अर्जदाराचे निवासाचे तपशिल</h6>
                    <hr className="border border-1 border-primary m-0 p-0" />
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Are you being resident of Maharashtralagate?
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        Yes
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        No
                                    </label>
                                    <span className="ml-3">
                                        (If Yes Please Upload Domicile Certificate)
                                    </span>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    आपण महाराष्ट्र राज्याचे रहिवासी आहत का?
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        होय{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        नाही
                                    </label>
                                    <span className="ml-3">
                                        (असल्यास अधिवास प्रमाणपत्र अपलोड करावे)
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Domacile Certificate/अधिवास प्रमाणपत्र<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="file" className="pl-2" />
                                    <span>
                                        (कागदपत्रे जेपीईजी/पीडीफ स्वरूपातच असावा. कागदपत्राचा आकार
                                        किमान ७५ केबी आणि कमाल २५६ केबी इतकी असावी)
                                    </span>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Corespondence Address / सध्याचा पत्ता<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <textarea
                                        name=""
                                        id=""
                                        cols="80"
                                        rows="2"
                                        className="pl-2"></textarea>
                                </div>
                            </li>
                        </ul>
                        <div className="d-flex ml-5">
                            <div className="col-md-4">
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Village/गांव<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                            <div className="col-md-4">
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Block/तालुका.<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                            <div className="col-md-4">
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        District/जिल्हा<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                        </div>
                        <div className="d-flex ml-5">
                            <div className="col-md-4">
                                <li className="mt-3">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        State / राज्य<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                            <div className="col-md-4">
                                <li className="mt-3">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Pincode / पिनकोड<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                        </div>
                        <ul style={{ listStyle: "unset" }}>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Permanent Address / कायमचा पत्ता<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <textarea
                                        name=""
                                        id=""
                                        cols="80"
                                        rows="2"
                                        className="pl-2"></textarea>
                                </div>
                            </li>
                        </ul>
                        <div className="d-flex ml-5">
                            <div className="col-md-4">
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Village/गांव<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                            <div className="col-md-4">
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Block/तालुका.<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                            <div className="col-md-4">
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        District/जिल्हा<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                        </div>
                        <div className="d-flex ml-5">
                            <div className="col-md-4">
                                <li className="mt-3">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        State / राज्य<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                            <div className="col-md-4">
                                <li className="mt-3">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Pincode / पिनकोड<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Documents of Address Proof <sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        Driving License
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Ration Card
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        Voter Id
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Passport{" "}
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        Electricity Bill
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Property Tax Receipt
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    निवासी असल्यासचा पुरावा <sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        वाहन परवाना
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        शिधापत्रिका
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        मतदान कार्ड
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पारपत्र{" "}
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        विज बिल
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        मालमता कर पावती
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Address proof / पत्त्याचा पुरावा<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="file" className="pl-2" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-12 mt-4">
                    <h6>Disability Certificate/ दिव्यांगत्व प्रमाणपत्र</h6>
                    <hr className="border border-1 border-primary m-0 p-0" />
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Do You have Disability Certificate
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        Yes
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        No
                                    </label>
                                    <span className="ml-3">
                                        {" "}
                                        (If Yes, Please fill in the following Details & attach
                                        disability certificate)
                                    </span>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    आपल्याकडे दिव्यांगत्व प्रमाणपत्र आहे का?
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        होय{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        नाही
                                    </label>
                                    <span className="ml-3">
                                        (असल्यास अधिवास प्रमाणपत्र अपलोड करावे)
                                    </span>
                                </div>
                                <div className="">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Disability Certificate/ दिव्यांगत्व प्रमाणपत्र
                                    </label>
                                    <input type="file" name="" id="" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    UDID Certificate No<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Date of issue<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="date" className="pl-2" />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    UDID प्रमाणपत्र क्र<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    प्रमाणपत्र दिल्याचा दिनांक<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="date" className="pl-2" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Percentage of disability (40% or more than 40%)<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    दिव्यंगत्वाचे प्रमाण (किमान ४०% अथवा त्यापेक्षा अधिक)
                                    <sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Types Of Disability<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Locomotors Disability
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Leprosy Cured persons
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Cerebral Palsy
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Dwarfism{" "}
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        Muscular Dystrophy
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Acid Attack victim
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Blindness
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Low-vision
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Hearing Impairment (Deaf and hard of Hearing)
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Speech and Language disability
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        Intellectual Disability
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Specific Learning Disabilities
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Autism Spectrum Disorder
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Mental Behavior/Mental illness
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Multiple Sclerosis
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Parkinson's disease
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        Hemophilia
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        Hemophilia
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Thalassemia
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Sickle Cell disease
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Chronic Neurological conditions
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Multiple Disabilities
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    दिव्यांगत्वाचा प्रकार<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अस्थिव्यंग
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        कुष्ठरोग निवारीत मुक्त अंशतः अंध (दृष्टिदोष)
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        मेंदूचा पक्षाघात
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        शारीरिक वाढ खुंटणे{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        स्नायुंची विकृती
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        आम्ल हल्ला पीडित
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        पुर्णतः अंध
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अंशतः अंध
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        कर्णबधीर / ऐकू कमी येणे
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        वाचा / भाषा दोष
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        बौद्धिक अक्षम
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विशिष्ट अध्ययन अक्षम
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        स्वमग्न
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        मानसिक वर्तन/मानसिक आधार
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        हातापायातील स्नायू कमजोर / शिथिल होणे
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        कंपावात
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        अधिक रक्तस्त्राव
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        {" "}
                                        रक्ताची कमतरता
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        रक्ताचे हिमोग्लोबीनचे प्रमाण कमी होणे
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className="seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        मज्जासंस्थेचे तीव्र आजार
                                    </label>

                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        बहुविकलांग
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Do you have Disability by Birth<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Yes
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        No
                                    </label>
                                    <label htmlFor="" className="ml-5 seller-label">
                                        If yes then Disability Since
                                    </label>
                                    <input type="date" className="ml-3" />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    तुम्हाला जन्मतःच दिव्यांगत्व आहे का होय<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        होय{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        नाही{" "}
                                    </label>
                                    <label htmlFor="" className="ml-5 seller-label">
                                        जर होय तर दिव्यांगत्व कधीपासून आहे
                                    </label>
                                    <input type="date" className="ml-3" />
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Area of Disability<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Chest
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        Ears
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Left Eye
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Left Hand
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Left Leg
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Mouth
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Nois
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        Shoulder
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Throat
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Right Eye
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Right Leg
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Stomach
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    {" "}
                                    दिव्यंगत्वाचे क्षेत्र<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        छाती
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        कान
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        डावा डोळा
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        डावा हात
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        डावा हात
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Mouth
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        आवाज
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        {" "}
                                        खांदा
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        घसा
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        उजवा डोळा{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        {" "}
                                        उजवा पाय
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        {" "}
                                        पोट
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-12 mt-4">
                    <h6>Employment Details /रोजगाराची सद्दस्थिती</h6>
                    <hr className="border border-1 border-primary m-0 p-0" />
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Employement Status<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Employed
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Unemployed
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    रोजगाराची स्थिती<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        नोकरीला आहे
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        बेरोजगार{" "}
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    BPL/APL<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        N/A
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        APL{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        BPL{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Antoday{" "}
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    दारिद्र्यरेषेच्या वर / दारिद्र्यरेषेच्या खालील<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        N/A
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        APL{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        BPL{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Antoday{" "}
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Annual Income of the beneficiary<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Below 10k
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        10k to 1 lakhs{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        01 lakhs to 2.5 lakhs{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        25 lakhs{" "}
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्याचे वार्षिक उत्पन्न<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        १० हजार पैक्षा कमी
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        १० हजार ते १ लक्ष{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        लक्ष ते २.५ लक्ष
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        २.५ लक्ष{" "}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-12 mt-4">
                    <h6>Identity Details (Min-1)/ओळखीचा पुरावा (किमान - १ )</h6>
                    <hr className="border border-1 border-primary m-0 p-0" />
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul style={{ listStyle: "unset" }}>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Identity Proof<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Driving License
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        PAN Card
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Ration Card
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Voter ID
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Aadhar Card
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Passport
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        MGNERGA Job Card
                                    </label>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    ओळखपत्र प्रमाणपत्र<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        वाहन परवाना
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पॅन कार्ड
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        शिधापत्रिका
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        मतदान कार्ड
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        आधार कार्ड
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पारपत्र
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        मग्रारोहयो जॉब कार्ड
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="file" />
                                    <span className="fw-bold">
                                        (कागदपत्रे जेपीईजी/पीडी स्वरूपातच असावा कागदपत्राचा आकार
                                        किमान ४५ केबी आणि कमाल २५६ केबी इतकी असावी)
                                    </span>
                                </div>
                            </li>
                            <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    Identity Proof No.<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li>
                            <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    ओळखपत्र क्र<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <div className="col-md-6">
                                        <label htmlFor="" className="fw-bold">
                                            Aadhar Card<sup>*</sup>
                                        </label>
                                        <div className="d-flex">
                                            <input type="text" className="pl-2" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="" className="fw-bold ">
                                            आधार क्रमांक<sup>*</sup>
                                        </label>
                                        <div className="d-flex">
                                            <input type="text" className="pl-2" />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    Bank Details of the Beneficiaries<sup>*</sup>
                                </label>
                                <div className=" col-md-12">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Account Holder's Name
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Account Number
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Bank Name
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        IFSC Code
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Branch Name
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Branch Code
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभाथ्र्यांचे  बँक तपशील<sup>*</sup>
                                </label>
                                <div className=" col-md-12">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        खातेधारकाचे नाव
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        खाते क्रमांक
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        बँकेचे नाव
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        IFSC कोड
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        शाखेचे नाव
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        शाखा कोड
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    Please upload Front Page of Bank Passbook<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="file" className="pl-2" />
                                </div>
                            </li>
                            <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    कृपया बँक पासबुकचे पहिले पान अपलोड करा<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="file" className="pl-2" />
                                </div>
                            </li>
                            <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    Beneficiary's Affidavith<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="file" className="pl-2" />
                                </div>
                            </li>
                            <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभाथ्र्यांचे प्रतिज्ञापत्रक<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="file" className="pl-2" />
                                </div>
                            </li>
                            <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    What Business would you like to do?<sup>*</sup>
                                </label>
                                <div className="col-md-6 border border-2 border-secondary ">
                                    <div className="mt-2 ">
                                        <input type="checkbox" className="ml-2 seller-checkbox " />
                                        <label htmlFor="" className="ml-3 seller-label">
                                            {" "}
                                            Food item / खादयपदार्थ
                                        </label>
                                    </div>
                                    <div>
                                        <input type="checkbox" className="ml-2 seller-checkbox  " />
                                        <label htmlFor="" className="ml-3 seller-label">
                                            Minor
                                        </label>
                                    </div>
                                    <div>
                                        <input type="checkbox" className="ml-2 seller-checkbox  " />
                                        <label htmlFor="" className="ml-3 seller-label">
                                            {" "}
                                            Independent Business
                                        </label>
                                    </div>

                                    <div>
                                        <input type="checkbox" className="ml-2 seller-checkbox  " />
                                        <label htmlFor="" className="ml-3 seller-label">
                                            Transport business
                                        </label>
                                    </div>
                                    <div className="mb-2">
                                        <input type="checkbox" className="ml-2 seller-checkbox  " />
                                        <label htmlFor="" className="ml-3 seller-label">
                                            Others
                                        </label>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    Declaration<sup>*</sup>
                                </label>
                                <div className="col-md-12">
                                    <span style={{ lineHeight: "normal", lineBreak: "auto" }}>
                                        1. Mr./Mrs./{" "}
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            style={{
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                paddingLeft: "5px",
                                            }}
                                        />
                                        Son/Daughter/Wife of{" "}
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            style={{
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                paddingLeft: "5px",
                                            }}
                                        />
                                        Age{" "}
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            style={{
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                paddingLeft: "5px",
                                                width: "50px",
                                            }}
                                        />{" "}
                                        Cast{" "}
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            style={{
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                paddingLeft: "5px",
                                            }}
                                        />{" "}
                                        (SC/ST/OBC/NT/SEBC/VINT) ,Resident of{" "}
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            style={{
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                paddingLeft: "5px",
                                            }}
                                        />{" "}
                                        , Post{" "}
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            style={{
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                paddingLeft: "5px",
                                            }}
                                        />
                                        ,Block
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            style={{
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                paddingLeft: "5px",
                                            }}
                                        />
                                        ,District{" "}
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            style={{
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                paddingLeft: "5px",
                                            }}
                                        />
                                        ,State Maharashtra, Pin code{" "}
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            style={{
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                                paddingLeft: "5px",
                                            }}
                                        />
                                        do hereby declare that, the Information given above and in
                                        the enclosed documents is true to the best of my biowledge
                                        and belief, and nothing has been concealed therein. I am
                                        well aware of the fact that if the information given by me
                                        is provided false/not true, then I will be liable for
                                        disqualifying the application as per the law. Also, if any
                                        benefits availed by me shall be summarily withdrawn. I will
                                        use the grant/e-vehicle/e-cart for the same purpose as per
                                        the scheme guidelines dated 10 June 2019
                                    </span>
                                </div>
                            </li>
                            <li className="mt-3 d-flex">
                                <label htmlFor="" className="fw-bold mr-2">
                                    Accept (All terms & Condition)<sup>*</sup>
                                </label>
                                <div className="col-md-6 border border-2 border-secondary ">
                                    <div className="d-flex mt-2 mb-2 ">
                                        <input type="checkbox" className="seller-checkbox " />
                                        <label htmlFor="" className="ml-1 seller-label">
                                            Yes
                                        </label>
                                        <input type="checkbox" className="ml-3 seller-checkbox  " />
                                        <label htmlFor="" className="ml-1 seller-label">
                                            No
                                        </label>
                                    </div>


                                </div>
                            </li>


                        </ul>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Seller;
